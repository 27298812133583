import { Directive, HostListener, Input } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {
  @Input('copy-clipboard')
  public payload: string;

  private clipboard: Clipboard = navigator.clipboard;

  constructor(private toastService: ToastService) {}

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();

    if (!this.payload) {
      return;
    }

    this.clipboard.writeText(this.payload).then(() => {
      this.toastService.sendMessage(
        `Successfully copied to clipboard`,
        'success'
      );
    });
  }
}
