import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FileDeleteConfirmationDialogComponent } from './components/file-delete-confirmation-dialog/file-delete-confirmation-dialog.component';
import { FilenameFormatPipe } from './pipes/filename-format-pipe';
import { FileStoreService } from './services/file-store.service';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule],
  declarations: [FileDeleteConfirmationDialogComponent, FilenameFormatPipe],
  exports: [FileDeleteConfirmationDialogComponent, FilenameFormatPipe],
  providers: [FileStoreService],
})
export class FileStoreModule {}
