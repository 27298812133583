import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  INotification,
  NotificationTypeMap,
} from '../../models/notification.models';

@Component({
  selector: 'app-notification',
  styleUrls: ['./notification.component.scss'],
  template: ` <div
    class="notif"
    fxLayout="row"
    fxLayoutGap="8px"
    fxLayoutAlign="space-between"
  >
    <div fxLayout="column" fxLayoutGap="8px">
      {{ actionPrefix }}

      <div *ngFor="let content of notification.content">
        <div
          *ngIf="content.text"
          [innerHTML]="content.text.text | markdown"
          data-e2e="notification-content"
        ></div>
        <div *ngIf="content.actions" fxLayout="row" fxLayoutGap="4px">
          <button
            class="thin-button"
            color="basic"
            *ngFor="let button of content.actions"
            (click)="route(button.action.link)"
            data-e2e="notification-action-button"
          >
            {{ button.text.text }}
          </button>
        </div>
      </div>
      <span class="time" data-e2e="notification-date-created">{{
        notification.dateCreated * 1000 | date : 'short'
      }}</span>
    </div>
    <div>
      <mat-icon
        *ngIf="notification.archiveAllowed && !notification.archived"
        class="close"
        (click)="archive()"
        data-e2e="notification-archive"
      >
        close
      </mat-icon>
    </div>
  </div>`,
})
export class NotificationComponent {
  @Input() notification: INotification;
  @Output() archiveNotification = new EventEmitter<string>();
  @Output() routeAction = new EventEmitter<string>();

  get actionPrefix(): string {
    return (
      {
        [NotificationTypeMap.availabilityRequest]: 'Add Availability',
        [NotificationTypeMap.expertAccepted]: 'Schedule Conference',
        [NotificationTypeMap.expertCallCompleted]: 'Pay Expert',
        [NotificationTypeMap.surveyCompleted]: 'Send to Client',
      }[this.notification.type] || ''
    );
  }

  archive(): void {
    this.archiveNotification.emit(this.notification.notificationId);
  }

  route(link: string): void {
    this.routeAction.emit(link);
  }
}
