import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IEngagement } from '@techspert-io/engagements';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Directive({
  selector: '[appEngagementBillingFormTimer]',
})
export class EngagementBillingFormTimerDirective implements OnInit, OnDestroy {
  @Input('appEngagementBillingFormTimer') engagement: IEngagement;

  startTime: number;

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.startTime = performance.now();
  }

  ngOnDestroy(): void {
    if (this.engagement) {
      this.gaService.event(
        'timing',
        `engagement_billing`,
        `${this.engagement.opportunityName}_${this.engagement.engagementId}`,
        Math.round(performance.now() - this.startTime)
      );
    }
  }
}
