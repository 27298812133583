import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IExpertProp } from '../models/experts.models';
import { ExpertRejectionReasonPipe } from './expert-rejection-reason.pipe';

type AvailabilityDate = { start: string; end: string };

@Pipe({
  name: 'expertFieldValue',
})
export class ExpertFieldValuePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private expertRejectionReasonPipe: ExpertRejectionReasonPipe
  ) {}

  transform(content: IExpertProp): string {
    switch (content.field) {
      case 'availabilities':
        return content.value
          .map((d: AvailabilityDate) => this.transformDate(d))
          .join('\n');
      case 'reasonForRejection':
        return this.expertRejectionReasonPipe.transform(content.value);
      default:
        return content.value;
    }
  }

  transformDate(a: AvailabilityDate): string {
    const start = this.datePipe.transform(a.start, 'EE MMM d, HH:mm');
    const end = this.datePipe.transform(a.end, 'HH:mm');
    const tz = this.datePipe.transform(a.end, 'z');

    return `${start} to ${end} (${tz})`;
  }
}
