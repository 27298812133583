import { Pipe, PipeTransform } from '@angular/core';
import { ReasonForRejection } from '../models/experts.models';

@Pipe({
  name: 'expertRejectionReason',
})
export class ExpertRejectionReasonPipe implements PipeTransform {
  transform(content: ReasonForRejection): string {
    return (
      {
        NULL: 'No reason',
      }[content] || content
    );
  }
}
