import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {
  IOpportunity,
  IOpportunityScreenerConfigItem,
  IOpportunitySegment,
  OpportunityScreenersService,
} from '@techspert-io/opportunities';
import { finalize, tap } from 'rxjs/operators';
import * as Env from '../../../../../environments/environment';

interface ISegmentFormValues {
  segmentId: string;
  emailSubject?: string;
  owner: string;
  cover: string[];
  templateKeys?: { key: string; value: string }[];
  surveyId?: string;
  messageId?: string;
  conferenceTopic?: string;
}

interface IScreenerFormValues {
  surveyName: string;
  messageId?: string;
}

interface IOpportunitySegmentScreener extends IOpportunitySegment {
  sfId: string;
  emailSubject?: string;
  surveyId?: string;
  surveyName?: string;
  owner?: string;
  cover?: string[];
  messageId?: string;
  screenerLink?: string;
  templateKeys: Record<string, string>;
  conferenceTopic?: string;
}

@Component({
  selector: 'app-expert-targets',
  templateUrl: './expert-targets.component.html',
  styleUrls: ['./expert-targets.component.scss'],
})
export class ExpertTargetsComponent implements OnInit {
  @Input() opportunity: IOpportunity;
  @Output() opportunityChange = new EventEmitter<IOpportunity>();

  isLoading = false;
  dataSource = new MatTableDataSource<IOpportunitySegmentScreener>();

  segmentForm = new FormGroup({
    segmentId: new FormControl(null, Validators.required),
    emailSubject: new FormControl(null),
    cover: new FormArray([]),
    owner: new FormControl(null, Validators.required),
    surveyId: new FormControl(null),
    messageId: new FormControl(null),
    templateKeys: new FormArray([]),
    conferenceTopic: new FormControl(null, Validators.required),
  });

  screenerForm = new FormGroup({
    surveyName: new FormControl(null, Validators.required),
    messageId: new FormControl(''),
  });

  get hasScreenerConfig(): boolean {
    return !!this.opportunity.screenerConfig;
  }

  get displayedColumns(): string[] {
    return this.hasScreenerConfig
      ? [
          'segment',
          'amount',
          'unitsPerEngagement',
          'owner',
          'emailSubject',
          'screenerLink',
          'options',
        ]
      : ['segment', 'amount', 'unitsPerEngagement', 'owner', 'options'];
  }

  get surveyOptions(): IOpportunityScreenerConfigItem[] {
    return (this.opportunity.screenerConfig?.surveys || []).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get messageOptions(): IOpportunityScreenerConfigItem[] {
    return (this.opportunity.screenerConfig?.messages || []).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get surveyFormOptions(): IOpportunityScreenerConfigItem[] {
    return (this.isEditing && this.surveyOptions) || [];
  }

  get messageFormOptions(): IOpportunityScreenerConfigItem[] {
    return (this.isEditing && this.messageOptions) || [];
  }

  get salesforceSegmentsUrl(): string {
    return `${Env.environment.salesforceBase}/lightning/r/Opportunity/${this.opportunity.salesforceId}/related/Segments__r/view`;
  }

  get isEditing(): boolean {
    return this.segmentForm.get('segmentId').valid;
  }

  get cover(): FormArray {
    return this.segmentForm.get('cover') as FormArray;
  }

  get templateKeys(): FormArray {
    return this.segmentForm.get('templateKeys') as FormArray;
  }

  constructor(private screenersService: OpportunityScreenersService) {}

  ngOnInit(): void {
    this.opportunity.opportunityAssignees.forEach(() =>
      this.cover.push(new FormControl(false))
    );

    this.updateDataSourceSegments(this.opportunity);
  }

  updateSegment(form: ISegmentFormValues): void {
    if (!this.opportunity.segmentConfig) {
      this.opportunity.segmentConfig = {};
    }

    const { templateKeys, cover } = this.mapFormData(form);

    const segmentId = form.segmentId;

    this.opportunity.segmentConfig[segmentId] = {
      ...(this.opportunity.segmentConfig[segmentId] || {}),
      templateKeys,
      cover,
      owner: [form.owner],
      screener: form.surveyId && {
        emailSubject: form.emailSubject,
        messageId: form.messageId,
        surveyId: form.surveyId,
        segmentId,
        segmentName: this.opportunity.segments[segmentId].segment,
        mailingListId: this.opportunity.screenerConfig.mailingListId,
        libraryId: this.opportunity.screenerConfig.libraryId,
      },
      conferenceTopic: form.conferenceTopic || '',
    };

    this.resetSegmentsForm();
    this.updateDataSourceSegments(this.opportunity);
    this.opportunityChange.emit(this.opportunity);
  }

  private mapFormData(form: ISegmentFormValues): {
    cover: string[];
    templateKeys: Record<string, string>;
  } {
    const cover = form.cover
      .map((v, i) => ({
        key: this.opportunity.opportunityAssignees[i].email,
        value: v,
      }))
      .filter((v) => v.value)
      .map((v) => v.key);

    const templateKeys = form.templateKeys
      .filter((k) => k.key && k.value)
      .reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {});

    return { templateKeys, cover };
  }

  createScreener(form: IScreenerFormValues): void {
    this.isLoading = true;
    this.screenersService
      .create({
        opportunityId: this.opportunity.opportunityId,
        surveyName: form.surveyName,
        messageId: form.messageId,
      })
      .pipe(
        tap((res) => (this.opportunity = res)),
        finalize(() => {
          this.updateDataSourceSegments(this.opportunity);
          this.opportunityChange.emit(this.opportunity);
          this.resetScreenerForm();
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  resetSegmentsForm(): void {
    this.segmentForm.reset();
    this.templateKeys.clear();
  }

  resetScreenerForm(): void {
    this.screenerForm.reset({
      messageId: '',
    });
  }

  getIsEditingRow(id: number): boolean {
    return this.segmentForm.get('segmentId').value === id;
  }

  selectSegment(row: IOpportunitySegmentScreener): void {
    const conferenceTopic =
      row.conferenceTopic || this.opportunity.defaultConferenceTopic || '';

    if (this.hasScreenerConfig) {
      ['emailSubject', 'messageId', 'surveyId'].forEach((control) =>
        this.segmentForm.get(control).setValidators(Validators.required)
      );

      this.segmentForm.patchValue({
        segmentId: row.sfId,
        emailSubject: row.emailSubject || 'Techspert Surveys',
        owner: row.owner || '',
        cover: this.opportunity.opportunityAssignees.map((o) =>
          row.cover.includes(o.email) ? o.email : false
        ),
        messageId: row.messageId || this.messageOptions[0].id,
        surveyId: row.surveyId || this.surveyOptions[0].id,
        conferenceTopic,
      });
    } else {
      this.segmentForm.patchValue({
        segmentId: row.sfId,
        owner: row.owner || '',
        cover: this.opportunity.opportunityAssignees.map((o) =>
          row.cover.includes(o.email) ? o.email : false
        ),
        conferenceTopic,
      });
    }

    this.templateKeys.clear();
    Object.entries(row.templateKeys).forEach(([key, value]) =>
      this.templateKeys.push(this.getNewTempleteKeysForm(key, value))
    );
  }

  addTemplateKey(): void {
    this.templateKeys.push(this.getNewTempleteKeysForm());
  }

  removeTemplateKey(idx: number): void {
    this.templateKeys.removeAt(idx);
  }

  private getNewTempleteKeysForm(
    key: string = null,
    value: string = null
  ): FormGroup {
    return new FormGroup({
      key: new FormControl(key, Validators.required),
      value: new FormControl(value, Validators.required),
    });
  }

  private updateDataSourceSegments({
    segments,
    segmentConfig,
  }: IOpportunity): void {
    this.dataSource.data = Object.entries(segments || {})
      .filter(([, s]) => s.active)
      .sort(
        ([, a], [, b]) =>
          a.geography.localeCompare(b.geography) ||
          a.segment.localeCompare(b.segment)
      )
      .map(([sfId, s]) => {
        const segment = (segmentConfig || {})[sfId];
        return {
          ...s,
          sfId,
          owner: segment?.owner?.find(Boolean),
          cover: segment?.cover || [],
          conferenceTopic: segment?.conferenceTopic || '',
          templateKeys: segment?.templateKeys || {},
          ...(segment?.screener
            ? {
                screenerLink: `https://survey.eu.qualtrics.com/jfe/form/${segment.screener.surveyId}`,
                surveyId: segment.screener.surveyId,
                surveyName: this.surveyOptions.find(
                  (s) => s.id === segment.screener.surveyId
                ).name,
                messageId: segment.screener.messageId,
                emailSubject: segment.screener.emailSubject,
              }
            : {}),
        };
      });
  }
}
