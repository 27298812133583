import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IExpertScreenerResult } from '@techspert-io/experts';

@Component({
  selector: 'app-expert-screener-result-dialog',
  templateUrl: './expert-screener-result-dialog.component.html',
  styleUrls: ['./expert-screener-result-dialog.component.scss'],
})
export class ExpertScreenerResultDialogComponent {
  screenerResult: IExpertScreenerResult;

  constructor(
    public dialogRef: MatDialogRef<ExpertScreenerResultDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA)
    private data: { screenerResult: IExpertScreenerResult }
  ) {
    this.screenerResult = this.data.screenerResult;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
