import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { OmnisearchIconsComponent } from './components/omnisearch-icons/omnisearch-icons.component';
import { OmnisearchResultComponent } from './components/omnisearch-result/omnisearch-result.component';
import { OmnisearchComponent } from './components/omnisearch/omnisearch.component';
import { HighlightSearchTermDirective } from './directives/highlight-search-term.directive';
import { CategoryPipe } from './pipes/category.pipe';
import { DetailsTooltipPipe } from './pipes/details-tooltip.pipe';
import { OmnisearchNamePipe } from './pipes/omnisearch-name.pipe';
import { UrlTooltipPipe } from './pipes/url-tooltip.pipe';
import { UrlPipe } from './pipes/url.pipe';

@NgModule({
  declarations: [
    OmnisearchComponent,
    OmnisearchResultComponent,
    UrlPipe,
    UrlTooltipPipe,
    DetailsTooltipPipe,
    HighlightSearchTermDirective,
    OmnisearchIconsComponent,
    CategoryPipe,
    OmnisearchNamePipe,
  ],
  imports: [
    BtxSharedModule,
    CommonModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    ScrollingModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [OmnisearchComponent],
})
export class OmnisearchModule {}
