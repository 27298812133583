import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEngagement, PaymentsService } from '@techspert-io/engagements';
import { ExpertsQueryService, IExpert } from '@techspert-io/experts';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

interface IPageData {
  expert?: IExpert;
  inactiveEngagements: IEngagement[];
  routerLink?: string;
}

@Component({
  selector: 'app-complete-expert',
  templateUrl: './complete-expert.component.html',
})
export class CompleteExpertComponent implements OnInit {
  pageData$: Observable<IPageData>;
  pageState:
    | 'loading'
    | 'inactive-engagements'
    | 'no-inactive-engagements'
    | 'update-complete'
    | 'no-expert' = 'loading';

  constructor(
    private activatedRoute: ActivatedRoute,
    private paymentsService: PaymentsService,
    private expertsQueryService: ExpertsQueryService
  ) {}

  ngOnInit(): void {
    this.pageData$ = this.activatedRoute.paramMap.pipe(
      filter((params) => params.has('expertId')),
      map((params) => ({
        expertId: params.get('expertId'),
      })),
      distinctUntilChanged(),
      switchMap((params) => this.initialiseExpert(params.expertId))
    );
  }

  setEngagementsToActive(inactiveEngagements: IEngagement[]): void {
    this.pageState = 'loading';

    const updates$ = inactiveEngagements.map((e) =>
      this.paymentsService.update({
        engagementId: e.engagementId,
        paymentActive: true,
      })
    );

    forkJoin(updates$)
      .pipe(tap(() => (this.pageState = 'update-complete')))
      .subscribe();
  }

  private initialiseExpert(expertId: string): Observable<IPageData> {
    const getInactiveEngagements = this.paymentsService
      .query({ expertId })
      .pipe(
        map((engagements) =>
          engagements.filter((engagement) => !engagement.paymentActive)
        )
      );

    this.pageState = 'loading';

    return combineLatest([
      this.expertsQueryService.getById(expertId),
      getInactiveEngagements,
    ]).pipe(
      tap(
        ([expert, inactiveEngagements]) =>
          (this.pageState = !expert
            ? 'no-expert'
            : inactiveEngagements.length === 0
            ? 'no-inactive-engagements'
            : 'inactive-engagements')
      ),
      map(([expert, inactiveEngagements]) => ({
        expert,
        inactiveEngagements,
        routerLink: `../../search/all-searches/phase/completed/details/${expert?.expertId}`,
      })),
      startWith({
        inactiveEngagements: [],
      })
    );
  }
}
