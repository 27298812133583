import { IDisplayExpert } from '@techspert-io/experts';
import { IScheduleDialogInput } from '../../../shared/components/schedule/schedule-models';
import { IExpertCallAction } from './expertCallActions.models';

export interface IPendingConferenceBookingExpert {
  firstName: string;
  lastName: string;
  callTime: string;
  opportunityName: string;
  connectPhase: string;
  clientId: string;
  opportunityId: string;
  searchId: string;
  expertId: string;
  opportunityAssigneeIds: string[];
}

export class IExpertConferenceEdit {
  expert: IDisplayExpert;
  expertCallAction?: IExpertCallAction;
  action: IScheduleDialogInput['action'] | 'view' | 'updateEmergencyContact';
}
