import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { ConferenceFilterComponent } from './conference-filter/conference-filter.component';
import { ConferenceManagementComponent } from './conference-management.component';

@NgModule({
  declarations: [ConferenceManagementComponent, ConferenceFilterComponent],
  imports: [
    BtxSharedModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  exports: [ConferenceManagementComponent],
})
export class ConferenceManagementModule {}
