import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, reduce, tap } from 'rxjs/operators';
import { ICreateManyResponse } from '../../../shared/models/api';
import { AppService } from '../../../shared/services/app.service';
import {
  IExpert,
  IExpertCreateRequest,
  ValidationErrors,
} from '../models/experts.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertsCreateService {
  private readonly baseUrl = '/experts';

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private appService: AppService
  ) {}

  public createMany(
    items: IExpertCreateRequest[],
    batchSize = 750
  ): Observable<ICreateManyResponse<IExpert>> {
    return from(this.appService.chunkArray(batchSize, items)).pipe(
      concatMap((e) => this.makeRequest(e)),
      reduce(
        (prev, curr) => ({
          success: [...prev.success, ...curr.success],
          fail: [...prev.fail, ...curr.fail],
        }),
        { success: [], fail: [] }
      )
    );
  }

  private makeRequest(
    items: IExpertCreateRequest[]
  ): Observable<ICreateManyResponse<IExpert>> {
    return this.http
      .post<ICreateManyResponse<IExpert>>(`${this.baseUrl}`, items)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.showErrorToast(err.error.message);
            return of({
              success: [],
              fail: [],
            });
          }
          return throwError(new ValidationErrors(err.error.errors));
        }),
        tap(
          (res) =>
            res.fail.length &&
            this.showErrorToast(`Creating ${res.fail.length} Expert(s) failed`)
        )
      );
  }

  private showErrorToast(message: string): void {
    this.toastService.sendMessage(message, 'error');
  }
}
