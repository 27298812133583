// Keep an eye on this to see if it's in sync with changes to opp stats object

import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { connectPhaseList } from '@techspert-io/experts';
import {
  IAggregatedStats,
  IBaseStats,
  IDisplayStat,
  IOpportunity,
  IOpportunitySegments,
  IStats,
} from '@techspert-io/opportunities';
import { ExportToCsv } from 'export-to-csv';
import { StatisticsService } from '../../../../shared/services/statistics.service';

interface IConstructGraphObject {
  [geography: string]: {
    [profileType: string]: {
      geography: string;
      segment: string;
      stats: IStats;
    };
  };
}
@Component({
  selector: 'app-geographic-progress',
  templateUrl: './geographic-progress.component.html',
  styleUrls: ['./geographic-progress.component.scss'],
})
export class GeographicProgressComponent implements OnChanges, AfterViewInit {
  @Input() opportunity: IOpportunity;
  public displayedColumns: string[] = [
    'geography',
    'segment',
    'screening',
    'inPortal',
    'connectionsScheduled',
    'connectionsCompleted',
    'target',
  ];
  public dataSource: MatTableDataSource<IDisplayStat>;

  constructor(public statsService: StatisticsService) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(
      this.calculateGeographicProgress(this.opportunity).sort(
        (a, b) =>
          a.geography.toLowerCase().localeCompare(b.geography.toLowerCase()) ||
          a.segment.toLowerCase().localeCompare(b.segment.toLowerCase())
      )
    );
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  public calculateGeographicProgress(
    opportunity: IOpportunity
  ): IDisplayStat[] {
    const flattenedStats = this.flattenSearchStats(opportunity);

    const aggregatedSegmentStatsByGeographyDict =
      this.aggregateSegmentStatsByGeography(flattenedStats);

    const flattenedAggregatedStats = this.flattenAggregatedStats(
      aggregatedSegmentStatsByGeographyDict
    );

    return this.constructGraphObjects(flattenedAggregatedStats, opportunity);
  }

  public getTotal(phase: string): number {
    return this.dataSource
      ? this.dataSource.data.reduce((curr, acc) => (curr += acc[phase]), 0)
      : 0;
  }

  public fetchColourCode(phase: number, value: number, target: number): string {
    const multiplier = 0.3;
    if (target) {
      return `rgba(31, 65, 97, ${(value / target) * phase * multiplier})`;
    }
  }

  public constructStatObject(
    statObj: IAggregatedStats,
    opportunity: IOpportunity
  ): IDisplayStat {
    const connectionsScheduled =
      statObj.stats.scheduled.activeExperts +
      statObj.stats.accepted.activeExperts;
    const target =
      opportunity.segments && Object.keys(opportunity.segments).length
        ? this.findExpertSegmentCount(statObj, opportunity.segments)
        : 0;
    return {
      geography: statObj.geography,
      segment: statObj.segment,
      screening: statObj.stats.screener.activeExperts,
      inPortal: statObj.stats.sentToClient.activeExperts,
      connectionsScheduled,
      connectionsCompleted: statObj.stats.completed.activeExperts,
      connectionsConfirmed:
        connectionsScheduled + (statObj.stats.completed.activeExperts || 0),
      target,
    };
  }

  public downloadProgressCsv(): void {
    const date = new Date().toISOString().slice(0, 10);
    const csvOptions = {
      fieldSeparator: ',',
      filename: `Geographic-progress-${date}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Geographic-progress-${date}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(this.dataSource.data);
  }

  private flattenAggregatedStats(
    aggregatedAccumulatedStats: IConstructGraphObject
  ): IAggregatedStats[] {
    return Object.values(aggregatedAccumulatedStats)
      .map((segmentObject) => Object.values(segmentObject))
      .flat();
  }

  private flattenSearchStats(opportunity: IOpportunity): IAggregatedStats[] {
    return Object.values(opportunity.searches)
      .map((searchObj) => searchObj.stats)
      .map((geographyObj) => Object.entries(geographyObj))
      .flat()
      .map((geographySegment) => {
        const geography = geographySegment[0];
        const segmentEntries = Object.entries(geographySegment[1]);
        return segmentEntries.map((segment) => ({
          geography: geography,
          segment: segment[0],
          stats: this.sanitizeStats(segment[1]),
        }));
      })
      .flat();
  }

  private sanitizeStats(stats: IBaseStats): IBaseStats {
    return connectPhaseList.reduce((acc, p) => {
      if (!acc[p]) {
        acc[p] = {
          experts: 0,
          clientRejected: 0,
          adminRejected: 0,
          blocked: 0,
          activeExperts: 0,
        };
      }
      return acc;
    }, stats);
  }

  private aggregateSegmentStatsByGeography(
    statObjects: IAggregatedStats[]
  ): IConstructGraphObject {
    return statObjects.reduce((acc, curr) => {
      if (acc[curr.geography]) {
        if (acc[curr.geography][curr.segment]) {
          acc[curr.geography][curr.segment].stats = this.sumStats(
            acc[curr.geography][curr.segment].stats,
            curr.stats
          );
        } else {
          acc[curr.geography][curr.segment] = {
            segment: curr.segment,
            geography: curr.geography,
            stats: curr.stats,
          };
        }
      } else {
        acc[curr.geography] = {
          [curr.segment]: {
            segment: curr.segment,
            geography: curr.geography,
            stats: curr.stats,
          },
        };
      }
      return acc;
    }, {});
  }

  private constructGraphObjects(
    flattenAggregatedStats: IAggregatedStats[],
    opportunity: IOpportunity
  ): IDisplayStat[] {
    return flattenAggregatedStats.map((statObj) =>
      this.constructStatObject(statObj, opportunity)
    );
  }

  private findExpertSegmentCount(
    statObj: IAggregatedStats,
    segments: IOpportunitySegments
  ): number {
    const segmentEntry = Object.values(segments).find(
      (segment) =>
        segment.active &&
        segment.geography.toLowerCase() === statObj.geography.toLowerCase() &&
        segment.segment.toLowerCase() === statObj.segment.toLowerCase()
    );

    return segmentEntry && segmentEntry ? segmentEntry.amount : 0;
  }

  private sumStats(
    accumulatorStats: IBaseStats,
    currentStats: IBaseStats
  ): IBaseStats {
    return connectPhaseList.reduce(
      (acc, p) => ({
        ...acc,
        [p]: {
          ...acc[p],
          activeExperts: acc[p].activeExperts + currentStats[p].activeExperts,
          adminRejected: acc[p].activeExperts + currentStats[p].adminRejected,
          blocked: acc[p].activeExperts + currentStats[p].blocked,
          clientRejected: acc[p].activeExperts + currentStats[p].clientRejected,
          experts: acc[p].activeExperts + currentStats[p].experts,
        },
      }),
      accumulatorStats
    );
  }
}
