import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoAuthService } from '../services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class BetaGroupGuard implements CanActivate {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(): Observable<boolean> {
    return this.cognitoAuthService.loggedIn$.pipe(
      map((loggedIn) => {
        if (
          loggedIn &&
          this.cognitoAuthService.loggedInUser.roles &&
          this.cognitoAuthService.loggedInUser.roles.includes('PM Beta') &&
          this.document.location.href.startsWith(
            'https://connect.techspert.com/'
          )
        ) {
          this.document.location.replace(
            this.document.location.href.replace(
              'https://connect.techspert.com/',
              'https://beta.connect.techspert.com/'
            )
          );
          return false;
        }

        return true;
      })
    );
  }
}
