import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IExpertProp } from '../models/experts.models';

@Pipe({
  name: 'expertField',
})
export class ExpertFieldPipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  transform(content: IExpertProp | string): string {
    const field = typeof content === 'string' ? content : content.field;

    switch (field) {
      case 'availabilities':
        return 'Availability';
      case 'profileType':
        return 'Profile type';
      case 'geographicTarget':
        return 'Geographic target';
      case 'searchId':
        return 'Search name';
      case 'dateCreated':
        return 'Date created';
      case 'lastUpdated':
        return 'Last updated';
      case 'reasonForRejection':
        return 'Reason for rejection';
      case 'adminRejected':
        return 'Rejected by admin';
      case 'clientRejected':
        return 'Rejected by client';
      case 'connectPhase':
        return 'Connect phase';
      default:
        return this.titleCasePipe.transform(field);
    }
  }
}
