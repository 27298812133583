import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-engagements-overview',
  templateUrl: './engagements-overview.component.html',
  styleUrls: ['./engagements-overview.component.scss'],
})
export class EngagementsOverviewComponent {
  @Input() public engagements;
  @Output() public openDeleteEngagementDialogSignal = new EventEmitter();
  constructor(public dialog: MatDialog) {}

  public emitOpenDeleteEngagementDialog(engagementId: string): void {
    this.openDeleteEngagementDialogSignal.emit(engagementId);
  }
}
