import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FileStoreModule } from '@techspert-io/file-store';
import { OpportunitiesModule } from '@techspert-io/opportunities';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { DeleteEngagementDialogComponent } from './components/delete-engagement-dialog/delete-engagement-dialog.component';
import { DonutSvgComponent } from './components/donut-svg/donut-svg.component';
import { EngagementBillingFormComponent } from './components/engagement-billing-form/engagement-billing-form.component';
import { EventPickerComponent } from './components/event-picker/event-picker.component';
import { OpportunityTileComponent } from './components/opportunity-tile/opportunity-tile.component';
import { ClientContactsComponent } from './components/opportunity-view-dialog/client-contacts/client-contacts.component';
import { ContactDetailComponent } from './components/opportunity-view-dialog/client-contacts/contact-detail/contact-detail.component';
import { EngagementsOverviewComponent } from './components/opportunity-view-dialog/engagements-overview/engagements-overview.component';
import { ExpertTargetsComponent } from './components/opportunity-view-dialog/expert-targets/expert-targets.component';
import { GeographicProgressComponent } from './components/opportunity-view-dialog/geographic-progress/geographic-progress.component';
import { OpportunityDetailsComponent } from './components/opportunity-view-dialog/opportunity-details/opportunity-details.component';
import { OpportunityFilesComponent } from './components/opportunity-view-dialog/opportunity-files/opportunity-files.component';
import { OpportunityViewDialogComponent } from './components/opportunity-view-dialog/opportunity-view-dialog.component';
import { PodMemberAssignerComponent } from './components/opportunity-view-dialog/pod-member-assigner/pod-member-assigner.component';
import { ScheduleFormComponent } from './components/schedule/conference-schedule-form/schedule-form.component';
import { EmailComponent } from './components/schedule/email/email.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { SharedBtnLoaderComponent } from './components/shared-btn-loader/shared-btn-loader.component';
import { SharedCalendarComponent } from './components/shared-calendar/shared-calendar.component';
import { SharedEmailPillComponent } from './components/shared-email-pill/shared-email-pill.component';
import { ScintillatingLoaderComponent } from './components/shared-scintillating-loader/scintillating-loader.component';
import { SharedSectionLoaderComponent } from './components/shared-section-loader/shared-section-loader.component';
import { TextareaEditorComponent } from './components/textarea-editor/textarea-editor.component';
import { TimezonePerspectiveViewerComponent } from './components/timezone-perspective-viewer/timezone-perspective-viewer.component';
import { TimezoneSelectorComponent } from './components/timezone-selector/timezone-selector.component';
import { UpdateEmergencyContactComponent } from './components/update-emergency-contact/update-emergency-contact.component';
import { ViewConnectionComponent } from './components/view-connection/view-connection.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CopyClipboardDirective } from './directives/copy-clipboard';
import { CountryOptionsDividersDirective } from './directives/country-options-dividers.directive';
import { EngagementBillingFormTimerDirective } from './directives/engagement-billing-form-timer.directive';
import { ExpertDiagnosticDataDirective } from './directives/expert-diagnostic-data';
import { SchedulingCallTimerDirective } from './directives/scheduling-call-timer.directive';
import { SchedulingModalTimerDirective } from './directives/scheduling-modal-timer.directive';
import { CurrencyInputComponent } from './patterns/currency-input/currency-input.component';
import { MaterialModule } from './patterns/material-module';
import { ExpertNamePipe } from './pipes/expert-name';
import { MomentTimeZonePipe } from './pipes/moment-timezone.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SalesforceLinksPipe } from './pipes/salesforce-links.pipe';
import { SourceDisplayNamePipe } from './pipes/source-display-name.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { AppService } from './services/app.service';
import { ConferenceService } from './services/conference.service';
import { CountryService } from './services/country.service';
import { CurrencyService } from './services/currency.service';
import { EmailService } from './services/email.service';
import { StatisticsService } from './services/statistics.service';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ClientContactsComponent,
    ContactDetailComponent,
    CopyClipboardDirective,
    CountryOptionsDividersDirective,
    CurrencyInputComponent,
    DeleteEngagementDialogComponent,
    DonutSvgComponent,
    EmailComponent,
    EngagementBillingFormComponent,
    EngagementBillingFormTimerDirective,
    EngagementsOverviewComponent,
    EventPickerComponent,
    ExpertDiagnosticDataDirective,
    ExpertNamePipe,
    ExpertTargetsComponent,
    GeographicProgressComponent,
    MomentTimeZonePipe,
    OpportunityDetailsComponent,
    OpportunityFilesComponent,
    OpportunityTileComponent,
    OpportunityViewDialogComponent,
    PodMemberAssignerComponent,
    SafePipe,
    SalesforceLinksPipe,
    ScheduleComponent,
    ScheduleFormComponent,
    SchedulingCallTimerDirective,
    SchedulingModalTimerDirective,
    ScintillatingLoaderComponent,
    SharedBtnLoaderComponent,
    SharedCalendarComponent,
    SharedEmailPillComponent,
    SharedSectionLoaderComponent,
    SourceDisplayNamePipe,
    StripHtmlPipe,
    TextareaEditorComponent,
    TimezonePerspectiveViewerComponent,
    TimezoneSelectorComponent,
    UpdateEmergencyContactComponent,
    ViewConnectionComponent,
  ],
  imports: [
    CdkTableModule,
    CommonModule,
    EditorModule,
    FlexLayoutModule,
    FormsModule,
    FullCalendarModule,
    MaterialModule,
    NgxCsvParserModule,
    OpportunitiesModule,
    RouterModule,
    ScrollingModule,
    FileStoreModule,
  ],
  exports: [
    CdkTableModule,
    ClickOutsideDirective,
    ClientContactsComponent,
    CommonModule,
    ContactDetailComponent,
    CopyClipboardDirective,
    CountryOptionsDividersDirective,
    CurrencyInputComponent,
    DeleteEngagementDialogComponent,
    DonutSvgComponent,
    EditorModule,
    EngagementBillingFormComponent,
    EngagementBillingFormTimerDirective,
    EngagementsOverviewComponent,
    EventPickerComponent,
    ExpertDiagnosticDataDirective,
    ExpertNamePipe,
    FlexLayoutModule,
    FormsModule,
    GeographicProgressComponent,
    MaterialModule,
    OpportunitiesModule,
    OpportunityDetailsComponent,
    OpportunityFilesComponent,
    OpportunityTileComponent,
    OpportunityViewDialogComponent,
    PodMemberAssignerComponent,
    SafePipe,
    SalesforceLinksPipe,
    ScheduleComponent,
    ScheduleFormComponent,
    ScintillatingLoaderComponent,
    ScrollingModule,
    SharedBtnLoaderComponent,
    SharedCalendarComponent,
    SharedEmailPillComponent,
    SharedSectionLoaderComponent,
    SourceDisplayNamePipe,
    StripHtmlPipe,
    TextareaEditorComponent,
    TimezonePerspectiveViewerComponent,
    TimezoneSelectorComponent,
    UpdateEmergencyContactComponent,
    ViewConnectionComponent,
  ],
  providers: [
    AppService,
    ConferenceService,
    CountryService,
    CurrencyService,
    EmailService,
    StatisticsService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BtxSharedModule {}
