import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { ExpertiseChipListComponent } from './components/expertise-chip-list/expertise-chip-list.component';
import { ChipListExpertiseAutocompleteDirective } from './directives/chip-list-expertise-autocomplete.directive';
import { PdlEnrichmentPipe } from './pipes/pdl-enrichment-service.pipe';
import { QueryCreatorComponent } from './query-creator/query-creator.component';
import { ResultsDisplayFilterComponent } from './results-display-filter/results-display-filter.component';
import { ResultsDisplayFilterService } from './results-display-filter/results-display-filter.service';
import { ResultsDisplayComponent } from './results-display/results-display.component';
import { SearchComponent } from './search.component';
import { SelectOpportunityDialogComponent } from './select-opportunity-dialog/select-opportunity-dialog.component';
import { SearchService } from './services/search.service';

@NgModule({
  imports: [CommonModule, BtxSharedModule],
  declarations: [
    SearchComponent,
    QueryCreatorComponent,
    ResultsDisplayComponent,
    SelectOpportunityDialogComponent,
    PdlEnrichmentPipe,
    ChipListExpertiseAutocompleteDirective,
    ResultsDisplayFilterComponent,
    ExpertiseChipListComponent,
  ],
  exports: [
    SearchComponent,
    QueryCreatorComponent,
    ResultsDisplayComponent,
    SelectOpportunityDialogComponent,
    ChipListExpertiseAutocompleteDirective,
    ResultsDisplayFilterComponent,
    ExpertiseChipListComponent,
  ],
  providers: [SearchService, ResultsDisplayFilterService],
})
export class SearchModule {}
