import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpertSourcesService } from '@techspert-io/search';
import { ToastService } from '@techspert-io/user-alerts';
import { interval, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OpportunityExpertSourcesService {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expertSourcesService: ExpertSourcesService,
    private toastService: ToastService
  ) {}

  getSearches(
    opportunityId: string,
    searchFilterIds: string[],
    delayTime = 20000
  ): Observable<string[]> {
    return this.route.queryParamMap.pipe(
      filter((p) => p.has('batch-id')),
      map((p) => p.get('batch-id')),
      tap(() =>
        this.router.navigate(this.route.snapshot.url, {
          relativeTo: this.route,
          queryParams: {},
        })
      ),
      switchMap((batchId) => {
        const cycleQuery$ = new Subject();

        return interval(delayTime).pipe(
          takeUntil(cycleQuery$),
          switchMap(() =>
            this.getSources(batchId, opportunityId, searchFilterIds)
          ),
          tap(() => {
            cycleQuery$.next();
            cycleQuery$.complete();
          })
        );
      })
    );
  }

  private getSources(
    batchId: string,
    opportunityId: string,
    searchFilterIds: string[]
  ): Observable<string[]> {
    return this.expertSourcesService.getByOpportunity(opportunityId).pipe(
      tap((sources) => {
        !sources.some((r) => r.batchId === batchId) &&
          this.toastService.sendMessage('Expert query in progress', 'success');
      }),
      filter((s) => s.some((r) => r.batchId === batchId)),
      map((s) => s.filter((r) => r.batchId === batchId)),
      tap((sources) => {
        if (sources.some((a) => a.status === 'failed')) {
          const noExperts = sources.some(
            (a) =>
              a.status === 'failed' &&
              a.error === 'No experts remain after deduplication'
          );
          this.toastService.sendMessage(
            noExperts
              ? 'Search failed: No experts remain after deduplication'
              : 'Search failed',
            'error'
          );
        }
      }),
      map((sources) =>
        sources
          .filter(
            (name) =>
              name.status === 'success' &&
              name.expertsAdded > 0 &&
              !searchFilterIds.includes(name.searchName)
          )
          .map((s) => s.searchName)
      ),
      map((searchIds) => [...new Set(searchIds)]),
      tap((searchIds) => {
        searchIds.length &&
          this.toastService.sendMessage(
            `Fetching ${searchIds.length} search(es)`,
            'success'
          );
      })
    );
  }
}
