import { Pipe, PipeTransform } from '@angular/core';
import { ICommercialSearchService } from '../models/query';

@Pipe({ name: 'pdlEnrichmentDisplay' })
export class PdlEnrichmentPipe implements PipeTransform {
  transform(service: ICommercialSearchService): string {
    switch (service) {
      case 'linkedin_urls':
        return 'LinkedIn URLs';
      case 'npi_numbers':
        return 'NPI numbers';
    }
  }
}
