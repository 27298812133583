import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectUser } from '@techspert-io/auth';
import { ClientsService, IClient } from '@techspert-io/clients';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { IUserCreateRequest } from '../../models/user.models';
import { UserService } from '../../services/user.service';

interface IUserEditVM {
  user: ConnectUser;
  clients: IClient[];
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  vmData$: Observable<IUserEditVM> = EMPTY;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private clients: ClientsService
  ) {}

  ngOnInit(): void {
    this.vmData$ = combineLatest([
      this.clients.getAll(),
      this.route.paramMap.pipe(
        map((params) => params.get('userId')),
        switchMap((userId) => this.userService.getById(userId))
      ),
    ]).pipe(map(([clients, user]) => ({ clients, user })));
  }

  editUser(payload: IUserCreateRequest): void {
    this.isLoading = true;

    this.userService
      .update(payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigateByUrl('/admin/users'));
  }
}
