import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import { IExpertCallAction } from '../../models/expertCallActions.models';

export const baseExpertCallActionMock: IExpertCallAction = {
  expertActionId: 'expertActionId',
  clientId: 'clientId',
  opportunityId: 'opportunityId',
  expertId: 'expertId',
  ownerUserId: 'connectId',
  actionType: 'call',
  status: ExpertActionStatusMap.Pending,
  callType: 'other',
  datetime: '2011-10-05T14:48:00.000Z',
  duration: 60,
  internalName: 'TXP11 - Joe & Jane',
  externalName: 'Advanced Actioning',
  blind: true,
  isRecordingOn: true,
  joinMetadata: {
    dialIns: [],
  },
  dateCreated: 0,
  lastUpdated: 0,
  emergencyContact: 'emergency-contact-guid',
};
