import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BtxSharedModule } from '../../shared/btx-shared.module';
import { EngagementsDialogComponent } from './engagements-dialog/engagements-dialog.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { PaymentsComponent } from './payments.component';

@NgModule({
  imports: [CommonModule, BtxSharedModule],
  declarations: [
    PaymentsComponent,
    NotesDialogComponent,
    EngagementsDialogComponent,
  ],
})
export class PaymentsModule {}
