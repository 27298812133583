import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appHighlightSearchTerm]',
})
export class HighlightSearchTermDirective implements OnChanges {
  @Input() appHighlightSearchTerm: string;
  @Input() rawValue: string;

  constructor(
    private el: ElementRef,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {}

  ngOnChanges(): void {
    if (this.rawValue) {
      this.rawValue = this.domSanitizer.sanitize(
        SecurityContext.HTML,
        this.rawValue
      );
      this.addSpanTag(this.rawValue);
    }
  }

  private addSpanTag(rawValue: string): void {
    if (!this.appHighlightSearchTerm) {
      return this.setInnerHtml(rawValue);
    }
    const regex = new RegExp(this.appHighlightSearchTerm, 'gi');
    const match = rawValue.match(regex);

    if (!match) {
      return this.setInnerHtml(rawValue);
    }

    this.setInnerHtml(
      rawValue.replace(
        regex,
        `<span style="color: #000000; font-weight: 700;">${match[0]}</span>`
      )
    );
  }

  private setInnerHtml(value: string): void {
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
  }
}
