export interface IClientFeatures {
  omnisearchEnabled: boolean;
  omnisearchGlobalEnabled: boolean;
  salesCarrotEnabled: boolean;
}

export interface IClient {
  clientId: string;
  opportunities: Record<string, { status: 'open' | 'closed' }>;
  salesforceId: string;
  clientName: string;
  features?: IClientFeatures;
}
