import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExpertFile, ExpertFileService } from '@techspert-io/expert-files';
import {
  FileDeleteConfirmationDialogComponent,
  FileStoreService,
  IFileProgressEvent,
} from '@techspert-io/file-store';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { combineLatest, Subject } from 'rxjs';
import {
  filter,
  finalize,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../services/expert-files-state.service';
import { ExpertFileEditDialogComponent } from '../expert-file-edit-dialog/expert-file-edit-dialog.component';

@Component({
  selector: 'app-expert-files',
  templateUrl: './expert-files.component.html',
  styleUrls: [],
})
export class ExpertFilesComponent implements OnInit {
  @Input() expertId: string;

  private cancelUpload$ = new Subject();

  showUploadProgress: boolean = false;
  filesForUpload: File[] = [];
  fileProgressEvents: IFileProgressEvent[] = [];
  loadingFiles$ = this.filesState.state$;
  files: ExpertFile[] = [];
  loading: boolean;

  constructor(
    private fileStoreService: FileStoreService,
    private expertFilesService: ExpertFileService,
    private filesState: ExpertFilesStateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.expertFilesService.getFiles(this.expertId).subscribe((files) => {
      this.loading = false;
      this.files = files;
    });
  }

  dropped(files: NgxFileDropEntry[]): void {
    files.forEach((file) => {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        this.filesForUpload = [...this.filesForUpload, file];
      });
    });
  }

  downloadFile(file: ExpertFile): void {
    this.filesState.dispatch(new Add(file.fileKey));

    this.fileStoreService
      .downloadFile(file.fileKey, `${file.fileName}.${file.fileExtension}`)
      .pipe(
        takeUntil(this.loadingFiles$.pipe(filter((d) => !d[file.fileKey]))),
        tap((event) =>
          this.filesState.dispatch(
            new UpdateProgress(file.fileKey, event.percentageComplete)
          )
        ),
        finalize(() => this.filesState.dispatch(new Delete(file.fileKey)))
      )
      .subscribe();
  }

  updateFileAuthorisedStatus(
    { fileId, expertId }: ExpertFile,
    status: boolean
  ): void {
    this.filesState.dispatch(new Add(fileId));
    this.expertFilesService
      .updateFile({ fileId, pendingApproval: !status, expertId })
      .pipe(
        tap((file) => {
          this.updateFiles(file);
        }),
        finalize(() => this.filesState.dispatch(new Delete(fileId)))
      )
      .subscribe();
  }

  removeUploadFile(file: File): void {
    this.filesForUpload = this.filesForUpload.filter(
      (f) => f.name !== file.name
    );
  }

  editFile(data: ExpertFile): void {
    this.dialog
      .open<ExpertFileEditDialogComponent, ExpertFile, ExpertFile>(
        ExpertFileEditDialogComponent,
        { width: '450px', height: '300px', data }
      )
      .afterClosed()
      .pipe(
        takeWhile((updatedFile) => !!updatedFile),
        tap(() => this.filesState.dispatch(new Add(data.fileKey))),
        switchMap((updatedFiles) =>
          this.expertFilesService.updateFile(updatedFiles)
        ),
        tap((file) => {
          this.updateFiles(file);
        }),
        finalize(() => this.filesState.dispatch(new Delete(data.fileKey)))
      )
      .subscribe();
  }

  removeFile(file: ExpertFile): void {
    this.dialog
      .open(FileDeleteConfirmationDialogComponent, {
        width: '450px',
        data: `${file.fileName}.${file.fileExtension}`,
      })
      .afterClosed()
      .pipe(
        takeWhile((save) => !!save),
        tap(() => this.filesState.dispatch(new Add(file.fileKey))),
        switchMap(() =>
          this.expertFilesService.removeFile(file.fileId, file.expertId)
        ),
        tap(() => {
          this.files = this.files.filter((f) => f.fileId !== file.fileId);
        }),
        finalize(() => this.filesState.dispatch(new Delete(file.fileKey)))
      )
      .subscribe();
  }

  uploadFiles(): void {
    this.showUploadProgress = true;
    this.fileStoreService
      .uploadFiles(this.filesForUpload, 'experts', this.expertId)
      .pipe(
        tap((fileEvents) => (this.fileProgressEvents = fileEvents)),
        takeUntil(this.cancelUpload$),
        filter((fileEvents) =>
          fileEvents.every((d) => d.status === 'complete')
        ),
        switchMap((res) =>
          combineLatest(
            res.map((r) =>
              this.expertFilesService.putFile(
                r.fileKey,
                r.fileName,
                this.expertId
              )
            )
          )
        ),
        tap((files) => {
          this.files = [...this.files, ...files];
        }),
        finalize(() => {
          this.showUploadProgress = false;
          this.filesForUpload = [];
        })
      )
      .subscribe();
  }

  cancelUpload(): void {
    this.cancelUpload$.next();
  }

  cancelDownload(fileName: string): void {
    this.filesState.dispatch(new Delete(fileName));
  }

  private updateFiles(file: ExpertFile) {
    const idx = this.files.findIndex((f) => f.fileId === file.fileId);
    this.files[idx] = file;
  }
}
