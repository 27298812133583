import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { IEngagement, PaymentsService } from '@techspert-io/engagements';
import {
  FileDeleteConfirmationDialogComponent,
  FileStoreService,
} from '@techspert-io/file-store';
import {
  IOpportunity,
  OpportunitiesService,
  OpportunitySearchesService,
} from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import * as moment from 'moment';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { DeleteEngagementDialogComponent } from '../../../shared/components/delete-engagement-dialog/delete-engagement-dialog.component';
import { Client, IClient } from '../../../shared/models/client';
import { ConfirmationDialogComponent } from '../../patterns/confirmation-dialog/confirmation-dialog.component';
import {
  IAcceptanceRate,
  StatisticsService,
} from '../../services/statistics.service';

interface IDaysLeftInput {
  percentageRatio: string;
  circleText: string;
  currentValue: number;
}

interface IFillGraphInput extends IDaysLeftInput {
  lineColor: string;
}

type LegacyHtmlDialog = {
  showModal: () => void;
  close: () => void;
};

@Component({
  selector: 'app-opportunity-view-dialog',
  templateUrl: './opportunity-view-dialog.component.html',
  styleUrls: ['./opportunity-view-dialog.component.scss'],
})
export class OpportunityViewDialogComponent implements OnInit {
  public client: Client;
  public opportunity: IOpportunity;

  public savingChanges = false;
  public showUploadLoader = false;
  public startDate: string;
  public closeDate: string;
  public acceptancePercentage: number;
  public rejectionPercentage: number;
  public daysGraphInput: IDaysLeftInput;
  public fillGraphInput: IFillGraphInput;
  public acceptanceRate: IAcceptanceRate;
  public searchKey: string = '';
  public oppStats: any;

  public engagements: IEngagement[];

  constructor(
    private fileStoreService: FileStoreService,
    public paymentsService: PaymentsService,
    public cognitoAuthService: CognitoAuthService,
    public router: Router,
    public statisticsService: StatisticsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OpportunityViewDialogComponent>,
    private searchesService: OpportunitySearchesService,
    private opportunitiesService: OpportunitiesService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA)
    public data: { client: IClient; opportunityId: string }
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.opportunitiesService.get(this.data.opportunityId),
      this.paymentsService.query({
        opportunityId: this.data.opportunityId,
      }),
    ]).subscribe(([opportunity, engagements]) => {
      this.opportunity = opportunity;

      this.client = this.data.client;

      this.acceptanceRate = this.statisticsService.opportunityCalculator(
        'client-acceptance-rate',
        this.opportunity
      );

      if (!this.opportunity.files) {
        this.opportunity.files = [];
      }

      if (this.opportunity?.startDate && this.opportunity?.closeDate) {
        this.daysGraphInput = this.createDaysLeftGraphInput(
          this.opportunity.startDate,
          this.opportunity.closeDate
        );
      }

      if (this.opportunity?.expertTargetQuantity) {
        this.fillGraphInput = this.createFillGraphInput(
          this.acceptanceRate.count,
          this.opportunity.expertTargetQuantity
        );
      }

      this.oppStats = this.calculateOppStats(this.opportunity);

      this.acceptancePercentage = this.acceptanceRate.percentage;
      this.rejectionPercentage = this.statisticsService.opportunityCalculator(
        'client-rejection-rate',
        this.opportunity
      );

      this.engagements = engagements.sort((a, b) => {
        if (a.paymentActive === b.paymentActive) {
          return 0;
        }
        if (a.paymentActive) {
          return -1;
        }
        if (b.paymentActive) {
          return 1;
        }
        if (!a.paymentActive) {
          return 1;
        }
        if (!b.paymentActive) {
          return 1;
        }
      });
    });
  }

  public openDeleteEngagementDialog(engagementId: string): void {
    this.dialog
      .open(DeleteEngagementDialogComponent, {
        width: '400px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === 'delete') {
          this.paymentsService.delete(engagementId).subscribe(() => {
            this.engagements = this.engagements.filter(
              (engagement) => engagement.engagementId !== engagementId
            );
          });
        }
      });
  }

  public createEngagementUpdatePromises(engagementArr: IEngagement[]): any[] {
    return engagementArr.map((engagement) => {
      return new Promise((resolve, reject) => {
        this.paymentsService.updateLegacy(engagement).subscribe(
          (data) => {
            if (data) {
              resolve(data);
            }
          },
          (error) => reject(error)
        );
      });
    });
  }

  public openSearchDialog(key: string, dialogEl: LegacyHtmlDialog): void {
    this.searchKey = key;
    dialogEl.showModal();
  }

  public removeSearch(dialogEl: LegacyHtmlDialog): void {
    if (this.searchKey) {
      this.searchesService
        .delete({
          opportunityId: this.opportunity.opportunityId,
          searchName: this.searchKey,
        })
        .subscribe((res) => {
          this.opportunity = res;
          dialogEl.close();
        });
    }
  }

  public renameSearch(newSearchName: string, dialogEl: LegacyHtmlDialog): void {
    if (this.searchKey) {
      this.searchesService
        .update({
          opportunityId: this.opportunity.opportunityId,
          oldSearchName: this.searchKey,
          newSearchName,
        })
        .subscribe((res) => {
          this.opportunity = res;
          dialogEl.close();
        });
    }
  }

  public splitSearch(newSearchId: string, dialogEl: LegacyHtmlDialog): void {
    if (this.searchKey) {
      this.searchesService
        .split({
          opportunityId: this.opportunity.opportunityId,
          searchIdToSplit: this.searchKey,
          newSearchId,
        })
        .subscribe((res) => {
          this.opportunity = res;
          dialogEl.close();
        });
    }
  }

  createDaysLeftGraphInput(startDate: string, endDate: string): IDaysLeftInput {
    const projectStart = moment(startDate);
    const projectClose = moment(endDate);
    const currentDate = moment();
    const totalRange = projectClose.diff(projectStart, 'days');
    const daysThroughRange = currentDate.diff(projectStart, 'days');
    const totalRangeDuration = moment.duration(totalRange, 'd');
    const daysThroughRangeDuration = moment.duration(daysThroughRange, 'd');
    const totalRangeDays = moment.duration(totalRange, 'd').days();
    const startToCurrentDateDays = moment
      .duration(daysThroughRange, 'd')
      .days();
    const daysLeft = totalRangeDuration
      .subtract(daysThroughRangeDuration)
      .days();
    const percentThroughRange = (startToCurrentDateDays / totalRangeDays) * 100;
    const remainingPercentage = 100 - percentThroughRange;
    const percentageRatio = `${percentThroughRange} ${remainingPercentage}`;
    return {
      percentageRatio,
      circleText: 'Days left',
      currentValue: daysLeft,
    };
  }

  public viewOpportunitySearches(opportunity: IOpportunity): void {
    this.router.navigate([
      'admin',
      'client',
      this.client.clientId,
      'opportunity',
      opportunity.opportunityId,
      'search',
      'all-searches',
      'phase',
      'identified',
    ]);
    this.dialogRef.close();
  }

  public closeDialog(): void {
    this.updateOpportunity().subscribe(() =>
      this.dialogRef.close(this.opportunity)
    );
  }

  public saveChanges(): void {
    this.updateOpportunity().subscribe();
  }

  private createFillGraphInput(
    currentProgressCount: number,
    totalCount: number
  ): IFillGraphInput {
    const percentageThrough = (currentProgressCount / totalCount) * 100;
    const remainingPercentage = 100 - percentageThrough;
    const percentageRatio = `${percentageThrough} ${remainingPercentage}`;
    return {
      percentageRatio,
      circleText: 'Filled',
      currentValue: currentProgressCount,
      lineColor: '#52be80',
    };
  }

  public uploadFile(files: File[]): void {
    this.showUploadLoader = true;
    this.fileStoreService
      .uploadFiles(files, 'opportunities', this.opportunity.opportunityId)
      .pipe(
        filter((fileEvents) =>
          fileEvents.every((d) => d.status === 'complete')
        ),
        map((fileEvents) => fileEvents.map((d) => d.fileKey)),
        mergeMap((completedFileNames) =>
          this.saveNewOpportunityFiles(completedFileNames)
        ),
        finalize(() => {
          this.showUploadLoader = false;
        })
      )
      .subscribe();
  }

  public removeFile(fileName: string): void {
    this.dialog
      .open(FileDeleteConfirmationDialogComponent, {
        width: '450px',
        data: fileName,
      })
      .afterClosed()
      .pipe(
        takeWhile((save) => !!save),
        switchMap(() => this.fileStoreService.removeFile(fileName)),
        switchMap(() => this.removeOpportunityFile(fileName))
      )
      .subscribe();
  }

  public downloadFile(fileName: string): void {
    const [, ...displayFileName] = fileName.split('_');
    this.fileStoreService
      .downloadFile(fileName, displayFileName.join('_'))
      .subscribe();
  }

  private saveNewOpportunityFiles(
    uploadedFileNames: string[]
  ): Observable<unknown> {
    const update = {
      opportunityId: this.opportunity.opportunityId,
      files: [...this.opportunity.files, ...uploadedFileNames],
    };

    return this.opportunitiesService.update(update).pipe(
      tap((response) => {
        if (response) {
          this.opportunity.files = [
            ...this.opportunity.files,
            ...uploadedFileNames,
          ];
        }
      })
    );
  }

  private removeOpportunityFile(fileName: string): Observable<unknown> {
    const update = {
      opportunityId: this.opportunity.opportunityId,
      files: this.opportunity.files.filter((file) => file !== fileName),
    };

    return this.opportunitiesService.update(update).pipe(
      tap((response) => {
        if (response) {
          this.opportunity.files = this.opportunity.files.filter(
            (file) => file !== fileName
          );
        }
      })
    );
  }

  public openGenerateNewDisplayIdConfirmationDialog(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        height: '225px',
        data: {
          message:
            'Are you sure you would like to generate a new portal link ? This will invalidate all existing portal links and the new link will need to be redistributed among all involved parties.',
          positiveButtonText: 'Generate new link',
          negativeButtonText: 'Cancel',
          isDangerous: true,
        },
      })
      .afterClosed()
      .pipe(
        takeWhile((confirmed) => confirmed),
        switchMap(() =>
          this.updateOpportunityPublicDisplayId().pipe(
            tap((res) => {
              this.opportunity = {
                ...this.opportunity,
                publicDisplayId: res.publicDisplayId,
              };
              this.toastService.sendMessage(
                'Successfully updated portal link!',
                'success'
              );
            })
          )
        )
      )
      .subscribe();
  }

  private updateOpportunityPublicDisplayId(): Observable<{
    publicDisplayId: string;
  }> {
    return this.opportunitiesService
      .updatePublicId(
        this.opportunity.opportunityId,
        this.cognitoAuthService.loggedInUser.email
      )
      .pipe(
        catchError((err: Error) => {
          console.error(err);
          this.toastService.sendMessage(
            'Failed to update portal link',
            'error'
          );
          return throwError(err);
        })
      );
  }

  private updateOpportunity(): Observable<unknown> {
    this.savingChanges = true;
    const oppUpdate = {
      opportunityId: this.opportunity.opportunityId,
      cepLog: this.opportunity.cepLog,
      callTranscripts: this.opportunity.callTranscripts,
      screenshare: this.opportunity.screenshare,
      callRecording: this.opportunity.callRecording,
      anticipatedCallTime: this.opportunity.anticipatedCallTime,
      notes: this.opportunity.notes,
      files: this.opportunity.files,
      startDate: this.opportunity.startDate,
      closeDate: this.opportunity.closeDate,
      conferenceReminderDefaultSettings:
        this.opportunity.conferenceReminderDefaultSettings,
      blind: this.opportunity.blind,
      closedEmailSend: this.opportunity.closedEmailSend,
      segments: this.opportunity.segments,
      defaultConferenceTopic: this.opportunity.defaultConferenceTopic,
      screenerEnabled: this.opportunity.screenerEnabled,
      segmentConfig: this.opportunity.segmentConfig,
      automatedScheduling: this.opportunity.automatedScheduling,
      screenerComparisonEnabled: this.opportunity.screenerComparisonEnabled,
      failedScreenerEmailSend: this.opportunity.failedScreenerEmailSend,
      completedScreenerEmailSend: this.opportunity.completedScreenerEmailSend,
      omnisearchHidden: this.opportunity.omnisearchHidden,
      omnisearchExpertNotification:
        this.opportunity.omnisearchExpertNotification,
    };

    return this.opportunitiesService.update(oppUpdate).pipe(
      switchMap(() => {
        if (this.engagements?.length) {
          const engagementPromiseArray = this.createEngagementUpdatePromises(
            this.engagements
          );
          return from(Promise.all(engagementPromiseArray)).pipe(
            tap((updatedEngagements) => (this.engagements = updatedEngagements))
          );
        }
        return of([]);
      }),
      finalize(() => (this.savingChanges = false))
    );
  }

  private calculateOppStats(opportunity: IOpportunity): any {
    return {
      expertCount: this.statisticsService.opportunityCalculator(
        'expert-count',
        opportunity
      ),
      identified: this.statisticsService.stageCounts('identified', opportunity),
      firstFollowUp: this.statisticsService.stageCounts(
        'firstFollowUp',
        opportunity
      ),
      secondFollowUp: this.statisticsService.stageCounts(
        'secondFollowUp',
        opportunity
      ),
      outreachComplete: this.statisticsService.stageCounts(
        'outreachComplete',
        opportunity
      ),
      outreach: this.statisticsService.stageCounts('outreach', opportunity),
      screener: this.statisticsService.stageCounts('screener', opportunity),
      accepted: this.statisticsService.stageCounts('accepted', opportunity),
      sentToClient: this.statisticsService.stageCounts(
        'sentToClient',
        opportunity
      ),
      scheduled: this.statisticsService.stageCounts('scheduled', opportunity),
      completed: this.statisticsService.stageCounts('completed', opportunity),
      amRejectionRate: this.statisticsService.opportunityCalculator(
        'am-rejection-rate',
        opportunity
      ),
      clientRejectionRate: this.statisticsService.opportunityCalculator(
        'client-rejection-rate',
        opportunity
      ),
      expertResponseRate: this.statisticsService.opportunityCalculator(
        'expert-response-rate',
        opportunity
      ),
    };
  }
}
