import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOpportunityClientContact } from '@techspert-io/opportunities';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent {
  @Input() contact: IOpportunityClientContact;
  @Input() disableDelete: boolean;
  @Output() editContactSignal = new EventEmitter();
  @Output() deleteContactSignal = new EventEmitter();
  @Output() assignAsPrimaryContactSignal = new EventEmitter();

  edit(): void {
    this.editContactSignal.emit();
  }

  deleteContact(): void {
    this.deleteContactSignal.emit();
  }

  assignAsPrimaryContact(): void {
    this.assignAsPrimaryContactSignal.emit();
  }
}
