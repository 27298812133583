import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
@Component({
  selector: 'app-opportunity-files',
  templateUrl: './opportunity-files.component.html',
})
export class OpportunityFilesComponent {
  @Input() opportunity: IOpportunity;
  @Input() showUploadLoader: boolean;
  @Output() uploadFileSignal = new EventEmitter();
  @Output() removeFileSignal = new EventEmitter();
  @Output() downloadFileSignal = new EventEmitter();

  emitUploadedFile(files: File[]): void {
    this.uploadFileSignal.emit(files);
  }

  emitRemoveFile(fileName: string): void {
    this.removeFileSignal.emit(fileName);
  }

  emitDownloadFile(fileName: string): void {
    this.downloadFileSignal.emit(fileName);
  }
}
