import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import {
  IExpertAction,
  IExpertActionBase,
  IExpertActionQuery,
} from '../models/expertActions.models';

export const typeOfAction =
  <T extends IExpertActionBase>(
    ...types: ('call' | 'screener' | 'respondent' | 'availabilities')[]
  ): OperatorFunction<T[], T[]> =>
  (source): Observable<T[]> =>
    source.pipe(
      map((actions) => actions.filter((e) => types.includes(e.actionType)))
    );

@Injectable({
  providedIn: 'root',
})
export class ExpertActionService {
  private readonly baseUrl = '/expert-actions';
  private lastOptions: IExpertActionQuery = {};
  private optionsInner$ = new ReplaySubject<IExpertActionQuery>(1);

  actions$ = this.optionsInner$.pipe(
    switchMap((query) => this.query(query)),
    map((actions) => actions.sort((a, b) => a.lastUpdated - b.lastUpdated)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private http: HttpClient) {}

  setQueryOptions<T extends IExpertActionQuery>(query?: T): void {
    this.lastOptions = query;
    this.optionsInner$.next(query);
  }

  refresh(): void {
    this.optionsInner$.next(this.lastOptions);
  }

  private query(query: IExpertActionQuery = {}): Observable<IExpertAction[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) => (value ? prev.append(key, value) : prev),
      new HttpParams()
    );

    return this.http.get<IExpertAction[]>(
      `${this.baseUrl}/${query.actionType || 'all'}`,
      { params }
    );
  }
}
