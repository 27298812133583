import { IConferenceReminderEmail } from '../../models/conference-reminders.models';

export const conferenceReminderEmailMockData: IConferenceReminderEmail = {
  sender: 'sender@email.com',
  recipient: 'recipient@email.com',
  subject: 'subjectLine',
  htmlContent: '<p>text</p>',
  textContent: 'text',
  emailType: 'conference',
};
