import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService, IClient } from '@techspert-io/clients';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { combineLatest, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ExpertOperation } from './opportunity-results/opportunity-results.component';

@Component({
  selector: 'app-client-opportunity-searches',
  templateUrl: './client-opportunity-searches.component.html',
})
export class ClientOpportunitySearchesComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  opportunity: IOpportunity;
  selectedClient: IClient;
  activeSearch: string = 'all-searches';
  activePhase: string = 'identified';
  expertId: string;
  operation: ExpertOperation;
  isLoading: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private opportunitiesService: OpportunitiesService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap((params) => {
          this.activePhase = params.phase;
          this.operation = params.operation; // opening scheduler or expert dialog
          this.expertId = params.expertId;
        }),
        distinctUntilChanged(
          (a, b) => a.opportunity === b.opportunity && a.id === b.id
        ),
        tap(() => (this.isLoading = true)),
        switchMap(({ opportunity, id }) =>
          combineLatest([
            this.clientsService.get(id),
            this.opportunitiesService.get(opportunity),
          ])
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([client, opportunity]) => {
        this.isLoading = false;
        this.selectedClient = client;
        this.opportunity = opportunity;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
