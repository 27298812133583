import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IClient } from '@techspert-io/clients';

type cbChange = (c: IClient[]) => void;
type cbTouch = () => void;

@Component({
  selector: 'app-user-client-control',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: UserClientControlComponent,
      multi: true,
    },
  ],
  template: ` <ct-chip-list
    [list]="list"
    [placeholder]="placeholderMessage"
    label="Client"
    displayPropKey="clientName"
    optionDisplayPropKey="clientName"
    [errorMessage]="errorMessage"
    [autoCompleteOptions]="clientsFiltered"
    [allowOptionsOnly]="true"
    [disabled]="disabled"
    (addItemSignal)="addItem($event)"
    (removeItemSignal)="removeItem($event)"
  >
  </ct-chip-list>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class UserClientControlComponent implements ControlValueAccessor {
  @Input() clients: IClient[] = [];
  @Input() errorMessage?: string;
  @Input() multiple?: boolean = false;

  touched = false;
  disabled = false;
  list: IClient[] = [];
  set val(val: IClient[]) {
    this.list = val;
  }

  get clientsFiltered(): IClient[] {
    return (this.clients || []).filter(
      (d) => !this.list.some((l) => l.clientId === d.clientId)
    );
  }

  get placeholderMessage(): string {
    return !this.list.length
      ? 'Add client...'
      : this.multiple
      ? 'Add another...'
      : '';
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  writeValue(value: IClient[]): void {
    this.val = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addItem(item: IClient): void {
    if (item) {
      if (this.multiple) {
        this.list = [...this.list, item];
      } else {
        this.list = [item];
      }
      this.change();
    }
  }

  removeItem(item: IClient): void {
    this.list = this.list.filter((l) => l.clientId !== item.clientId);
    this.change();
  }

  private change(): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(this.list);
  }
}
