import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
  publishReplay,
  refCount,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  IOpportunity,
  IOpportunityCreateRequest,
  IOpportunityUpdateRequest,
  ISalesForceOpportunity,
} from '../models/opportunity.models';

export interface IOpportunityQueryParams {
  opportunityIds?: string[];
  clientIds?: string[];
  stageNames?: IOpportunity['stageName'][];
  opportunityAssigneeIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class OpportunitiesService {
  private readonly baseUrl = '/data';
  private readonly oppBaseUrl = '/opportunities';
  private readonly sfBaseUrl = '/salesforce';
  private sfOppsCache: Record<string, Observable<ISalesForceOpportunity[]>> =
    {};
  private sfOppsPreBidRFQCache: Observable<ISalesForceOpportunity[]>;

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getSalesforceOpportunities(
    clientId: string
  ): Observable<ISalesForceOpportunity[]> {
    if (!this.sfOppsCache[clientId]) {
      this.sfOppsCache[clientId] = this.http
        .get<ISalesForceOpportunity[]>(
          `${this.sfBaseUrl}/clients/${clientId}/opportunities`
        )
        .pipe(
          catchError((err) => {
            this.toastService.sendMessage(err.message, 'error');
            return of([]);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.sfOppsCache[clientId];
  }

  getPreBidRFQSfOpportunities(): Observable<ISalesForceOpportunity[]> {
    if (!this.sfOppsPreBidRFQCache) {
      this.sfOppsPreBidRFQCache = this.http
        .get<ISalesForceOpportunity[]>(
          `${this.sfBaseUrl}/opportunities/pre-bid-rfq`
        )
        .pipe(
          catchError((err) => {
            this.toastService.sendMessage(err.message, 'error');
            return throwError(err);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.sfOppsPreBidRFQCache;
  }

  getSalesforceOpportunity(
    clientId: string,
    sfOppdId: string
  ): Observable<ISalesForceOpportunity> {
    return this.getSalesforceOpportunities(clientId).pipe(
      switchMap((res) => res.filter((s) => s.sfOppId === sfOppdId))
    );
  }

  create(opp: IOpportunityCreateRequest): Observable<IOpportunity> {
    return this.http.post<IOpportunity>(`${this.oppBaseUrl}`, opp).pipe(
      tap(() => (this.sfOppsCache[opp.clientId] = null)), // Reset the cache
      tap((res) =>
        this.toastService.sendMessage(
          `Created "${res.opportunityName}"`,
          'success'
        )
      ),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.toastService.sendMessage(err.error.message, 'error');
          return throwError(err.error);
        }
        return throwError(err);
      })
    );
  }

  update(opp: IOpportunityUpdateRequest): Observable<IOpportunity> {
    return this.http
      .patch<IOpportunity>(`${this.oppBaseUrl}/${opp.opportunityId}`, opp)
      .pipe(
        tap((res) =>
          this.toastService.sendMessage(
            `Updated "${res.opportunityName}"`,
            'success'
          )
        ),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this.toastService.sendMessage(err.error.message, 'error');
            return throwError(err.error);
          }
          return throwError(err);
        })
      );
  }

  query(query: IOpportunityQueryParams): Observable<IOpportunity[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) =>
        value?.length ? prev.append(key, value.join(',')) : prev,
      new HttpParams()
    );

    return this.http.get<IOpportunity[]>(`${this.oppBaseUrl}`, { params });
  }

  get(oppId: string): Observable<IOpportunity> {
    return this.query({ opportunityIds: [oppId] }).pipe(switchMap((r) => r));
  }

  updatePublicId(
    opportunityId: string,
    email: string
  ): Observable<{ publicDisplayId: string }> {
    return this.http.post<{ publicDisplayId: string }>(
      `${this.oppBaseUrl}/${opportunityId}/updatePublicId`,
      { opportunityId, email }
    );
  }

  appendSearchToOpportunity(payload: {
    opportunityId: string;
    searches: IOpportunity['searches'];
  }): Observable<IOpportunity> {
    return this.http.post<IOpportunity>(
      `${this.baseUrl}/opportunities/append_search`,
      payload
    );
  }
}
