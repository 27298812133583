import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeWhile } from 'rxjs/operators';
import {
  IClientContactForm,
  IOpportunityClientContact,
} from '../../../models/opportunity.models';
import { ClientContactDialogComponent } from '../../dialogs/client-contact-dialog/client-contact-dialog.component';

@Component({
  selector: 'opp-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientContactsComponent {
  @Input() clientContacts: IOpportunityClientContact[];
  @Output() upsert = new EventEmitter<IOpportunityClientContact>();
  @Output() delete = new EventEmitter<IOpportunityClientContact>();
  @Output() setPrimary = new EventEmitter<IOpportunityClientContact>();

  clientContactsColumns = ['name', 'email', 'country', 'timezone'];
  clientContactsCells = [...this.clientContactsColumns, 'options'];

  constructor(private dialog: MatDialog) {}

  upsertItem(data?: Partial<IClientContactForm>): void {
    this.dialog
      .open<
        ClientContactDialogComponent,
        Partial<IClientContactForm>,
        IClientContactForm
      >(ClientContactDialogComponent, {
        width: '840px',
        data,
      })
      .afterClosed()
      .pipe(takeWhile((d) => !!d))
      .subscribe((item) =>
        this.upsert.emit({
          ...data,
          ...item,
        })
      );
  }

  deleteItem(data: IOpportunityClientContact): void {
    this.delete.emit(data);
  }

  setPrimaryItem(data: IOpportunityClientContact): void {
    this.setPrimary.emit(data);
  }
}
