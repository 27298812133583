import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IExpertConferenceEdit } from '@techspert-io/conferences';
import { IDisplayExpert, IExpert } from '@techspert-io/experts';

@Component({
  selector: 'app-opportunity-expert-list',
  templateUrl: './opportunity-expert-list.component.html',
  styleUrls: ['./opportunity-expert-list.component.scss'],
})
export class OpportunityExpertListComponent {
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;
  @Input() experts: IDisplayExpert[] = [];
  @Input() phase: string = 'identified';
  @Input() openFilter: boolean;
  @Input() showAffiliations: boolean;
  @Output() selectExpertSignal = new EventEmitter();
  @Output() editExpertSignal = new EventEmitter();
  @Output() toggleAdminRejectStatusSignal = new EventEmitter();
  @Output() rescheduleConferenceSignal = new EventEmitter();
  @Output() callDetailsSignal = new EventEmitter();
  @Output() emergencyContactSignal = new EventEmitter();
  @Output() toggleBlockExpertSignal = new EventEmitter();
  @Output() navigateToExpertSignal = new EventEmitter();
  @Output() addExpertiseToSearchSignal = new EventEmitter();
  @Output() showScreenerResultsSignal = new EventEmitter();
  @Output() scheduleSignal = new EventEmitter();

  public emitScheduleSignal(expert: any): void {
    this.scheduleSignal.emit(expert);
  }

  public trackByFn(_index: number, item: IExpert): string {
    return item.expertId;
  }

  public goToTop(): void {
    this.virtualScroll.scrollToIndex(0);
  }

  public emitSelectExpertSignal(expert: any): void {
    this.selectExpertSignal.emit(expert);
  }

  public emitToggleBlockExpertSignal(event: any): void {
    this.toggleBlockExpertSignal.emit(event);
  }

  public emitEditExpertSignal(expert: any): void {
    this.editExpertSignal.emit(expert);
  }

  public emitToggleAdminRejectStatusSignal(event: any): void {
    this.toggleAdminRejectStatusSignal.emit(event);
  }

  public emitCallActionSignal(event: IExpertConferenceEdit): void {
    if (event.action === 'view') {
      this.callDetailsSignal.emit(event);
    } else if (event.action === 'updateEmergencyContact') {
      this.emergencyContactSignal.emit(event);
    } else {
      this.rescheduleConferenceSignal.emit(event);
    }
  }

  public emitNavigateToExpertSignal(event: any): void {
    this.navigateToExpertSignal.emit(event);
  }

  public emitAddExpertiseToSearchSignal(event: any): void {
    this.addExpertiseToSearchSignal.emit(event);
  }

  public emitShowScreenerResults(expert: IExpert): void {
    this.showScreenerResultsSignal.emit(expert);
  }
}
