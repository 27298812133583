import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IExpert } from '@techspert-io/experts';
import { ToastService } from '@techspert-io/user-alerts';
import { extendMoment } from 'moment-range';
import * as moment from 'moment-timezone';

interface IFormDates {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export interface IExpertAvailabilitySlot {
  start: string;
  end: string;
}

@Component({
  selector: 'app-event-picker',
  templateUrl: './event-picker.component.html',
  styleUrls: ['./event-picker.component.scss'],
})
export class EventPickerComponent implements OnInit {
  @Input() slotFrequencyMins = 30;
  @Input() meetingDuration = 60;
  @Input() timezoneName: IExpert['timezoneName'];
  @Output() eventCreated = new EventEmitter<IExpertAvailabilitySlot>();
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    const today = moment().set({ hour: 12, minutes: 0 });

    this.resestDatePickers(today);
  }

  submitDates(formData: IFormDates): void {
    if (Object.values(formData).some((v) => !v)) {
      this.toastService.sendMessage('Enter valid dates/times', 'error');
      return;
    }

    const { start, end } = this.buildDates(
      formData.startDate,
      formData.startTime,
      formData.endDate,
      formData.endTime
    );

    if (this.isTimeSpanValid(start, end)) {
      this.enumerateDaysBetweenDates(start, end, this.slotFrequencyMins)
        .map((slot) => this.mapAvailability(slot, this.meetingDuration))
        .filter((a) => moment(a.end).isSameOrBefore(end))
        .forEach((a) => this.eventCreated.emit(a));
    }
  }

  updateEndDate(): void {
    const { start, end } = this.buildDates(
      this.startDate,
      this.startTime,
      this.endDate,
      this.endTime
    );
    if (moment(end).isSameOrBefore(start)) {
      const endDate = start.add(this.meetingDuration, 'm');
      this.endDate = endDate.format('YYYY-MM-DD');
      this.endTime = endDate.format('HH:mm');
    }
  }

  private buildDates(
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ): { start: moment.Moment; end: moment.Moment } {
    const start = moment.tz(`${startDate} ${startTime}`, this.timezoneName);
    const end = moment.tz(`${endDate} ${endTime}`, this.timezoneName);
    return { start, end };
  }

  private isTimeSpanValid(
    startDate: moment.Moment,
    endDate: moment.Moment
  ): boolean {
    if (endDate.isSameOrBefore(startDate, 'm')) {
      this.toastService.sendMessage(
        'End time must be after start time',
        'error'
      );
      return false;
    }

    if (
      endDate.isBefore(startDate.clone().add(this.meetingDuration, 'm'), 'm')
    ) {
      this.toastService.sendMessage(
        `Time span is too short for a ${this.meetingDuration} minute call`,
        'error'
      );
      return false;
    }
    return true;
  }

  private enumerateDaysBetweenDates(
    startDate: moment.Moment,
    endDate: moment.Moment,
    sessionDuration: number
  ): moment.Moment[] {
    const day = extendMoment(moment).range(startDate, endDate);
    const timeslots = Array.from(day.by('minutes', { step: sessionDuration }));
    return timeslots.map((slot) => slot);
  }

  private mapAvailability(
    slot: moment.Moment,
    meetingDuration: number
  ): IExpertAvailabilitySlot {
    const slotTz = moment.tz(slot, this.timezoneName).clone();
    return {
      start: slotTz.toISOString(),
      end: slotTz.add(meetingDuration, 'm').toISOString(),
    };
  }

  private resestDatePickers(startDate: moment.Moment): void {
    this.startDate = startDate.format('YYYY-MM-DD');
    this.startTime = startDate.format('HH:mm');

    const endDate = startDate.clone().add(this.meetingDuration, 'm');
    this.endDate = endDate.format('YYYY-MM-DD');
    this.endTime = endDate.format('HH:mm');
  }
}
