import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { tap } from 'rxjs/operators';
import { ChipListStyle } from '../../../../shared/patterns/chip-list/chip-list.component';
import { IRelatedTerms } from '../../models/query';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-expertise-chip-list',
  template: `
    <ct-chip-list
      fxFlex="90%"
      [list]="displayedTerms"
      [label]="fieldDisplay"
      [placeholder]="'Enter terms...'"
      [chipStyles]="styles"
      [disabled]="disabled"
      (addItemSignal)="addTerm($event)"
      (removeItemSignal)="removeTerm($event)"
      (selectItemSignal)="expandTerm($event)"
      (listChange)="onListChange($event)"
      [splitByPipe]="true"
      data-e2e="query-creator-expertise-conditions-list"
    >
    </ct-chip-list>
  `,
})
export class ExpertiseChipListComponent implements OnInit, OnChanges {
  @Output() addItemSignal = new EventEmitter<string>();
  @Output() removeItemSignal = new EventEmitter<string>();
  @Output() listChange = new EventEmitter<string[]>();

  @Input() terms: string[] = [];
  @Input() disabled: boolean;
  @Input() fieldDisplay: string;

  styles: Record<string, ChipListStyle> = {};
  mainTerms: string[] = [];
  expandedTerms: string[] = [];
  displayedTerms: string[] = [];

  constructor(
    private searchService: SearchService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.mainTerms = this.terms;
    this.displayedTerms = [...this.mainTerms];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.terms) {
      this.mainTerms = changes.terms.currentValue;
      this.displayedTerms = [...this.mainTerms, ...this.expandedTerms];
    }
  }

  expandTerm(term: string): void {
    term = term.toLowerCase().trim();

    if (this.expandedTerms.includes(term) && this.styles[term]) {
      this.styles[term] = undefined;
      this.mainTerms = [...this.mainTerms, term];
      this.expandedTerms = this.expandedTerms.filter((t) => t !== term);

      this.addItemSignal.emit(term);
    } else {
      this.searchService
        .knowledgeGraph([term])
        .pipe(
          tap((expandedTerms) => this.addExpandConditions(expandedTerms, term))
        )
        .subscribe();
    }
  }

  addTerm(term: string): void {
    this.addItemSignal.emit(term.toLowerCase().trim());
  }

  removeTerm(removalTerm: string): void {
    this.expandedTerms = this.expandedTerms.filter(
      (term) => term !== removalTerm
    );

    this.removeItemSignal.emit(removalTerm);
  }

  onListChange(event: string[]): void {
    this.listChange.emit(event.filter((t) => !this.expandedTerms.includes(t)));
  }

  private addExpandConditions(
    relatedTerms: IRelatedTerms,
    baseTerm: string
  ): void {
    const fetchedExpanded = [
      relatedTerms.disease_ontology_synonym,
      relatedTerms.mesh_synonym,
      relatedTerms.disease_ontology_child,
      relatedTerms.mag_child,
      relatedTerms.mesh_child,
    ]
      .flat()
      .map((t) => t.toLowerCase().trim())
      .filter((t) => !this.mainTerms.includes(t));
    this.expandedTerms = [
      ...new Set([...this.expandedTerms, ...fetchedExpanded]),
    ];
    this.displayedTerms = [...this.mainTerms, ...this.expandedTerms];

    const styleMap = {
      disease_ontology_synonym: 'solid-border',
      mesh_synonym: 'solid-border',
      disease_ontology_child: 'dashed-border',
      mag_child: 'dashed-border',
      mesh_child: 'dashed-border',
    };

    const terms: {
      term: string;
      style: ChipListStyle;
    }[] = Object.keys(styleMap)
      .map((k) =>
        relatedTerms[k]
          .flat()
          .filter((t) => !this.mainTerms.includes(t))
          .map((term) => ({
            term,
            style: styleMap[k],
          }))
      )
      .flat();

    this.styles = terms.reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr.term]: curr.style,
        };
      },
      { ...this.styles }
    );

    if (!terms.length) {
      this.toastService.sendMessage(
        `No terms found to expand from ${baseTerm}`,
        'success'
      );
    }
  }
}
