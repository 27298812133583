import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'app-opportunity-tile',
  templateUrl: './opportunity-tile.component.html',
  styleUrls: ['./opportunity-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityTileComponent implements OnChanges {
  @Input() opportunity: IOpportunity;
  @Input() showDetailsDialogButton = true;
  @Output() openOpportunityDialogSignal = new EventEmitter<IOpportunity>();

  opportunityClosedConnections = 0;
  opportunityOpenConnections = 0;
  opportunityUrl: string[];

  constructor(private statisticsService: StatisticsService) {}

  ngOnChanges(): void {
    this.opportunityUrl = [
      '/',
      'admin',
      'client',
      this.opportunity.clientId,
      'opportunity',
      this.opportunity.opportunityId,
      'search',
      'all-searches',
      'phase',
      'identified',
    ];

    this.opportunityOpenConnections =
      this.statisticsService.opportunityCalculator(
        'open-connections',
        this.opportunity
      );
    this.opportunityClosedConnections =
      this.statisticsService.opportunityCalculator(
        'closed-connections',
        this.opportunity
      );
  }

  openDetailsDialog(e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.openOpportunityDialogSignal.emit(this.opportunity);
  }
}
