import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IEngagement,
  IExtendedPaymentEngagement,
} from '../models/engagements.models';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private readonly baseUrl = '/payments';

  constructor(private http: HttpClient) {}

  get(
    paymentStatus: string,
    dateMin: string,
    dateMax: string
  ): Observable<IExtendedPaymentEngagement[]> {
    if (!paymentStatus) {
      return of([]);
    }

    const params = new HttpParams()
      .set('paymentStatus', paymentStatus)
      .set('dateMin', dateMin)
      .set('dateMax', dateMax);

    return this.http
      .get<IExtendedPaymentEngagement[]>(this.baseUrl, {
        params,
      })
      .pipe(catchError(() => of([])));
  }

  query(query: {
    expertId?: string;
    opportunityId?: string;
  }): Observable<IEngagement[]> {
    const params = Object.entries(query).reduce(
      (prev, [key, value]) => prev.append(key, value),
      new HttpParams()
    );

    return this.http.get<IEngagement[]>(`${this.baseUrl}/engagements`, {
      params,
    });
  }

  create(engagement: IEngagement): Observable<IEngagement> {
    return this.http.post<IEngagement>(this.baseUrl, engagement);
  }

  /** Bypasses checks on date of engagement */
  updateLegacy(engagement: Partial<IEngagement>): Observable<IEngagement> {
    const {
      engagementId,
      opportunityName,
      opportunityId,
      expertId,
      ...safeProps
    } = engagement;

    return this.http.patch<IEngagement>(
      `${this.baseUrl}/legacy/${engagementId}`,
      safeProps
    );
  }

  update(engagement: Partial<IEngagement>): Observable<IEngagement> {
    const {
      engagementId,
      opportunityName,
      opportunityId,
      expertId,
      ...safeProps
    } = engagement;

    return this.http.patch<IEngagement>(
      `${this.baseUrl}/${engagementId}`,
      safeProps
    );
  }

  delete(engagementId: string): Observable<string> {
    return this.http.delete<string>(`${this.baseUrl}/${engagementId}`);
  }
}
