import { Directive, OnInit } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import { ChipListComponent } from '../../../shared/patterns/chip-list/chip-list.component';
import { SearchService } from '../services/search.service';

@Directive({ selector: 'ct-chip-list[expertise-autocomplete]' })
export class ChipListExpertiseAutocompleteDirective implements OnInit {
  constructor(
    private chipListComponent: ChipListComponent,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.chipListComponent.filterAutoCompleteOptions = false;

    this.chipListComponent.optionsControl.valueChanges
      .pipe(
        debounceTime(200),
        tap((value) => this.onExpertiseInputChange(value))
      )
      .subscribe();
  }

  private onExpertiseInputChange(input: string | null): void {
    if (!input) {
      this.chipListComponent.autoCompleteOptions = [];
      return;
    }

    this.searchService
      .expertiseAutocomplete(input, 10)
      .subscribe((res) => (this.chipListComponent.autoCompleteOptions = res));
  }
}
