import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IEngagement,
  PaymentsService,
  PaymentStatus,
} from '@techspert-io/engagements';
import { ExportToCsv } from 'export-to-csv';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { filter, map, scan, startWith, switchMap, tap } from 'rxjs/operators';
import { EngagementsDialogComponent } from '../../admin-portal/payments/engagements-dialog/engagements-dialog.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { IPaymentDashboardEngagement } from './payments-models';

interface ISelect {
  value: PaymentStatus;
  display: string;
}
interface ITranferWiseUploadObject {
  name: string;
  recipientEmail: string;
  paymentReference: string;
  receiverType: string;
  amountCurrency: string;
  amount: number;
  sourceCurrency: string;
  targetCurrency: string;
  type: string;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  @ViewChild('PaymentsCsvDialog') public paymentsCsvDialog: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public paymentStatuses: ISelect[] = [
    { value: 'not-paid', display: 'Not paid' },
    { value: 'payment-sent', display: 'Payment sent' },
    { value: 'payment-received', display: 'Payment received' },
    { value: 'not-claimed', display: 'Not claimed' },
  ];
  public displayedColumns = [
    'Select',
    'expertName',
    'email',
    'engagementTitle',
    'opportunityName',
    'owner',
    'amountOwed',
    'currency',
    'paymentType',
    'dateCreated',
    'dateOfEngagement',
    'paymentStatus',
    'Edit',
    'Add',
  ];
  public dataSource: MatTableDataSource<IPaymentDashboardEngagement>;
  public updatePaymentStatus: boolean = false;
  public allEngagements: IPaymentDashboardEngagement[] = [];
  public engagementsSelection = new SelectionModel<IPaymentDashboardEngagement>(
    true,
    []
  );
  public showLoader: boolean;

  public getEngagements$ = new Observable<IPaymentDashboardEngagement[]>();

  filterForm = new FormGroup({
    paymentStatus: new FormControl(['not-paid']),
    dateMin: new FormControl(
      this.formatDate(Date.now() - 1000 * 60 * 60 * 24 * 30)
    ),
    dateMax: new FormControl(this.formatDate(Date.now())),
  });

  searchForm = new FormGroup({
    search: new FormControl(''),
  });

  public newEngagements$ = new BehaviorSubject<IPaymentDashboardEngagement[]>(
    []
  );

  private sidebarDimensions = {
    width: '375px',
    height: '100vh',
    position: { right: '0' },
  };

  constructor(
    public dialog: MatDialog,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.showLoader = true;

    const formChanges$ = this.filterForm.valueChanges.pipe(
      startWith(this.filterForm.value),
      tap(() => (this.showLoader = true)),
      switchMap(({ paymentStatus, dateMin, dateMax }) => {
        const filterMaxDate = new Date(dateMax);
        return this.paymentsService.get(
          paymentStatus.join(','),
          dateMin,
          new Date(filterMaxDate.setDate(filterMaxDate.getDate() + 1))
            .toISOString()
            .slice(0, 10)
        );
      })
    );

    const searchFormChanges$ = this.searchForm.valueChanges.pipe(
      startWith(this.searchForm.value),
      map((searchForm) => searchForm.search)
    );

    combineLatest([
      this.newEngagements$.pipe(
        scan<IPaymentDashboardEngagement[]>(
          (acc, value) => [...acc, ...value],
          []
        )
      ),
      formChanges$,
      searchFormChanges$,
    ]).subscribe(([newEngagements, engagements, search]) => {
      const engagementDict = [...engagements, ...newEngagements]
        .filter((e) => e.paymentType !== 'thirdParty')
        .reduce<Record<string, IPaymentDashboardEngagement>>(
          (acc, cur) => ({ ...acc, [cur.engagementId]: cur }),
          {}
        );

      this.allEngagements = Object.values(engagementDict);

      this.allEngagements = this.filterEngagements(this.allEngagements, search);
      this.formulateEngagements(this.allEngagements);
      this.engagementsSelection.clear();
      this.showLoader = false;
    });
  }

  isAllSelected(): boolean {
    if (this.dataSource && this.dataSource.data) {
      const numSelected = this.engagementsSelection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return true;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.engagementsSelection.clear()
      : this.dataSource.data.forEach((engagement) =>
          this.engagementsSelection.select(engagement)
        );
  }

  checkboxLabel(row?: IPaymentDashboardEngagement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all engagements`;
    }
    return `${
      this.engagementsSelection.isSelected(row) ? 'deselect' : 'select'
    } engagement: ${row.engagementTitle}`;
  }

  downloadEngagementsCsvHandler(): void {
    const selectedEngagements = this.engagementsSelection.selected;
    const transferWiseObjects =
      this.aggregateEngagementsToTransferWiseObjects(selectedEngagements);
    this.downloadEngagementsCsv(transferWiseObjects);
    if (this.updatePaymentStatus && selectedEngagements.length) {
      const updates$ = selectedEngagements
        .filter((engagement) => engagement.paymentStatus === 'not-paid')
        .map((e) =>
          this.paymentsService.updateLegacy({
            engagementId: e.engagementId,
            paymentStatus: 'payment-sent',
          })
        );

      forkJoin(updates$)
        .pipe(
          tap(() => {
            selectedEngagements.forEach((selectedEngagement) => {
              const index = this.allEngagements.findIndex(
                (engagement) =>
                  engagement.engagementId === selectedEngagement.engagementId
              );
              this.allEngagements[index].paymentStatus = 'payment-sent';
            });
            this.closePaymentsCsvDialog();
            this.engagementsSelection.clear();
          })
        )
        .subscribe();
    }
  }

  openPaymentsCsvDialog(): void {
    this.paymentsCsvDialog.nativeElement.style.display = 'flex';
    this.paymentsCsvDialog.nativeElement.showModal();
  }

  closePaymentsCsvDialog(): void {
    this.paymentsCsvDialog.nativeElement.style.display = 'none';
    this.paymentsCsvDialog.nativeElement.close();
    this.updatePaymentStatus = false;
  }

  openNotesDialog(engagement: IPaymentDashboardEngagement): void {
    const dialogRef: MatDialogRef<NotesDialogComponent> = this.dialog.open(
      NotesDialogComponent,
      {
        ...this.sidebarDimensions,
        data: {
          engagement,
        },
      }
    );
    dialogRef.afterClosed().subscribe();
  }

  createPaymentForEngagement(engagement: IPaymentDashboardEngagement): void {
    const payment: IEngagement = {
      engagementTitle: `Payment - [${engagement.expertName}]`,
      engagementType: 'payment',
      dateOfEngagement: new Date().toISOString(),
      paymentStatus: 'not-paid',
      rate: 0,
      quantityEngaged: 0,
      unitsUsed: 0,
      unitsUsedAdjustment: 0,
      amountOwed: 0,
      paymentType: engagement.paymentType,
      notes: `Payment correction for ${engagement.engagementTitle}`,
      transactionId: '',
      currency: engagement.currency,
      email: engagement.email,
      opportunityName: engagement.opportunityName,
      opportunityId: engagement.opportunityId,
      expertName: engagement.expertName,
      expertId: engagement.expertId,
      paymentActive: true,
      lastAccepted: engagement.lastAccepted,
      paymentProvider: 'Techspert',
    };
    this.dialog
      .open<
        EngagementsDialogComponent,
        { engagement: IEngagement },
        { action: string; engagement: IEngagement }
      >(EngagementsDialogComponent, {
        ...this.sidebarDimensions,
        data: {
          engagement: payment,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.action === 'create' && !!res.engagement),
        switchMap((res) =>
          this.paymentsService.create(res.engagement).pipe(
            tap((createdEngagement) => {
              this.newEngagements$.next([
                {
                  ...createdEngagement,
                  owner: engagement.owner,
                },
              ]);
            })
          )
        )
      )
      .subscribe();
  }

  updateEngagementPaymentStatus(engagementId: string): void {
    this.paymentsService
      .updateLegacy({
        engagementId,
        paymentStatus: this.allEngagements.find(
          (engagement) => engagementId === engagement.engagementId
        ).paymentStatus,
        paymentProvider: 'Techspert',
      })
      .subscribe();
  }

  private aggregateEngagementsToTransferWiseObjects(
    selectedEngagements: IPaymentDashboardEngagement[]
  ): ITranferWiseUploadObject[] {
    const aggregatedDictionary = selectedEngagements.reduce(
      (acc, engagement) => {
        if (acc[engagement.expertId]) {
          acc[engagement.expertId].amount += engagement.amountOwed;
        } else {
          acc[engagement.expertId] = {
            name: `${engagement.expertName}`,
            recipientEmail: engagement.email,
            paymentReference: engagement.expertId
              ? engagement.expertId.substring(0, 10)
              : 'No Expert Id',
            receiverType: 'PRIVATE',
            amountCurrency: engagement.currency,
            amount: engagement.amountOwed,
            sourceCurrency: 'USD',
            targetCurrency: engagement.currency,
            type: 'EMAIL',
          };
        }
        return acc;
      },
      {}
    );
    return Object.values(aggregatedDictionary);
  }

  private formatDate(date: number): string {
    return new Date(date).toISOString().split('T')[0];
  }

  private downloadEngagementsCsv(
    transferWisePaymentObjects: ITranferWiseUploadObject[]
  ): void {
    const csvOptions = {
      fieldSeparator: ',',
      filename: `payments-batch-${new Date().toISOString().slice(0, 10)}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `payments-batch-${new Date().toISOString().slice(0, 10)}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(transferWisePaymentObjects);
  }

  private filterEngagements(
    allEngagements: IPaymentDashboardEngagement[],
    searchTerm: string
  ): IPaymentDashboardEngagement[] {
    return allEngagements.filter(
      (engagement) =>
        engagement.expertName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (searchTerm.length > 8 &&
          engagement.expertId &&
          engagement.expertId.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }

  private formulateEngagements(
    engagements: IPaymentDashboardEngagement[]
  ): void {
    this.dataSource = new MatTableDataSource<IPaymentDashboardEngagement>(
      engagements
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
