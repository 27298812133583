import { Injectable } from '@angular/core';
import { ConferencesConferenceRemindersService } from '@techspert-io/conferences';
import { IEngagement, PaymentsService } from '@techspert-io/engagements';
import {
  ConnectPhase,
  connectPhaseList,
  ExpertsQueryService,
  IDisplayExpert,
  IDisplayExpertCallActions,
  IExpert,
  IQueryExpertSearchRequest,
} from '@techspert-io/experts';
import {
  combineLatest,
  from,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subject,
} from 'rxjs';
import {
  concatMap,
  map,
  mergeMap,
  reduce,
  scan,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

enum Actions {
  UpsertMany = 'UpsertMany',
  UpsertOne = 'UpsertOne',
  SetCount = 'SetCount',
  SetCurrentOpp = 'SetCurrentOpp',
  ResetOpportunity = 'ResetOpportunity',
}

interface IState {
  ids: string[];
  experts: Record<string, IDisplayExpert>;
  totals: Record<string, Record<string, Record<ConnectPhase, number>>>;
  currentOppId: string;
}

interface IAction<T = unknown> {
  type: Actions;
  payload: T;
}

class UpsertMany implements IAction {
  readonly type = Actions.UpsertMany;
  constructor(
    public payload: {
      experts: IDisplayExpert[];
      totals: {
        searchId: string;
        totals: Record<ConnectPhase, number>;
      }[];
    }
  ) {}
}

class UpsertOne implements IAction {
  readonly type = Actions.UpsertOne;
  constructor(public payload: Partial<IDisplayExpert>) {}
}

class SetCurrentOpp implements IAction {
  readonly type = Actions.SetCurrentOpp;
  constructor(public payload: { oppId: string }) {}
}

class ResetOpportunity implements IAction {
  readonly type = Actions.ResetOpportunity;
  constructor(public payload: { opportunityId: string }) {}
}

type ActionTypes = UpsertMany | UpsertOne | SetCurrentOpp | ResetOpportunity;

type LoadingState = {
  name: string;
  status: 'init' | 'partial' | 'loading' | 'loaded';
};

interface ISearchRequest {
  searchIds: string[];
  fromPhase?: ConnectPhase;
}

@Injectable({
  providedIn: 'root',
})
export class OpportunityExpertsService {
  private optionsInner$ = new Subject<IQueryExpertSearchRequest>();
  private searchRequestInner$ = new Subject<ISearchRequest>();
  private actions$ = new Subject<ActionTypes>();
  private loadingInner$ = new Subject<LoadingState[]>();
  private cancelDownload$ = new Subject();

  private expertsInit$ = this.optionsInner$.pipe(
    tap((opts) =>
      this.loadingInner$.next(
        opts.searchIds.map((name) => ({ name, status: 'init' }))
      )
    ),
    switchMap((opts) =>
      this.expertsQueryService
        .query(opts)
        .pipe(
          tap((res) =>
            this.loadingInner$.next(
              res.map((r) => ({ name: r.searchId, status: 'partial' }))
            )
          )
        )
    )
  );

  private expertsDownloads$ = this.searchRequestInner$.pipe(
    tap(({ searchIds }) =>
      this.loadingInner$.next(
        searchIds.map((name) => ({ name, status: 'loading' }))
      )
    ),
    withLatestFrom(this.optionsInner$),
    mergeMap(([req, { opportunityId }]) =>
      this.expertsQueryService.query({ opportunityId, ...req }).pipe(
        takeUntil(
          this.cancelDownload$.pipe(
            tap(() =>
              this.loadingInner$.next(
                req.searchIds.map((name) => ({
                  name,
                  status: 'partial',
                }))
              )
            )
          )
        ),
        tap(() =>
          this.loadingInner$.next(
            req.searchIds.map((name) => ({ name, status: 'loaded' }))
          )
        )
      )
    )
  );

  private expertsMapped$ = combineLatest([
    merge(this.expertsInit$, this.expertsDownloads$),
  ]).pipe(
    concatMap(([ex]) =>
      of(Object.values(ex).flatMap((e) => e.experts)).pipe(
        mergeMap((experts) =>
          combineLatest([
            this.getEngagements(experts),
            this.getCallActions(experts),
          ]).pipe(
            map(([engagements, callActions]) =>
              experts.map((e) => ({
                ...e,
                engagements: engagements[e.expertId] || [],
                callActions: callActions[e.expertId],
              }))
            ),
            map(
              (r) =>
                new UpsertMany({
                  experts: r.map((e) => ({ ...e, isSelected: false })),
                  totals: ex.map((t) => ({
                    searchId: t.searchId,
                    totals: t.totals,
                  })),
                })
            )
          )
        )
      )
    )
  );

  private expertsState$ = merge(this.expertsMapped$, this.actions$).pipe(
    this.scanState()
  );

  downloadedSearches$ = this.loadingInner$.pipe(
    switchMap((d) => d),
    withLatestFrom(this.expertsState$),
    scan<
      [LoadingState, IState],
      Record<string, Record<string, LoadingState['status']>>
    >(
      (prev, [curr, { currentOppId }]) => ({
        ...prev,
        [currentOppId]: {
          ...prev[currentOppId],
          [curr.name]:
            curr.status === 'loading'
              ? 'loading'
              : prev[currentOppId]?.[curr.name] === 'loaded'
              ? 'loaded'
              : curr.status,
        },
      }),
      {}
    ),
    withLatestFrom(this.expertsState$),
    map(([state, { currentOppId }]) => state[currentOppId]),
    shareReplay(1)
  );

  experts$ = this.expertsState$.pipe(
    map((state) =>
      Object.values(state.experts)
        .filter((e) => e.opportunityId === state.currentOppId)
        .sort(this.sortExperts)
    ),
    shareReplay(1)
  );

  expertTotals$ = this.expertsState$.pipe(
    map((state) => state.totals[state.currentOppId] || {}),
    shareReplay(1)
  );

  constructor(
    private expertsQueryService: ExpertsQueryService,
    private paymentsService: PaymentsService,
    private conferencesConferenceRemindersService: ConferencesConferenceRemindersService
  ) {}

  setOptions(opts: IQueryExpertSearchRequest): void {
    this.actions$.next(new SetCurrentOpp({ oppId: opts.opportunityId }));
    this.optionsInner$.next(opts);
  }

  getSearches(
    searchIds: string[],
    fromPhase: ConnectPhase = 'identified'
  ): void {
    this.searchRequestInner$.next({ searchIds, fromPhase });
  }

  upsertExpert(expert: Partial<IDisplayExpert>): void {
    this.actions$.next(new UpsertOne(expert));
  }

  upsertMany(experts: IDisplayExpert[]): void {
    this.actions$.next(new UpsertMany({ experts, totals: [] }));
  }

  resetOpportunity(opportunityId: string): void {
    this.actions$.next(new ResetOpportunity({ opportunityId }));
  }

  cancelDownloads(): void {
    this.cancelDownload$.next();
  }

  private getEngagements(
    experts: IDisplayExpert[]
  ): Observable<Record<string, IEngagement[]>> {
    return from(
      experts
        .filter(
          (e) =>
            connectPhaseList.findIndex((phase) => e.connectPhase === phase) >= 6
        )
        .map((e) => e.expertId)
    ).pipe(
      mergeMap((expertId) => this.paymentsService.query({ expertId })),
      reduce<IEngagement[], Record<string, IEngagement[]>>(
        (acc, engagements) =>
          Object.assign(
            acc,
            ...engagements.map((e) => ({
              [e.expertId]: [...(acc[e.expertId] || []), e],
            }))
          ),
        {}
      )
    );
  }

  private getCallActions(
    experts: IExpert[]
  ): Observable<Record<string, IDisplayExpertCallActions>> {
    return from([...new Set(experts.map((e) => e.opportunityId))]).pipe(
      switchMap((oppId) =>
        this.conferencesConferenceRemindersService.getDisplayExpertCallActions(
          oppId
        )
      ),
      reduce((acc, callActions) => Object.assign(acc, callActions), {})
    );
  }

  private scanState(): OperatorFunction<ActionTypes, IState> {
    const initialState: IState = {
      experts: {},
      ids: [],
      totals: {},
      currentOppId: '',
    };

    return (source): Observable<IState> =>
      source.pipe(
        scan<ActionTypes, IState>(this.updateState.bind(this), initialState),
        startWith(initialState),
        shareReplay(1)
      );
  }

  private updateState(state: IState, action: ActionTypes): IState {
    switch (action.type) {
      case Actions.UpsertMany: {
        const payloadTotals = action.payload.totals.reduce<
          Record<string, Record<ConnectPhase, number>>
        >(
          (prev, curr) => ({ ...prev, [curr.searchId]: curr.totals }),
          state.totals[state.currentOppId]
        );

        const payloadExperts = action.payload.experts;
        const ids = [
          ...new Set([
            ...state.ids,
            ...payloadExperts.flatMap((e) => e.expertId),
          ]),
        ];
        const experts = Object.assign(
          state.experts,
          ...payloadExperts.map((e) => ({
            [e.expertId]: Object.assign(state.experts?.[e.expertId] || {}, e),
          }))
        );
        return {
          ...state,
          ids,
          experts,
          totals: { ...state.totals, [state.currentOppId]: payloadTotals },
        };
      }
      case Actions.UpsertOne: {
        const expert = action.payload;
        const ids = [...new Set([...state.ids, expert.expertId])];
        const experts = Object.assign(state.experts, {
          [expert.expertId]: Object.assign(
            state.experts?.[expert.expertId] || {},
            expert
          ),
        });
        return { ...state, ids, experts };
      }
      case Actions.ResetOpportunity: {
        const experts = state.ids.reduce<IState['experts']>(
          (prev, curr) =>
            state.experts[curr].opportunityId !== action.payload.opportunityId
              ? Object.assign(prev, {
                  [curr]: state.experts[curr],
                })
              : prev,
          {}
        );
        return {
          ...state,
          ids: Object.keys(experts),
          experts,
          totals: { ...state.totals, [action.payload.opportunityId]: {} },
        };
      }
      case Actions.SetCurrentOpp: {
        return { ...state, currentOppId: action.payload.oppId };
      }
      default:
        return state;
    }
  }

  private sortExperts(b: IDisplayExpert, a: IDisplayExpert): number {
    return a.connectPhase === 'scheduled' && a.callTime
      ? a.callTime.localeCompare(b.callTime)
      : -(
          a.firstName.localeCompare(b.firstName) ||
          a.lastName.localeCompare(b.lastName)
        );
  }
}
