import {
  ExpertActionStatusMap,
  IExpertAction,
} from '../models/expertActions.models';
import { IExpertAvailabilitiesAction } from '../models/expertAvailabilityActions.models';
import { IExpertRespondentAction } from '../models/expertRespondentActions.models';

export const baseExpertActionMock: IExpertAction = {
  expertActionId: 'expertActionId',
  clientId: 'clientId',
  opportunityId: 'opportunityId',
  expertId: 'expertId',
  ownerUserId: 'connectId',
  actionType: 'call',
  status: ExpertActionStatusMap.Pending,
  dateCreated: 0,
  lastUpdated: 0,
};

export const baseExpertRespondentActionMock: IExpertRespondentAction = {
  ...baseExpertActionMock,
  actionType: 'respondent',
  responses: [
    {
      body: 'A Body',
      dateCreated: 1234,
      categories: ['assumed_positive', 'other_tag'],
    },
  ],
  referenceId: 'referenceId1',
  responseType: 'outreachEmail',
};

export const baseExpertAvailabilitiesActionMock: IExpertAvailabilitiesAction = {
  ...baseExpertActionMock,
  actionType: 'availabilities',
  clientId: 'clientId1',
  expertActionId: 'expertActionId1',
  opportunityId: 'opportunityId1',
  ownerUserId: 'ownerUserId1',
  status: ExpertActionStatusMap.Pending,
  selectedSlots: [],
  slotDuration: 3600,
  slotRequestType: 'expert',
  timeSlots: [
    {
      start: '2023-02-03T12:00:00-10:00',
      end: '2023-02-03T13:00:00-10:00',
    },
  ],
  dateCreated: 0,
  lastUpdated: 0,
};
