import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-delete-confirmation-dialog',
  template: `<div
    class="ct-modalContainer"
    data-e2e="file-delete-dialog"
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <h3>
      You are about to delete {{ fileName | filenameFormat }}. Are you sure?
    </h3>
    <div
      mat-dialog-actions
      fxLayout="row"
      fxLayoutGap="8px"
      fxLayoutAlign="end center"
    >
      <button
        (click)="revoke()"
        color="warn"
        data-e2e="file-cancel-delete"
        mat-flat-button
      >
        No
      </button>
      <button
        (click)="save()"
        color="primary"
        data-e2e="file-confirm-delete"
        mat-flat-button
      >
        Yes
      </button>
    </div>
  </div>`,
})
export class FileDeleteConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public fileName: string,
    private dialogRef: MatDialogRef<FileDeleteConfirmationDialogComponent>
  ) {}

  save(): void {
    this.dialogRef.close(true);
  }

  revoke(): void {
    this.dialogRef.close(false);
  }
}
