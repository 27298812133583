import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CognitoAuthService, WINDOW } from '@techspert-io/auth';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  scan,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { INotification } from '../models/notification.models';

@Injectable()
export class NotificationService {
  private readonly baseUrl = '/notification';
  private notificationsInner$ = new BehaviorSubject<INotification[]>([]);
  private archivedNotificationInner$ = new Subject<string>();
  private archivedNotifications$ = this.archivedNotificationInner$.pipe(
    scan<string, string[]>((acc, curr) => [...acc, curr], []),
    startWith<string[]>([])
  );

  notifications$ = combineLatest([
    this.notificationsInner$,
    this.archivedNotifications$,
  ]).pipe(
    map(([notifs, archived]) =>
      notifs
        .map((n) => ({
          ...n,
          archived: n.archived || archived.includes(n.notificationId),
        }))
        .sort((a, b) => b.dateCreated - a.dateCreated)
    )
  );

  constructor(
    private http: HttpClient,
    private auth: CognitoAuthService,
    @Inject(WINDOW) private window: Window
  ) {}

  initPoll(): Observable<INotification[]> {
    return combineLatest([
      this.trackVisibility(),
      this.auth.loggedIn$.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap((res) =>
        res.every(Boolean)
          ? this.pollNotifications(this.auth.loggedInUser.connectId)
          : of([])
      ),
      takeUntil(fromEvent(this.window, 'beforeunload'))
    );
  }

  archiveNotification(notificationId: string): Observable<INotification> {
    return this.http
      .get<INotification>(
        `${this.baseUrl}/archive/notificationId/${notificationId}`
      )
      .pipe(tap((n) => this.archivedNotificationInner$.next(n.notificationId)));
  }

  clearNotifications(): Observable<INotification[]> {
    return this.http
      .patch<INotification[]>(
        `${this.baseUrl}/archive/connectId/${this.auth.loggedInUser.connectId}`,
        null
      )
      .pipe(
        tap((archivedNotifs) =>
          archivedNotifs.map((notif) =>
            this.archivedNotificationInner$.next(notif.notificationId)
          )
        )
      );
  }

  private trackVisibility(): Observable<boolean> {
    return fromEvent(this.window.document, 'visibilitychange').pipe(
      map(() => this.window.document.visibilityState === 'visible'),
      startWith(true)
    );
  }

  private pollNotifications(connectId: string): Observable<INotification[]> {
    return timer(0, 1000 * 60 * 15).pipe(
      mergeMap(() =>
        this.getNotifications(connectId).pipe(
          tap((n) => this.notificationsInner$.next(n))
        )
      )
    );
  }

  private getNotifications(connectId: string): Observable<INotification[]> {
    return this.http.get<INotification[]>(
      `${this.baseUrl}/getForUser/${connectId}`
    );
  }
}
