export class ToastNotification {
  content: string;
  style: string;
  dismissed: boolean = false;
  countdown: number = 100;
  interval: NodeJS.Timer;

  constructor(content: string, style?: string) {
    this.content = content;
    this.style = style || 'error';
    this.interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown = this.countdown - 1;
      } else {
        this.dismissNotification();
      }
    }, 50);
  }

  dismissNotification(): void {
    this.dismissed = true;
    clearInterval(this.interval);
  }
}
