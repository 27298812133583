import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOpportunityOptions } from '../../../models/opportunity.models';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
})
export class SettingsFormComponent {
  @Input() opportunity: IOpportunityOptions;
  @Output() opportunityChange = new EventEmitter<IOpportunityOptions>();

  onChange(value: boolean, field: string): void {
    switch (field) {
      case 'conferenceReminderDefaultSettings':
        this.opportunity[field] = {
          enabled: value,
          sendEmailBeforeSeconds: [3600, 86400],
        };
        break;
      default:
        this.opportunity[field] = value;
        break;
    }
    this.opportunityChange.emit(this.opportunity);
  }
}
