export type FileType =
  | 'screenerResponse'
  | 'zoomRecording'
  | 'zoomTranscript'
  | 'manual';

export interface IFileBase {
  fileId: string;
  fileName: string;
  fileExtension: string;
  fileKey: string;
  type: FileType;
  deleted: boolean;
}

export interface IFileDataAccessLogs {
  lastUpdated: number;
  dateCreated: number;
  createdBy: string;
  lastUpdatedBy: string;
}

export interface IExpertFileBase
  extends Omit<IFileBase, keyof IFileDataAccessLogs> {
  expertId: string;
  pendingApproval: boolean;
}

export type ExpertFile = IExpertFileBase & IFileDataAccessLogs;
