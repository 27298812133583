import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IExpertConferenceEdit } from '@techspert-io/conferences';
import { IEngagement } from '@techspert-io/engagements';
import { IDisplayExpert } from '@techspert-io/experts';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss'],
})
export class ExpertComponent {
  @Input() expert: IDisplayExpert;
  @Input() index: number;
  @Input() openFilter: boolean;
  @Input() showAffiliations: boolean;
  @Output() selectExpertSignal = new EventEmitter();
  @Output() editExpertSignal = new EventEmitter();
  @Output() toggleBlockExpertSignal = new EventEmitter();
  @Output() toggleAdminRejectStatusSignal = new EventEmitter();
  @Output() callActionSignal = new EventEmitter();
  @Output() navigateToExpertSignal = new EventEmitter();
  @Output() addExpertiseToSearchSignal = new EventEmitter();
  @Output() showExpertScreenerSignal = new EventEmitter();
  @Output() scheduleSignal = new EventEmitter();
  @ViewChild('FullEmailList')
  public fullEmailList: ElementRef;
  public showActions: boolean = false;

  public getEngagementStatusColor(engagement: IEngagement): string {
    if (!engagement.paymentActive) {
      return 'darkgrey';
    }
    if (engagement.paymentType === 'thirdParty') {
      return 'olive';
    }
    if (engagement.paymentStatus === 'not-paid') {
      return 'dodgerblue';
    }
    if (engagement.paymentStatus === 'payment-sent') {
      return 'darkorange';
    }
    if (engagement.paymentStatus === 'payment-received') {
      return 'mediumseagreen';
    }
    if (engagement.paymentStatus === 'not-claimed') {
      return 'indianred';
    }
  }

  public emitScheduleSingal(): void {
    this.scheduleSignal.emit(this.expert);
  }

  public emitSelectExpertSignal(expert: any): void {
    this.selectExpertSignal.emit(expert);
  }

  public emitToggleBlockExpertSignal(event: any): void {
    this.toggleBlockExpertSignal.emit(event);
  }

  public emitToggleAdminRejectStatusSignal(): void {
    this.showActions = false;
    this.toggleAdminRejectStatusSignal.emit(this.expert);
  }

  public emitEditExpertSignal(): void {
    this.editExpertSignal.emit(this.expert);
  }

  public emitNavigateToExpertSignal(expert: any): void {
    this.navigateToExpertSignal.emit(expert);
  }

  public toggleShowActions(event: any): void {
    event.stopPropagation();
    this.showActions = !this.showActions;
  }

  public closeActions(): void {
    this.showActions = false;
  }

  public emitRescheduleConferenceSignal(data: IExpertConferenceEdit): void {
    this.callActionSignal.emit(data);
  }

  public emitAddExpertiseToSearchSignal(expertise: string): void {
    this.addExpertiseToSearchSignal.emit(expertise);
  }

  public emitShowScreenerResults(): void {
    this.showExpertScreenerSignal.emit(true);
  }
}
