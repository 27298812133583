import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService, IClient } from '@techspert-io/clients';
import { EMPTY, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IUserCreateRequest } from '../../models/user.models';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
})
export class UserInviteComponent implements OnInit {
  clients$: Observable<IClient[]> = EMPTY;
  isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private clients: ClientsService
  ) {}

  ngOnInit(): void {
    this.clients$ = this.clients.getAll();
  }

  inviteUser(payload: IUserCreateRequest): void {
    this.isLoading = true;

    this.userService
      .create(payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => this.router.navigateByUrl('/admin/users'));
  }
}
