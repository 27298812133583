import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOpportunity } from '@techspert-io/opportunities';
import { ITimezone } from '../../../../shared/models/country';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
})
export class OpportunityDetailsComponent implements OnInit {
  @Input() opportunity: IOpportunity;
  @Output() generateNewDisplayIdSignal = new EventEmitter();
  primaryClientContactTimezone: ITimezone;

  ngOnInit(): void {
    this.primaryClientContactTimezone = this.opportunity.clientContacts.find(
      (contact) => contact.primary
    )?.timezone;
  }

  emitGenerateNewDisplayId(): void {
    this.generateNewDisplayIdSignal.emit();
  }
}
