import { Injectable } from '@angular/core';
import { CountryService } from '../../../shared/services/country.service';
import {
  IOpportunity,
  IOpportunityClientContact,
} from '../models/opportunity.models';

interface IValidationResult {
  isValid: boolean;
  invalidProperties: Record<string, boolean>;
}
@Injectable({
  providedIn: 'root',
})
export class OpportunityValidationService {
  constructor(private countryService: CountryService) {}

  validate(opp: IOpportunity): IValidationResult {
    const validationResult = [
      this.isClientContactsValid,
      this.isSegmentConfigsValid,
      this.isPodMembersValid,
      this.isDescriptionValid,
    ]
      .map((fn) => fn.bind(this))
      .reduce((prev, curr) => ({ ...prev, ...curr(opp) }), {});

    return {
      isValid: Object.values(validationResult).every((valid) => valid),
      invalidProperties: validationResult,
    };
  }

  private isDescriptionValid({ notes }: IOpportunity): Record<string, boolean> {
    return { notes: !!notes };
  }

  private isClientContactsValid({
    clientContacts,
  }: IOpportunity): Record<string, boolean> {
    if (!clientContacts) {
      return { clientContacts: true };
    }

    return {
      clientContacts:
        !this.hasDuplicates(clientContacts) &&
        clientContacts.every((c) => this.isClientContactValid(c)),
    };
  }

  private isClientContactValid(
    clientContact: IOpportunityClientContact
  ): boolean {
    const isValidCountry = this.countryService.countryList.find(
      (c) => c.abbreviation === clientContact.country
    );
    if (!isValidCountry) return false;

    return true;
  }

  private isSegmentConfigsValid({
    segments,
    segmentConfig,
  }: IOpportunity): Record<string, boolean> {
    const segmentKeys = Object.keys(segments);

    return {
      segmentConfig: segmentKeys.every(
        (key) => segmentConfig[key] && segmentConfig[key].owner[0]
      ),
    };
  }

  private isPodMembersValid({
    opportunityAssignees,
  }: IOpportunity): Record<string, boolean> {
    if (!opportunityAssignees) {
      return { opportunityAssignees: true };
    }

    return {
      opportunityAssignees: !this.hasDuplicates(opportunityAssignees),
    };
  }

  private hasDuplicates<T>(items: T[]): boolean {
    return (items || []).some(
      (item, i, arr) =>
        arr.filter((a) => JSON.stringify(a) === JSON.stringify(item)).length > 1
    );
  }
}
