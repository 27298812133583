import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import {
  connectPhaseList,
  expertSegmentList,
  expertSourceList,
  IExpert,
} from '@techspert-io/experts';
import {
  IOpportunity,
  IOpportunitySegment,
  OpportunitySegmentsService,
} from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';
import { combineLatest } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';
import { ITimezoneWithCountry } from '../../../../../../shared/models/country';
import { AppService } from '../../../../../../shared/services/app.service';
import { CurrencyService } from '../../../../../../shared/services/currency.service';

interface ISelect<V = string, D = string> {
  value: V;
  display: D;
}

@Component({
  selector: 'app-expert-profile-details',
  templateUrl: './expert-profile-details.component.html',
  styleUrls: ['./expert-profile-details.component.scss'],
})
export class ExpertProfileDetailsComponent implements OnInit {
  @Input() expert: IExpert;
  @Input() opportunity: IOpportunity;
  @Output() isPocEmailValidSignal = new EventEmitter<boolean>();

  public expertSources = expertSourceList;
  public expertSegments = expertSegmentList;
  opportunitySegmentTargetsDict: Record<string, string[]> = {};
  opportunitySegmentGeographies: string[] = [];
  costBandOptions: IExpert['costBand'][] = [
    'Discounted',
    'Standard',
    'Premium',
    'Premium+',
  ];

  public owners: ISelect<ConnectUser>[] = [];
  public owner: ConnectUser;

  public expertProfileDetailsForm = new FormGroup({
    currency: new FormControl(
      null,
      this.currencyService.validateCurrency.bind(this.currencyService)
    ),
    email: new FormControl(null),
    costBand: new FormControl(null, Validators.required),
    unitsPerHour: new FormControl(null, [
      Validators.required,
      Validators.min(0.01),
      this.unitsPerHourValidator(),
    ]),
    geographicTarget: new FormControl(null),
    profileType: new FormControl(null),
  });

  public emailValidator = [Validators.required, Validators.email];

  get currencyControl(): FormControl {
    return this.expertProfileDetailsForm.get('currency') as FormControl;
  }

  get emailControl(): FormControl {
    return this.expertProfileDetailsForm.get('email') as FormControl;
  }

  get unitsPerHourControl(): FormControl {
    return this.expertProfileDetailsForm.get('unitsPerHour') as FormControl;
  }

  get costBandControl(): FormControl {
    return this.expertProfileDetailsForm.get('costBand') as FormControl;
  }

  get geographicTargetControl(): FormControl {
    return this.expertProfileDetailsForm.get('geographicTarget') as FormControl;
  }

  get profileTypeControl(): FormControl {
    return this.expertProfileDetailsForm.get('profileType') as FormControl;
  }

  constructor(
    public appService: AppService,
    public usersService: UserService,
    public cognitoAuthService: CognitoAuthService,
    public dialog: MatDialog,
    public opportunitySegmentsService: OpportunitySegmentsService,
    public currencyService: CurrencyService
  ) {}

  ngOnInit(): void {
    this.setupExpertOpportunitySegmentForm();
    this.createOwnerList();
    this.setupCurrencyForm();
    this.setupEmailForm();
    this.setupUnitsPerHourForm();
    this.setupSegmentInputsForm();
  }

  private setupExpertOpportunitySegmentForm(): void {
    this.geographicTargetControl.valueChanges.subscribe((value) => {
      this.expert.geographicTarget = value;
      this.expert.opportunitySegmentId =
        this.opportunitySegmentsService.getOpportunitySegmentId(
          this.opportunity.segments || {},
          this.expert
        );
    });
    this.profileTypeControl.valueChanges.subscribe((value) => {
      this.expert.profileType = value;
      this.expert.opportunitySegmentId =
        this.opportunitySegmentsService.getOpportunitySegmentId(
          this.opportunity.segments || {},
          this.expert
        );
    });

    this.opportunitySegmentTargetsDict =
      this.opportunitySegmentsService.getOpportunityTargetsByGeographyDict(
        this.opportunity.segments
      );
    this.opportunitySegmentGeographies = Object.keys(
      this.opportunitySegmentTargetsDict
    );

    const segment = this.expert.opportunitySegmentId
      ? this.opportunity.segments[this.expert.opportunitySegmentId]
      : undefined;

    this.geographicTargetControl.setValue(segment?.geography);
    this.profileTypeControl.setValue(segment?.segment);
  }

  private createOwnerList(): void {
    const sortByDisplay = (
      a: ISelect<ConnectUser>,
      b: ISelect<ConnectUser>
    ): number => {
      if (a.display < b.display) {
        return -1;
      }
      if (a.display > b.display) {
        return 1;
      }
      return 0;
    };

    this.usersService.getAll({ userTypes: ['PM'] }).subscribe((users) => {
      this.owners = [
        {
          value: this.cognitoAuthService.loggedInUser,
          display:
            this.cognitoAuthService.loggedInUser.firstName +
            ' ' +
            this.cognitoAuthService.loggedInUser.lastName,
        },
        ...users
          .map((u) => ({
            value: u,
            display: u.firstName + ' ' + u.lastName,
          }))
          .filter(
            (u) =>
              u.value.connectId !==
              this.cognitoAuthService.loggedInUser.connectId
          )
          .sort(sortByDisplay),
      ];

      this.owner = (
        this.owners.find(
          (o) => o.value.connectId === this.expert.ownerConnectId
        ) || {}
      ).value;
    });
  }

  public updateTimezoneAndCountry(
    timezoneAndCountry: ITimezoneWithCountry
  ): void {
    this.expert.timezoneName = timezoneAndCountry.timezone;
    this.expert.country = timezoneAndCountry.country;
  }

  public addEmail(address: string): void {
    this.expert.opportunityEmails = [
      ...this.expert.opportunityEmails,
      address.toLowerCase().trim(),
    ];
  }

  public removeEmail(emailForRemoval: string): void {
    this.expert.opportunityEmails = this.expert.opportunityEmails.filter(
      (e) => e !== emailForRemoval
    );
  }

  public addProperty(key: string, item: unknown): void {
    this.expert[key] = [...(this.expert[key] || []), item];
  }

  public removeProperty(key: string, removalItem: unknown): void {
    this.expert[key] = this.expert[key].filter((item) => item !== removalItem);
  }

  public onOwnerChange(user: ConnectUser): void {
    this.expert.ownerConnectId = user.connectId;
  }

  private setupCurrencyForm(): void {
    this.currencyControl.setValue(this.expert.currency);

    this.currencyControl.valueChanges.subscribe((currency) => {
      this.expert.currency = currency;
    });
  }

  private setupEmailForm(): void {
    if (this.expert.primaryEmail) {
      this.emailControl.setValue(this.expert.primaryEmail);
    }

    const targetPhaseIndex = connectPhaseList.indexOf(this.expert.connectPhase);
    if (targetPhaseIndex >= 4) {
      this.emailControl.setValidators([Validators.required, Validators.email]);
    } else {
      this.emailControl.setValidators([Validators.email]);
    }

    this.emailControl.valueChanges.subscribe((email) => {
      this.expert.primaryEmail = email;
      this.isPocEmailValidSignal.emit(this.emailControl.valid);
    });
  }

  private setupSegmentInputsForm(): void {
    combineLatest([
      this.geographicTargetControl.valueChanges.pipe(startWith('')),
      this.profileTypeControl.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        filter(() => this.expertProfileDetailsForm.dirty),
        filter<string[]>(() => this.costBandControl.value === 'Standard'),
        tap(([geographicTarget, profileType]) =>
          this.handleSegmentUpdates(geographicTarget, profileType)
        )
      )
      .subscribe();
  }

  private setupUnitsPerHourForm(): void {
    this.unitsPerHourControl.valueChanges.subscribe((unitsPerHour) => {
      const opportunitySegment = this.getExpertSegment();

      this.expert.unitsPerHour =
        unitsPerHour === opportunitySegment &&
        opportunitySegment.unitsPerEngagement
          ? 0
          : unitsPerHour;
    });

    this.costBandControl.valueChanges
      .pipe(tap((val) => this.handleCostBandUpdate(val)))
      .subscribe((costBand) => (this.expert.costBand = costBand));

    this.unitsPerHourControl.setValue(this.expert.unitsPerHour || 0);
    this.costBandControl.setValue(this.expert.costBand || 'Standard');
  }

  private handleCostBandUpdate(costBand: IExpert['costBand']): void {
    const opportunitySegment = this.getExpertSegment();

    if (costBand === 'Standard') {
      if (opportunitySegment) {
        this.unitsPerHourControl.setValue(
          opportunitySegment.unitsPerEngagement
        );
      }
      this.unitsPerHourControl.disable();
      this.expert.unitsPerHour = 0;
    } else {
      this.unitsPerHourControl.enable();

      if (
        opportunitySegment &&
        opportunitySegment.unitsPerEngagement === this.unitsPerHourControl.value
      ) {
        this.unitsPerHourControl.setValue(0);
        this.unitsPerHourControl.markAsTouched();
      }
    }
  }

  private handleSegmentUpdates(
    geographicTarget: string,
    profileType: string
  ): void {
    const opportunitySegment = this.getExpertSegment(
      geographicTarget,
      profileType
    );

    if (opportunitySegment) {
      this.unitsPerHourControl.setValue(opportunitySegment.unitsPerEngagement);
    } else {
      this.unitsPerHourControl.setValue(0);
    }
  }

  private unitsPerHourValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = `${control.value}`;
      const opportunitySegment = this.getExpertSegment();

      if (
        opportunitySegment &&
        opportunitySegment.unitsPerEngagement === +value &&
        this.costBandControl.value !== 'Standard'
      ) {
        return {
          matchingDefaultRate: 'Rate cannot be same as segment default',
        };
      }

      const [, post] = value.split('.');

      return post?.length > 2
        ? { twoDpValidator: 'Must be fewer than 3dp' }
        : null;
    };
  }

  private getExpertSegment(
    geographicTarget?: string,
    profileType?: string
  ): IOpportunitySegment {
    if (!this.opportunity) {
      return;
    }

    const segmentId = this.opportunitySegmentsService.getOpportunitySegmentId(
      this.opportunity.segments,
      {
        geographicTarget:
          geographicTarget || this.geographicTargetControl.value,
        profileType: profileType || this.profileTypeControl.value,
      }
    );

    return this.opportunity.segments[segmentId];
  }
}
