import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClientsService } from '@techspert-io/clients';
import {
  ExpertCallActionService,
  IExpertCallAction,
} from '@techspert-io/conferences';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import { ExpertsQueryService, IExpert } from '@techspert-io/experts';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import * as Moment from 'moment';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { IExpertProfileDialogInput } from '../../../admin-portal/clients/client-opportunity-searches/opportunity-results/expert-profile-dialog/expert-profile-dialog-models';
import { ExpertProfileDialogComponent } from '../../../admin-portal/clients/client-opportunity-searches/opportunity-results/expert-profile-dialog/expert-profile-dialog.component';
import { ScheduleComponent } from '../../../shared/components/schedule/schedule.component';
import { Client } from '../../../shared/models/client';
import { MessageStatusMap } from '../../../shared/models/email';
import {
  ActionEmailType,
  ConferenceService,
} from '../../../shared/services/conference.service';
import {
  IScheduleDialogClosePayload,
  IScheduleDialogInput,
  scheduleDialogSize,
} from '../schedule/schedule-models';
import { UpdateEmergencyContactComponent } from '../update-emergency-contact/update-emergency-contact.component';

export interface IViewConnectionInput {
  callAction: IExpertCallAction;
  isActionsDisabled?: boolean;
}
export interface IViewConnectionDialogClosePayload {
  callAction: IExpertCallAction;
}

export const viewConnectionDialogSize = {
  width: '600px',
  height: '800px',
};

interface ITableItem {
  date: number;
  status: MessageStatusMap;
  statusLabel: string;
  statusIcon: string;
  statusClass: string;
  recipient: string;
  emailType: ActionEmailType;
}

@Component({
  selector: 'app-view-connection',
  templateUrl: './view-connection.component.html',
  styleUrls: ['./view-connection.component.scss'],
})
export class ViewConnectionComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  moment = Moment;
  expert: IExpert;
  client: Client;
  opportunity: IOpportunity;
  showCompleteStatuses = [
    ExpertActionStatusMap.Request,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showRescheduleStatuses = [
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Complete,
  ];
  showCancelStatuses = [
    ExpertActionStatusMap.Complete,
    ExpertActionStatusMap.Pending,
    ExpertActionStatusMap.Request,
  ];

  dataSource = new MatTableDataSource<ITableItem>([]);
  isLoading = true;
  displayedColumns = ['date', 'recipient', 'emailType', 'statusLabel'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IViewConnectionInput,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ViewConnectionComponent>,
    private conferenceService: ConferenceService,
    private opportunitiesService: OpportunitiesService,
    private expertCallActionService: ExpertCallActionService,
    private toastService: ToastService,
    private clientsService: ClientsService,
    private expertQueryService: ExpertsQueryService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.expertQueryService.getById(this.data.callAction.expertId),
      this.clientsService.get(this.data.callAction.clientId),
      this.opportunitiesService.get(this.data.callAction.opportunityId),
      this.getTableData(),
    ])
      .pipe(
        tap(([expertData, clientData, opportunity, tableData]) => {
          this.expert = expertData;
          this.client = clientData;
          this.opportunity = opportunity;

          this.setTableData(tableData);
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  public updateConference(action: IScheduleDialogInput['action']): void {
    const data: IScheduleDialogInput = {
      expert: this.expert,
      opportunity: this.opportunity,
      client: this.client,
      callAction: this.data.callAction,
      action,
    };

    this.dialog
      .open<
        ScheduleComponent,
        IScheduleDialogInput,
        IScheduleDialogClosePayload
      >(ScheduleComponent, {
        ...scheduleDialogSize,
        data: data,
      })
      .afterClosed()
      .pipe(
        switchMap((payload: IScheduleDialogClosePayload | undefined) => {
          if (payload?.engagement) {
            this.openExpertEngagements(
              payload.expert,
              payload.callAction,
              payload.engagement.engagementId
            );
          } else {
            this.dialogRef.close({
              callAction: payload.callAction,
            });
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  openUpdateEmergencyContact(): void {
    this.dialog
      .open<UpdateEmergencyContactComponent, string>(
        UpdateEmergencyContactComponent,
        {
          data: this.data.callAction.emergencyContact,
          width: '450px',
          height: '260px',
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((contact) => !!contact),
        switchMap((contact) =>
          this.expertCallActionService.patch({
            expertActionId: this.data.callAction.expertActionId,
            emergencyContact: contact,
          })
        ),
        tap((callAction) => (this.data.callAction = callAction)),
        tap(() =>
          this.toastService.sendMessage('Updated emergency contact', 'success')
        ),
        catchError(() => {
          this.toastService.sendMessage(
            'Error occurred when updating emergency contact',
            'error'
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }

  private openExpertEngagements(
    expertForEdit: IExpert,
    callAction: IExpertCallAction,
    focusEngagementId?: string
  ): void {
    const data: IExpertProfileDialogInput = {
      expert: JSON.parse(JSON.stringify(expertForEdit)),
      opportunity: this.opportunity,
      focus: {
        displayTab: focusEngagementId ? 'billing' : 'availability',
        openEngagmentId: focusEngagementId,
      },
    };

    this.dialog
      .open(ExpertProfileDialogComponent, {
        width: '1150px',
        height: '80%',
        data: data,
      })
      .afterClosed()
      .pipe(
        tap(() =>
          this.dialogRef.close({
            callAction: callAction,
          })
        )
      )
      .subscribe();
  }

  private getTableData(): Observable<ITableItem[]> {
    const unsubscribed = {
      statusLabel: 'Recipient unsubscribed',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const domainBlocked = {
      statusLabel: 'Recipient domain blocked',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const messageBlocked = {
      statusLabel: 'Blocked',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const permissionRejected = {
      statusLabel: 'Recipient failed validation checks',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const sendError = {
      statusLabel: 'Send error',
      statusIcon: 'block',
      statusClass: 'fail',
    };
    const sendPending = {
      statusLabel: 'Sending',
      statusIcon: 'refresh',
      statusClass: 'pending',
    };
    const messageDelivered = {
      statusLabel: 'Delivered',
      statusIcon: 'done',
      statusClass: 'success',
    };
    const statusUnknown = {
      statusLabel: 'Unknown',
      statusIcon: 'question_mark',
      statusClass: 'unknown',
    };

    const statusToDisplay: Record<
      MessageStatusMap | 'unknown',
      { statusLabel: string; statusIcon: string; statusClass: string }
    > = {
      [MessageStatusMap.connectUnsubscribed]: unsubscribed,
      [MessageStatusMap.connectBlockedDomain]: domainBlocked,
      [MessageStatusMap.connectBounced]: messageBlocked,
      [MessageStatusMap.connectPermissionRejected]: permissionRejected,
      [MessageStatusMap.connectSendFailure]: sendError,
      [MessageStatusMap.connectDeferred]: sendPending,
      [MessageStatusMap.connectSendElapsed]: sendError,
      [MessageStatusMap.connectSent]: sendPending,
      [MessageStatusMap.providerProcessing]: sendPending,
      [MessageStatusMap.providerDropped]: messageBlocked,
      [MessageStatusMap.providerDeferred]: sendPending,
      [MessageStatusMap.providerBounce]: messageBlocked,
      [MessageStatusMap.providerDelivered]: messageDelivered,
      [MessageStatusMap.providerOpen]: messageDelivered,
      [MessageStatusMap.providerClick]: messageDelivered,
      [MessageStatusMap.providerSpam]: messageDelivered,
      [MessageStatusMap.providerUnsubscribe]: messageDelivered,
      [MessageStatusMap.providerResubscribe]: messageDelivered,
      [MessageStatusMap.userUnsubscribe]: messageDelivered,
      unknown: statusUnknown,
    };

    return this.conferenceService
      .getEmailsForCallActionId(this.data.callAction.expertActionId)
      .pipe(
        map((emails) =>
          emails.map((e) => ({
            emailType: e.actionMessage.type,
            date: e.actionMessage.dateCreated * 1000,
            status: e.message?.status,
            recipient: e.message?.recipient,
            ...statusToDisplay[e.message?.status || 'unknown'],
          }))
        ),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
  }

  private setTableData(tableData: ITableItem[]): void {
    if (tableData) {
      this.dataSource.data = tableData;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }
}
