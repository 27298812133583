import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ct-slide-toggle',
  template: `<div class="ct-slide-toggle" [matTooltip]="tooltip">
    <mat-slide-toggle
      class="nav-link-icon"
      name="callRecording"
      [(ngModel)]="value"
      (ngModelChange)="toggleChangeSignal.emit(value)"
      data-e2e="slide-toggle-button"
    >
    </mat-slide-toggle>
    <span class="ct-slide-toggle__label">{{ label }}</span>
  </div>`,
})
export class SlideToggleComponent {
  @Input() value: boolean;
  @Input() label: string;
  @Input() tooltip: string;
  @Output() toggleChangeSignal = new EventEmitter();
}
