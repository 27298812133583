import { IExpert } from '../../models/experts.models';

export const expertMockData: IExpert = {
  firstName: 'Guy',
  lastName: 'Pierreson',
  opportunityId: 'opp-id',
  searchId: 'search-id',
  source: 'deep-next',
  expertise: ['Hats', 'Scarves'],
  affiliations: ['?'],
  sites: [''],
  prev_bios: { a12wqeqe2: '<p>Hi!</p>}' },
  s_qualifications: ['Paper'],
  opportunityEmails: ['g@email.com', 'p@email.com'],
  country: 'FR',
  primaryEmail: 'guy.pierreson@email.com',
  currency: 'GBP',
  bio: "I'm an expert",
  prevOpportunities: [],
  callTime: '',
  connectPhase: 'outreach',
  reasonForRejection: 'NULL',
  timezoneName: 'UTC',
  conferences: ['conference-id'],
  blocked: false,
  expertId: 'expert-id',
  geographicTarget: 'US',
  ownerConnectId: 'e-o-id',
  profileType: 'nurse',
  recordingAuthorised: true,
  expertSegment: 'Clinical - KOL',
  tagline: 'tag',
  linkedInUrl: '',
  screenerResponse: '',
  clientNotes: '',
  portalAffiliations: [],
  seenByClient: false,
  unitsPerHour: 1.25,
  opportunitySegmentId: 'osId',
  portalExpertise: [],
  rejection: {},
};
