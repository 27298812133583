import {
  ExpertActionStatusMap,
  IDataAccessLogs,
  IExpertActionBase,
  IExpertActionQuery,
} from '@techspert-io/expert-actions';

export interface IExpertCallAction
  extends IExpertCallActionBase,
    IDataAccessLogs {}

export interface IExpertCallActionBase extends IExpertActionBase {
  actionType: 'call';
  status: ExpertActionStatusMap;
  callType: 'zoom-techspert' | 'other';
  datetime: string;
  /** expected duration of call in minutes */
  duration: number;
  internalName: string;
  externalName: string;
  blind: boolean;
  isRecordingOn: boolean;
  emergencyContact?: string;
  organizer?: {
    email: string;
    mailto: string;
    name: string;
  };
  joinMetadata?: {
    meetingId?: string;
    password?: string;
    weblink?: string;
    dialIns: IExpertCallActionDialIn[];
  };
  zoomMetadata?: {
    zoomId?: string;
    zoomAccount?: string;
  };
}

export interface IExpertCallActionDialIn {
  country: string;
  number: string;
  city?: string;
}

export interface IExpertCallActionQuery extends IExpertActionQuery {
  from?: string;
  to?: string;
  ownerUserId?: string[];
  status?: IExpertCallAction['status'][];
}

export enum ConferenceStage {
  Pending = 'pending',
  Complete = 'complete',
  Cancelled = 'cancelled',
}

export interface IConferenceQuery {
  from?: string;
  to?: string;
  ownerUserId?: string[];
  stage?: ConferenceStage[];
}

interface ICallActionBaseReq {
  callType: 'zoom-techspert' | 'other';
  datetime: string;
  duration: number;
  internalName: string;
  externalName: string;
  blind: boolean;
  isRecordingOn: boolean;
  emergencyContact?: string;
  dialInCountries?: string[];
  status?: ExpertActionStatusMap;
  organizer?: {
    email: string;
    mailto: string;
    name: string;
  };
}

export interface ICreateCallActionReq extends ICallActionBaseReq {
  clientId: string;
  opportunityId: string;
  expertId: string;
  ownerUserId: string;
  organizer: {
    email: string;
    mailto: string;
    name: string;
  };
}

export interface IUpdateCallActionReq extends ICallActionBaseReq {
  expertActionId: string;
}

export interface IPatchCallActionReq {
  expertActionId: string;
  emergencyContact: string;
}
