import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmationDialogData {
  message: string;
  positiveButtonText: string;
  negativeButtonText: string;
  isDangerous: boolean;
}

@Component({
  selector: 'ct-confirmation-dialog-component',
  template: `<div
    class="ct-modalContainer"
    fxLayout="column"
    fxLayoutAlign="space-between unset"
    fxLayoutGap="20px"
    style="height: 100%"
  >
    <div fxLayoutAlign="center center">
      <h3 data-e2e="confirmation-dialog-message">{{ data.message }}</h3>
    </div>
    <div fxLayout="row" fxLayoutGap="20px">
      <button
        mat-raised-button
        [color]="data.isDangerous ? 'warn' : 'primary'"
        (click)="close()"
        data-e2e="confirmation-dialog-confirm-button"
      >
        {{ data.positiveButtonText }}
      </button>
      <button
        mat-raised-button
        mat-dialog-close
        data-e2e="confirmation-dialog-cancel-button"
      >
        {{ data.negativeButtonText }}
      </button>
    </div>
  </div>`,
})
export class ConfirmationDialogComponent {
  data: Partial<IConfirmationDialogData> = {
    message: 'Are you sure ?',
    positiveButtonText: 'Yes',
    negativeButtonText: 'No',
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IConfirmationDialogData
  ) {
    this.data = {
      ...this.data,
      ...data,
    };
  }

  close(): void {
    this.dialogRef.close(true);
  }
}
