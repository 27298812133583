import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EngagementPaymentReasons,
  engagementPaymentTypeSelectList,
  EngagementsCalculationsService,
  EngagementsTitleService,
  IEngagement,
  paymentReasonsList,
} from '@techspert-io/engagements';
import { IExpert } from '@techspert-io/experts';
import { tap } from 'rxjs/operators';
import {
  EngagementPaymentProviders,
  EngagementPaymentTypes,
} from '../../../features/engagements/models/engagements.models';
import { ConfirmationDialogComponent } from '../../../shared/patterns/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-engagement-billing-form',
  templateUrl: './engagement-billing-form.component.html',
})
export class EngagementBillingFormComponent implements OnInit, OnChanges {
  @Input() engagement: IEngagement;
  @Input() expert: IExpert;
  @Input() opportunityName: string;
  @Input() showDelete = true;
  @Input() providers: EngagementPaymentProviders[] = [];
  @Output() engagementDeletionSignal = new EventEmitter();
  @Output() engagementEditAllowedSignal = new EventEmitter();
  // Form inputs
  acceptanceDate: string;
  dateOfEngagement: string;
  quantityEngaged: number;

  // Variable input field titles, if title blank field won't be displayed
  dateOfEngagementTitle: string;
  rateTitle: string;
  quantityEngagedTitle: string;
  netUnitsUsedTitle: string;
  unitsUsedAdjustmentTitle: string;
  reasonTitle: string = '';
  legacyPaymentReason: string;

  paymentReasons: EngagementPaymentReasons[] = paymentReasonsList;
  paymentTypes = engagementPaymentTypeSelectList;

  engagementTypes: {
    value: IEngagement['engagementType'];
    display: string;
  }[] = [
    { value: 'call', display: 'Call' },
    { value: 'data-review', display: 'Data Review' },
    { value: 'survey', display: 'Survey' },
    { value: 'payment', display: 'Payment' },
  ];

  isEnagagementEditable = true;
  isEngagementPastNotPaid = false;
  minDate: string;

  constructor(
    public engagementsCalculationsService: EngagementsCalculationsService,
    private engagementsTitleService: EngagementsTitleService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const now = new Date();

    this.minDate = new Date(
      now.getFullYear(),
      now.getDate() > 1 ? now.getMonth() : now.getMonth() - 1,
      1
    )
      .toISOString()
      .split('T')[0];

    if (this.engagement.lastAccepted) {
      this.acceptanceDate = new Date(this.engagement.lastAccepted)
        .toISOString()
        .split('T')[0];
    }
    if (this.engagement.dateOfEngagement) {
      this.dateOfEngagement = new Date(this.engagement.dateOfEngagement)
        .toISOString()
        .split('T')[0];

      this.isEnagagementEditable = this.isEnagementEditable(
        this.engagement.dateOfEngagement
      );
      this.engagementEditAllowedSignal.emit(this.isEnagagementEditable);
    }

    this.isEngagementPastNotPaid =
      this.engagement.paymentStatus === 'payment-sent' ||
      this.engagement.paymentStatus === 'payment-received' ||
      this.engagement.paymentStatus === 'not-claimed';

    this.setupTitles();
    this.setupQuantityEngaged();

    if (
      this.engagement.reason &&
      !paymentReasonsList.includes(this.engagement.reason)
    ) {
      this.legacyPaymentReason = this.engagement.reason;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.engagement &&
      changes.engagement.currentValue !== changes.engagement.previousValue
    ) {
      this.ngOnInit();
    }
  }

  public onEngagementTypeChange(): void {
    this.engagement.engagementTitle =
      this.engagementsTitleService.createEngagementTitle(
        this.engagement.engagementType,
        this.opportunityName,
        this.expert
      );
    this.setupTitles();
    this.setupQuantityEngaged();
    if (this.engagement.engagementType === 'payment') {
      this.engagement.unitsUsed = 0;
    } else {
      this.onQuantityChange();
    }
  }

  public onDateOfEngagementChange(dateString: string): void {
    if (dateString) {
      this.engagement.dateOfEngagement = new Date(dateString).toISOString();

      this.isEnagagementEditable = this.isEnagementEditable(
        this.engagement.dateOfEngagement
      );
      this.engagementEditAllowedSignal.emit(this.isEnagagementEditable);
    }
  }

  public onAcceptanceDateChange(dateString: string): void {
    if (dateString) {
      this.engagement.lastAccepted = new Date(dateString).toISOString();
    }
  }

  public onPaymentTypeChange(paymentType: EngagementPaymentTypes): void {
    if (paymentType === 'thirdParty') {
      this.engagement.paymentProvider = undefined;
    } else {
      this.engagement.paymentProvider = 'Techspert';
    }
  }

  public onQuantityChange(): void {
    this.engagement.quantityEngaged =
      this.engagement.engagementType === 'call'
        ? this.quantityEngaged / 60
        : this.quantityEngaged;

    const amountOwed = this.engagementsCalculationsService.calculateOwed(
      this.engagement
    );
    if (amountOwed || amountOwed === 0) {
      this.engagement.amountOwed = amountOwed;
    }
    const unitsUsed = this.engagementsCalculationsService.calculateUnitsUsed(
      this.engagement
    );
    if (unitsUsed || unitsUsed === 0) {
      this.engagement.unitsUsed = unitsUsed;
    }
  }

  public emitEngagementDeletion(): void {
    this.engagementDeletionSignal.emit(this.engagement.engagementId);
  }

  public changePaymentActive(event: { checked: boolean }): void {
    if (event.checked && this.engagement.unitsUsed > 3) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          width: '400px',
          height: '225px',
          data: {
            message: 'Units used are higher than expected, is this correct?',
            positiveButtonText: 'Yes',
            negativeButtonText: 'No',
            isDangerous: true,
          },
        })
        .afterClosed()
        .pipe(tap((res) => (this.engagement.paymentActive = !!res)))
        .subscribe();
    } else if (event.checked && this.engagement.unitsUsed <= 3) {
      this.engagement.paymentActive = true;
    } else {
      this.engagement.paymentActive = false;
    }
  }

  private setupQuantityEngaged(): void {
    this.quantityEngaged =
      this.engagement.engagementType === 'call'
        ? this.engagement.quantityEngaged * 60
        : this.engagement.quantityEngaged;
  }

  private setupTitles(): void {
    switch (this.engagement.engagementType) {
      case 'call':
        this.dateOfEngagementTitle = 'Date of call';
        this.rateTitle = 'Call rate';
        this.quantityEngagedTitle = 'Call duration (minutes)';
        this.netUnitsUsedTitle = 'Net units used';
        this.unitsUsedAdjustmentTitle = 'Units used adjustment';
        this.reasonTitle = '';
        break;
      case 'survey':
        this.dateOfEngagementTitle = 'Date of survey';
        this.rateTitle = 'Survey rate';
        this.quantityEngagedTitle = 'No. of surveys';
        this.netUnitsUsedTitle = 'Net units used';
        this.unitsUsedAdjustmentTitle = 'Units used adjustment';
        this.reasonTitle = '';
        break;
      case 'data-review':
        this.dateOfEngagementTitle = 'Date of data review';
        this.rateTitle = 'Data review rate';
        this.quantityEngagedTitle = 'Hours engaged';
        this.netUnitsUsedTitle = 'Net units used';
        this.unitsUsedAdjustmentTitle = 'Units used adjustment';
        this.reasonTitle = '';
        break;
      case 'payment':
        this.dateOfEngagementTitle = 'Date of payment';
        this.rateTitle = '';
        this.quantityEngagedTitle = '';
        this.netUnitsUsedTitle = '';
        this.unitsUsedAdjustmentTitle = 'Units used';
        this.reasonTitle = 'Payment reason';
        break;
      default:
        console.error('Unknown engagement type ', this.engagement);
    }
  }

  private isEnagementEditable(dateString: string): boolean {
    const engagementDate = new Date(dateString);
    const now = new Date();

    return (
      engagementDate.getTime() > now.getTime() ||
      (engagementDate.getFullYear() === now.getFullYear() &&
        (engagementDate.getMonth() === now.getMonth() ||
          (engagementDate.getMonth() === now.getMonth() - 1 &&
            now.getDate() < 2)))
    );
  }
}
