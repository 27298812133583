import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { from, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ISearchDisplayExpert } from '../models/search-models';
import {
  ISearchFilter,
  ISearchScrolls,
  ResultsDisplayFilterService,
} from './results-display-filter.service';

const DEFAULT_VALUE = 'not-set';

@Component({
  selector: 'app-results-display-filter',
  templateUrl: './results-display-filter.component.html',
  styleUrls: ['./results-display-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsDisplayFilterComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() experts: ISearchDisplayExpert[] = [];
  @Output() toggleFitler = new EventEmitter<ISearchDisplayExpert[]>();

  @ViewChild('expertiseScroll', { static: true })
  expertiseScroll: CdkVirtualScrollViewport;
  @ViewChild('affiliationsScroll', { static: true })
  affiliationsScroll: CdkVirtualScrollViewport;

  private destroy$ = new Subject();

  searchFilterForm = new FormGroup({
    country: new FormGroup({}),
    expertise: new FormGroup({}),
    affiliations: new FormGroup({}),
  });
  filteredExperts: ISearchDisplayExpert[] = [];
  countryCounts: Record<string, number> = {};
  expertiseCounts: Record<string, number> = {};
  affiliationsCounts: Record<string, number> = {};

  get countriesCtl(): FormGroup {
    return this.searchFilterForm.controls.country as FormGroup;
  }

  get expertiseCtl(): FormGroup {
    return this.searchFilterForm.controls.expertise as FormGroup;
  }

  get affiliationsCtl(): FormGroup {
    return this.searchFilterForm.controls.affiliations as FormGroup;
  }

  constructor(private filters: ResultsDisplayFilterService) {}

  ngOnInit(): void {
    this.searchFilterForm.valueChanges
      .pipe(
        debounceTime<ISearchFilter>(250),
        takeUntil(this.destroy$),
        tap((filter) => this.filters.set(filter)),
        map((filter) => this.getFilteredExperts(filter)),
        tap((experts) => (this.filteredExperts = experts)),
        tap((experts) => this.toggleFitler.emit(experts)),
        tap(() => this.setCounts())
      )
      .subscribe();

    this.filteredExperts = this.experts;
    this.setCounts();
    this.setupFormControls();
  }

  ngAfterViewInit(): void {
    const ctrls: {
      name: keyof ISearchScrolls;
      ctrl: CdkVirtualScrollViewport;
    }[] = [
      {
        ctrl: this.expertiseScroll,
        name: 'expertise',
      },
      {
        ctrl: this.affiliationsScroll,
        name: 'affiliations',
      },
    ];

    setTimeout(() => {
      ctrls.forEach((c) => c.ctrl.scrollToIndex(this.filters.scrolls[c.name]));
    }, 0);

    from(ctrls)
      .pipe(
        mergeMap((c) =>
          c.ctrl.scrolledIndexChange.pipe(
            distinctUntilChanged(),
            filter((idx) => idx > 0),
            tap((idx) => this.filters.setScroll(c.name, idx))
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setCounts(): void {
    this.countryCounts = this.getFilterCounts('country');
    this.expertiseCounts = this.getFilterCounts('expertise');
    this.affiliationsCounts = this.getFilterCounts('affiliations');
  }

  trackByFn(_: number, { key }: { key: string }): string {
    return key;
  }

  private setupFormControls(): void {
    const addControls = (field: string, fg: FormGroup): void =>
      [
        ...new Set<string>(
          (this.experts || [])
            .flatMap((e) => e.source[field])
            .map((d) => this.sanatizeValue(d || DEFAULT_VALUE))
        ),
      ].forEach((c) => {
        const storedValue = this.filters.filter[field][c];
        return fg.registerControl(
          c,
          new FormControl(storedValue !== undefined ? storedValue : true)
        );
      });

    addControls('country', this.countriesCtl);
    addControls('expertise', this.expertiseCtl);
    addControls('affiliations', this.affiliationsCtl);
  }

  private getFilteredExperts(filter: ISearchFilter): ISearchDisplayExpert[] {
    return this.experts
      .filter((expert) =>
        !expert.source.country
          ? true
          : filter.country[this.sanatizeValue(expert.source.country)]
      )
      .filter((expert) =>
        expert.source.expertise.length
          ? expert.source.expertise
              .map((d) => this.sanatizeValue(d))
              .some((d) => filter.expertise[d])
          : true
      )
      .filter((expert) =>
        expert.source.affiliations.length
          ? expert.source.affiliations
              .map((d) => this.sanatizeValue(d))
              .some((d) => filter.affiliations[d])
          : true
      );
  }

  private getFilterCounts(field: string): Record<string, number> {
    return (this.filteredExperts || [])
      .flatMap((e) => e.source[field])
      .map((e) => this.sanatizeValue(e))
      .reduce(
        (prev, curr) =>
          Object.assign(prev, {
            [curr || DEFAULT_VALUE]: (prev[curr || DEFAULT_VALUE] || 0) + 1,
          }),
        {}
      );
  }

  private sanatizeValue(d: string): string {
    return `${d}`.trim().toLowerCase();
  }
}
