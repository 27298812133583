import { Pipe, PipeTransform } from '@angular/core';
import { ConnectUser } from '@techspert-io/auth';

@Pipe({
  name: 'expertOwnerName',
})
export class ExpertOwnerNamePipe implements PipeTransform {
  transform(content: { ownerConnectId: string }, users: ConnectUser[]): string {
    const user = users.find((u) => u.connectId === content.ownerConnectId);

    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }

    return 'unknown';
  }
}
