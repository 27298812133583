import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EngagementPaymentProviders } from '@techspert-io/engagements';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentProvidersService {
  private cache: Observable<EngagementPaymentProviders[]>;

  constructor(private httpClient: HttpClient) {}

  getProviders(): Observable<EngagementPaymentProviders[]> {
    if (!this.cache) {
      this.cache = this.httpClient
        .get<EngagementPaymentProviders[]>(
          '/payments/getThirdPartyPaymentTypes'
        )
        .pipe(
          map((p) => p.sort()),
          shareReplay(1)
        );
    }
    return this.cache;
  }
}
