import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { expertSegmentList, IExpert } from '@techspert-io/experts';
import {
  IAggregatedOpportunitySegment,
  IOpportunity,
  OpportunitySegmentsService,
} from '@techspert-io/opportunities';
import { ITimezone } from '../../../../../../shared/models/country';
import { CountryService } from '../../../../../../shared/services/country.service';
import { CurrencyService } from '../../../../../../shared/services/currency.service';

@Component({
  selector: 'app-expert-responded-detail',
  templateUrl: './expert-responded-detail.component.html',
  styleUrls: ['./expert-responded-detail.component.scss'],
})
export class ExpertRespondedDetailComponent implements OnInit {
  @Input() public expert: IExpert;
  @Input() public opportunity: IOpportunity;
  @Input() public targetPhaseIndex: number;
  @Output() public expertEmitter = new EventEmitter<IExpert>();
  public emailList: string[];
  public possibleZones: ITimezone[];
  public aggregatedTargets: IAggregatedOpportunitySegment[];
  public targetGeos: string[];
  public profileOptions: string[];
  public segments = expertSegmentList;

  public costBandOptions: IExpert['costBand'][] = [
    'Discounted',
    'Standard',
    'Premium',
    'Premium+',
  ];

  public tzName: string;

  public currencyFormControl = new FormControl(
    null,
    this.currencyService.validateCurrency.bind(this.currencyService)
  );

  public unitsPerHourControl = new FormControl(null, [
    Validators.required,
    Validators.min(0.01),
    this.unitsPerHourValidator(),
  ]);

  public costBandControl = new FormControl(null, [Validators.required]);

  get isCostBandStandard(): boolean {
    return this.costBandControl.value === 'Standard';
  }

  constructor(
    public countryService: CountryService,
    private currencyService: CurrencyService,
    private opportunitySegmentsService: OpportunitySegmentsService
  ) {}

  public ngOnInit(): void {
    this.setupCurrencyForm();
    this.setupUnitsPerHourForm();

    if (this.opportunity) {
      this.aggregatedTargets =
        this.opportunitySegmentsService.aggregateSegments(
          this.opportunity.segments
        );
    }
    this.targetGeos = this.aggregatedTargets.map((t) => t.geography);

    if (this.targetGeos.includes(this.expert.geographicTarget)) {
      this.assignTargetGeography(
        this.expert.geographicTarget,
        this.expert.profileType
      );
    } else if (this.targetGeos.length === 1) {
      this.expert.geographicTarget = this.targetGeos[0];
      this.assignTargetGeography(this.expert.geographicTarget);
    }

    this.emailList = [
      ...new Set([this.expert.primaryEmail, ...this.expert.opportunityEmails]),
    ].filter(Boolean);

    if (this.expert.country) {
      this.assignExpertCountryAndGetZones(
        this.expert.country,
        this.expert.timezoneName
      );
    }
  }

  public assignTargetGeography(geography: string, profileType?: string): void {
    const geos = this.aggregatedTargets.filter(
      (target) => target.geography === geography
    );
    this.profileOptions = geos[0]
      ? geos[0].segments.map((s) => s.segment)
      : undefined;
    if (!profileType || !this.profileOptions.find((p) => p === profileType)) {
      if (this.profileOptions.length === 1) {
        this.expert.profileType = this.profileOptions[0];
      } else {
        this.expert.profileType = undefined;
      }
    }

    this.expert.opportunitySegmentId =
      this.opportunitySegmentsService.getOpportunitySegmentId(
        this.opportunity.segments,
        this.expert
      );

    this.emitExpertChange();
  }

  public selectPocEmail(email: string): void {
    this.expert.primaryEmail =
      this.expert.primaryEmail === email ? undefined : email;
    this.emitExpertChange();
  }

  public assignExpertCountryAndGetZones(
    country: string,
    tzName?: string
  ): void {
    this.possibleZones =
      this.countryService.getTimezonesForCountry(country) || [];
    if (this.possibleZones) {
      this.expert.country = country;
    }
    if (tzName && this.possibleZones.find((z) => z.name === tzName)) {
      this.assignZone(tzName);
    } else if (this.possibleZones.length === 1) {
      this.expert.timezoneName = this.possibleZones[0].name;
      this.assignZone(this.possibleZones[0].name);
    } else {
      this.tzName = undefined;
      this.expert.timezoneName = undefined;
      this.emitExpertChange();
    }
  }

  public assignZone(tzName: string): void {
    this.tzName = tzName;
    if (this.possibleZones && this.possibleZones.length > 0 && tzName) {
      this.expert.timezoneName = this.possibleZones.find(
        (zoneObj) => zoneObj.name === tzName
      ).name;
      this.emitExpertChange();
    }
  }

  public emitExpertChange(): void {
    return this.expertEmitter.emit(this.expert);
  }

  private setupCurrencyForm(): void {
    this.currencyFormControl.setValue(this.expert.currency);

    this.currencyFormControl.valueChanges.subscribe((currency) => {
      this.expert.currency = currency;
      this.emitExpertChange();
    });
  }

  private setupUnitsPerHourForm(): void {
    this.unitsPerHourControl.valueChanges.subscribe((unitsPerHour) => {
      this.expert.unitsPerHour = unitsPerHour;
      this.emitExpertChange();
    });

    this.costBandControl.valueChanges.subscribe((costBand) => {
      this.expert.costBand = costBand;
      this.emitExpertChange();
    });

    this.unitsPerHourControl.setValue(this.expert.unitsPerHour || 0);
    this.costBandControl.setValue(this.expert.costBand || 'Standard');
  }

  private unitsPerHourValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const [, post] = `${control.value}`.split('.');

      return post?.length > 2
        ? { twoDpValidator: 'Must be fewer than 3dp' }
        : null;
    };
  }
}
