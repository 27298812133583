import { Component, Input } from '@angular/core';
import {
  IExpertScreenerConfig,
  IExpertScreenerResult,
} from '@techspert-io/experts';
import 'moment-timezone';

@Component({
  selector: 'app-screener-box',
  templateUrl: './screener-box.component.html',
  styleUrls: ['./screener-box.component.scss'],
})
export class ScreenerBoxComponent {
  @Input() screenerConfig: IExpertScreenerConfig;
  @Input() screenerResult?: IExpertScreenerResult;
}
