import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchQueryResponse } from '../models/omnisearch.models';

@Pipe({
  name: 'omnisearchUrl',
})
export class UrlPipe implements PipeTransform {
  transform(data: OmnisearchQueryResponse): string[] {
    const baseUrl: string[] = ['/admin', 'client'];
    switch (data.entity) {
      case 'opportunities': {
        const { clientId, id } = data;
        return [...baseUrl, clientId, 'opportunity', id];
      }
      case 'clients': {
        const { id } = data;
        return [baseUrl[0], 'clients', id];
      }
      case 'experts':
      case 'solicitationExperts': {
        const { id, clientId, oppId } = data;
        return [
          ...baseUrl,
          clientId,
          'opportunity',
          oppId,
          'search',
          'all-searches',
          'phase',
          'identified',
          'details',
          id,
        ];
      }
    }
  }
}
