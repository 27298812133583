import { IExpertSource } from '../models/expert-profiles.models';

export const baseExpertSourceMock: IExpertSource = {
  expertSourceId: 'expertSourceId1',
  opportunityId: 'opportunityId1',
  status: 'success',
  segment: 'Academic - KOL',
  searchName: 'search-name',
  opportunitySegmentId: 'opportunitySegment1',
  query: {
    conditions: [],
    countries: [],
    fromIndex: 0,
    size: 5,
    service: 'deep3',
  },
  expertsFound: 34,
  expertsAdded: 23,
  source: 'async-search',
  batchId: 'batchId1',
  dateCreated: 1234,
  lastUpdated: 123,
  createdBy: 'createdBy1',
  updatedBy: 'updatedBy1',
};
