import { IExpert } from '@techspert-io/experts';

export interface IRecipient {
  emails: IRecipientEmail[];
  firstName: string;
  lastName: string;
  id: string;
  connectPhase: IExpert['connectPhase'];
  source: IExpert['source'];
  segment: IExpert['expertSegment'];
}

export interface IRecipientEmail {
  address: string;
  type: 'standard' | 'poc' | 'transactional';
}
