import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

const declarations = [];

@NgModule({
  providers: [DatePipe],
  declarations: declarations,
  exports: declarations,
})
export class ExpertActionsModule {}
