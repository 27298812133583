import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpertFile, FileType } from '../../models/expert-file.model';

@Component({
  selector: 'app-expert-file-edit-dialog',
  templateUrl: './expert-file-edit-dialog.component.html',
  styleUrls: ['./expert-file-edit-dialog.component.scss'],
})
export class ExpertFileEditDialogComponent implements OnInit {
  fileForm = new FormGroup({
    nameInput: new FormControl<string>(null, [
      Validators.pattern(/\./),
      Validators.required,
    ]),
    typeInput: new FormControl<FileType>(null, Validators.required),
  });
  fileTypes: FileType[] = [
    'manual',
    'screenerResponse',
    'zoomRecording',
    'zoomTranscript',
  ];

  constructor(
    private dialogRef: MatDialogRef<ExpertFileEditDialogComponent, ExpertFile>,
    @Inject(MAT_DIALOG_DATA) private file: ExpertFile
  ) {}

  ngOnInit(): void {
    this.fileForm.setValue({
      nameInput: `${this.file.fileName}.${this.file.fileExtension}`,
      typeInput: this.file.type || 'manual',
    });
  }

  submitForm(): void {
    const { ext, name } = this.mapUploadedFile(
      this.fileForm.get('nameInput').value
    );

    this.dialogRef.close({
      ...this.file,
      fileName: name,
      fileExtension: ext,
      type: this.fileForm.get('typeInput').value,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private mapUploadedFile(filename: string): { name: string; ext: string } {
    const getFileName = (filename: string): string => {
      const sl = filename.split('.');
      return sl.length > 1 ? sl.slice(0, -1).join('.') : filename;
    };
    const getExtension = (filename: string): string => {
      const sl = filename.split('.');
      return sl.length > 1 ? sl.slice(-1).join('.') : '';
    };

    return {
      name: getFileName(filename),
      ext: getExtension(filename),
    };
  }
}
