import { HttpClientTestingModule } from '@angular/common/http/testing';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { PORTAL_URL } from '../pipes/portal-links.pipe';

@NgModule({
  imports: [HttpClientTestingModule, RouterTestingModule, NoopAnimationsModule],
  exports: [HttpClientTestingModule, RouterTestingModule, NoopAnimationsModule],
  providers: [{ provide: PORTAL_URL, useValue: 'http://portal.example' }],
})
export class OpportunitiesTestingModule {}
