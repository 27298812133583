import { IOutreachTemplate } from '../models/outreach-templates.models';

export const baseOutreachTemplateMock: IOutreachTemplate = {
  title: 'title1',
  description: 'description1',
  subject: 'subject1',
  preheader: 'preheader1',
  content: 'bodyContent',
  templateId: 'templateId1',
  parentId: 'parentId1',
  active: true,
  createdBy: '',
  lastUpdatedBy: '',
  dateCreated: 123,
  language: '',
  lastUpdated: 1234,
};
