import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExpertFieldValuePipe } from './pipes/expert-field-value.pipe';
import { ExpertFieldPipe } from './pipes/expert-field.pipe';
import { ExpertOwnerNamePipe } from './pipes/expert-owner-name.pipe';
import { ExpertRejectionReasonPipe } from './pipes/expert-rejection-reason.pipe';
import { PhaseNamePipe } from './pipes/phase-name.pipe';
import { ExpertsCreateService } from './services/experts-create.service';
import { ExpertsQueryService } from './services/experts-query.service';
import { ExpertsUpdateService } from './services/experts-update.service';
import { ExpertValidationService } from './services/experts-validator.service';

const declarations = [
  ExpertFieldPipe,
  ExpertFieldValuePipe,
  ExpertOwnerNamePipe,
  ExpertRejectionReasonPipe,
  PhaseNamePipe,
];

@NgModule({
  providers: [
    DatePipe,
    ExpertsUpdateService,
    ExpertsCreateService,
    ExpertsQueryService,
    ExpertValidationService,
    TitleCasePipe,
  ],
  declarations: declarations,
  exports: declarations,
})
export class ExpertsModule {}
