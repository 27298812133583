import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expert-previous-bio-button',
  templateUrl: './expert-previous-bio-button.component.html',
  styleUrls: ['./expert-previous-bio-button.component.scss'],
})
export class ExpertPreviousBioButtonComponent {
  @Input() bios: string[] = [];
  @Output() bioSelected = new EventEmitter<string>();

  showOldBioDropdown: boolean = false;

  showBios(): void {
    this.showOldBioDropdown = true;
  }

  onClickOutside(): void {
    if (this.showOldBioDropdown) {
      this.showOldBioDropdown = false;
    }
  }

  onBioSelected(bio: string): void {
    this.showOldBioDropdown = false;

    this.bioSelected.emit(bio);
  }
}
