import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { Raygun, RAY_GUN } from '@techspert-io/errorHandling';
import { NotificationService } from '@techspert-io/notifications';
import * as moment from 'moment';
import { interval, merge, Observable, of } from 'rxjs';
import { delay, filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 1, bottom: -15 }),
        animate('1000ms', style({ transform: 'translateY(0)', bottom: -15 })),
      ]),
      transition(':leave', [
        animate(
          '700ms',
          style({ transform: 'translateY(100%)', bottom: -100 })
        ),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  showSanta$: Observable<boolean>;

  constructor(
    private router: Router,
    private cognitoAuthService: CognitoAuthService,
    private notificationService: NotificationService,
    @Inject(RAY_GUN) private raygun: Raygun
  ) {}

  ngOnInit(): void {
    this.notificationService.initPoll().subscribe();

    this.cognitoAuthService.authError$.subscribe((err) =>
      this.router.navigate(['error'], { queryParams: { message: err.message } })
    );

    this.cognitoAuthService
      .initAuth()
      .pipe(takeUntil(this.cognitoAuthService.authError$))
      .subscribe((user) => {
        if (user) {
          this.handleRedirect();
          this.raygun('setUser', {
            identifier: user.email,
            isAnonymous: false,
            uuid: user.connectId,
          });
          this.showTheSanta(user.email);
        }
      });
  }

  showTheSanta(email: string): void {
    this.showSanta$ = interval(190000).pipe(
      filter(() => moment().month() === 11),
      filter(() => !['stuart.tottle@techspert.com'].includes(email)),
      switchMap(() => merge(of(true), of(false).pipe(delay(3400))))
    );
  }

  private handleRedirect(): void {
    const redirectUrl = this.cognitoAuthService.getRedirectUrl();
    this.cognitoAuthService.setRedirectUrl(null);

    if (redirectUrl) {
      this.router.navigate([redirectUrl], {
        replaceUrl: true,
      });
    }
  }
}
