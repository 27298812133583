import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@techspert-io/auth';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  INotification,
  NotificationTypeMap,
} from '../../models/notification.models';
import { NotificationService } from '../../services/notification.service';

type FilterFn = (notif: INotification) => boolean;

@Component({
  selector: 'app-notification-actions',
  templateUrl: './notification-actions.component.html',
  styleUrls: ['./notification-actions.component.scss'],
})
export class NotificationActionsComponent implements OnInit {
  @Input() filterFn: FilterFn = () => true;

  notifications$: Observable<INotification[]> = EMPTY;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationService.notifications$.pipe(
      map((notifications) =>
        notifications.filter(
          (n) =>
            !n.archived && this.filterFn(n) && this.isNotificationAction(n.type)
        )
      )
    );
  }

  archiveNotification(notificationId: string): void {
    this.notificationService.archiveNotification(notificationId).subscribe();
  }

  routeAction(actionLink: string): void {
    actionLink.startsWith('http')
      ? this.window.open(actionLink)
      : this.router.navigate([actionLink]);
  }

  private isNotificationAction(type: NotificationTypeMap): unknown {
    return [
      NotificationTypeMap.availabilityRequest,
      NotificationTypeMap.expertAccepted,
      NotificationTypeMap.expertCallCompleted,
      NotificationTypeMap.surveyCompleted,
    ].includes(type);
  }
}
