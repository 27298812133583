export type UserJobTitle =
  | 'associate'
  | 'senior-associate'
  | 'associate-project-manager'
  | 'senior-project-manager'
  | 'internal';

export type UserType = 'PM' | 'Client' | 'Expert' | 'Service-Provider';
export type UserRoleType = 'projectUser' | 'clientAdmin';

export type ConnectUser = IUserBase;

export interface IConnectUserProfile {
  jobTitle?: UserJobTitle;
  phoneNumber?: string;
  linkedInUrl?: string;
  officeLocation?: 'houston' | 'cambridge';
}

export interface IUserBase {
  connectId: string;
  email: string;
  firstName: string;
  lastName: string;
  userType: UserType;
  userRoles: UserRoleType[];
  roles: ('PM Beta' | 'Other')[];
  active: boolean;
  profile: IConnectUserProfile;
  clientIds: string[];
  country: string;
  timezone: {
    abbr: string;
    name: string;
    offset: string;
  };
  lastUpdated: number;
  dateCreated: number;
}
