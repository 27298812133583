import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { KeyValue } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IClient } from '@techspert-io/clients';
import {
  ExpertCallActionService,
  IExpertConferenceEdit,
} from '@techspert-io/conferences';
import {
  ConnectPhase,
  connectPhaseList,
  ExpertsQueryService,
  ExpertsUpdateService,
  ExpertValidationService,
  getExpertRejectionMock,
  IDisplayExpert,
  IDisplayExpertCallAction,
  IExpert,
  IExpertScreenerResult,
  IExpertUpdateRequest,
} from '@techspert-io/experts';
import { IOpportunity, PortalLinksPipe } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import { EMPTY, fromEvent, merge, Observable, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { OpportunityViewDialogComponent } from '../../../../shared/components/opportunity-view-dialog/opportunity-view-dialog.component';
import {
  IScheduleDialogClosePayload,
  IScheduleDialogInput,
  scheduleDialogSize,
} from '../../../../shared/components/schedule/schedule-models';
import { ScheduleComponent } from '../../../../shared/components/schedule/schedule.component';
import { UpdateEmergencyContactComponent } from '../../../../shared/components/update-emergency-contact/update-emergency-contact.component';
import {
  IViewConnectionInput,
  ViewConnectionComponent,
  viewConnectionDialogSize,
} from '../../../../shared/components/view-connection/view-connection.component';
import {
  ConfirmationDialogComponent,
  IConfirmationDialogData,
} from '../../../../shared/patterns/confirmation-dialog/confirmation-dialog.component';
import { EmailService } from '../../../../shared/services/email.service';
import { IExpertProfileDialogInput } from './expert-profile-dialog/expert-profile-dialog-models';
import { ExpertProfileDialogComponent } from './expert-profile-dialog/expert-profile-dialog.component';
import { ExpertScreenerResultDialogComponent } from './expert-screener-results-dialog/expert-screener-result-dialog.component';
import { OpportunitySearchSelectDialogComponent } from './opportunity-search-select-dialog/opportunity-search-select-dialog.component';
import {
  IRespondedDialogInput,
  IRespondedDialogResponse,
  RespondedDialogComponent,
} from './responded-dialog/responded-dialog.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import {
  ISendSolicitationDialogData,
  SendSolicitationDialogComponent,
} from './send-solicitation-dialog/send-solicitation-dialog.component';
import { OpportunityExpertSourcesService } from './services/opportunity-expert-sources.service';
import { OpportunityExpertsService } from './services/opportunity-experts.service';

type OpportunityPhases = ConnectPhase | 'rejected';

export type ExpertOperation = 'schedule' | 'details' | 'screener';

@Component({
  selector: 'app-opportunity-results',
  templateUrl: './opportunity-results.component.html',
  styleUrls: ['./opportunity-results.component.scss'],
  providers: [PortalLinksPipe],
})
export class OpportunityResultsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() client: IClient;
  @Input() opportunity: IOpportunity;
  @Input() operation: ExpertOperation;
  @Input() expertId: string;
  @Input() selectedPhase: OpportunityPhases = 'identified';
  @Input() selectedSearch: string = '';
  @Input() isLoading: boolean;

  @ViewChild('phaseMenu')
  phaseMenu: TemplateRef<unknown>;

  private destory$ = new Subject();

  expertsTotal$: Observable<number> = EMPTY;
  overlayRef: OverlayRef | null;
  experts: IDisplayExpert[] = [];
  filteredExperts: IDisplayExpert[] = [];
  searchTerm = '';
  showLoader: boolean = false;
  selectAllToggle: boolean = false;
  selectedExpertsCount: number = 0;
  filterEmails: boolean = true;
  filterAutoExperts: boolean = true;
  openFilter: boolean = false;
  showAffiliationsToggle = false;
  showNoExperts: boolean = false;
  selectedSearches: string[] = [];
  eligiblePhaseMenuOptions: Array<KeyValue<string, string>> = [];

  get hasAutomatedExperts(): boolean {
    return this.experts.some((d) => !!d.campaignId);
  }

  private static readonly PHASES: Map<OpportunityPhases, string> = new Map([
    ['identified', 'Identified'],
    ['firstFollowUp', 'Follow up 1'],
    ['secondFollowUp', 'Follow up 2'],
    ['outreachComplete', 'Outreach Complete'],
    ['outreach', 'Responded'],
    ['screener', 'Screener'],
    ['sentToClient', 'In Portal'],
    ['accepted', 'Accepted'],
    ['scheduled', 'Scheduled'],
    ['completed', 'Completed'],
    ['rejected', 'Reject'],
  ]);
  static readonly NEXT = 'next';
  static readonly PREVIOUS = 'previous';

  constructor(
    private router: Router,
    private title: Title,
    private dialog: MatDialog,
    private emailService: EmailService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private expertsUpdateService: ExpertsUpdateService,
    private expertsQueryService: ExpertsQueryService,
    private opportunityExpertsService: OpportunityExpertsService,
    private opportunityExpertSourcesService: OpportunityExpertSourcesService,
    private expertValidationService: ExpertValidationService,
    private toastService: ToastService,
    private expertCallActionService: ExpertCallActionService
  ) {}

  ngOnInit(): void {
    const expertSources$ = this.opportunityExpertSourcesService
      .getSearches(
        this.opportunity.opportunityId,
        Object.keys(this.opportunity.searches)
      )
      .pipe(
        tap((searchIds) => this.initialiseData(this.opportunity, searchIds))
      );

    const loadingState$ =
      this.opportunityExpertsService.downloadedSearches$.pipe(
        tap(
          (res) =>
            (this.showLoader = Object.values(res).some((d) =>
              ['init', 'loading'].includes(d)
            ))
        )
      );

    const experts$ = this.opportunityExpertsService.experts$.pipe(
      tap((experts) => {
        this.experts = experts;
        this.applyFilter();
      })
    );

    merge(expertSources$, loadingState$, experts$)
      .pipe(takeUntil(this.destory$))
      .subscribe();

    this.expertsTotal$ = this.opportunityExpertsService.expertTotals$.pipe(
      map((expertsTotal) =>
        Object.values(expertsTotal).reduce(
          (prev, val) => prev + Object.values(val).reduce((p, c) => p + c, 0),
          this.experts.filter((d) => d.rejection.admin || d.rejection.client)
            .length
        )
      )
    );

    this.initialiseData(this.opportunity);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.opportunity && !changes.opportunity.firstChange) {
      this.experts = [];
      this.filteredExperts = [];
      this.searchTerm = '';
      this.selectedExpertsCount = 0;
      this.selectAllToggle = false;
      this.filterEmails = true;
      this.filterAutoExperts = true;
      this.showNoExperts = false;
      this.initialiseData(changes.opportunity.currentValue);
    }

    if (changes.expertId || changes.operation) {
      this.handleInputExpertAction();
    }
  }

  ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }

  addExpertiseToSearch(expertise: string): void {
    this.searchTerm = expertise;
    this.applyFilter();
  }

  navigateToExpert(expert: IExpert): void {
    this.selectPhase(
      expert.rejection.admin || expert.rejection.client || expert.blocked
        ? 'rejected'
        : expert.connectPhase
    );
  }

  toggleBlockExpert(status: { index: number }): void {
    const expert = this.filteredExperts[status.index];

    const emails = [
      ...new Set([
        ...(expert.primaryEmail ? [expert.primaryEmail] : []),
        ...expert.opportunityEmails,
      ]),
    ];
    const blocked = !expert.blocked;

    if (blocked) {
      this.emailService
        .block(emails)
        .pipe(
          catchError(() => {
            this.toastService.sendMessage(
              `Failed to unsubscribe emails: ${emails.join(', ')}`,
              'error'
            );
            return EMPTY;
          })
        )
        .subscribe();
    }

    this.updateExperts(
      [{ expertId: expert.expertId, blocked: blocked }],
      false
    ).subscribe();
  }

  rejectExperts(): void {
    const experts = this.experts
      .filter((expert) => expert.isSelected)
      .map((expert) => ({
        rejection: {
          ...expert.rejection,
          admin: getExpertRejectionMock(Date.now()),
        },
        expertId: expert.expertId,
      }));

    this.updateExperts(experts).subscribe();
  }

  unrejectExperts(): void {
    const updatedExperts = this.experts.filter((expert) => expert.isSelected);

    const clientRejected = updatedExperts.filter((e) => e.rejection.client);

    const expertUpdates = updatedExperts.map(({ expertId }) => ({
      expertId,
      rejection: {},
    }));

    if (clientRejected.length) {
      const expertText = clientRejected.length > 1 ? 'experts' : 'expert';

      this.dialog
        .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            width: '400px',
            height: '150px',
            data: {
              message: `This will overwrite the client's rejection for ${clientRejected.length} ${expertText}. Are you sure?`,
              positiveButtonText: 'Yes',
              negativeButtonText: 'No',
              isDangerous: true,
            },
          }
        )
        .afterClosed()
        .pipe(
          takeWhile((result) => result),
          switchMap(() => this.updateExperts(expertUpdates))
        )
        .subscribe();
    } else {
      this.updateExperts(expertUpdates).subscribe();
    }
  }

  toggleAdminRejectedExpertStatus(expert: IExpert): void {
    if (!expert.rejection.admin && !expert.rejection.client) {
      this.updateExperts([
        {
          expertId: expert.expertId,
          rejection: {
            ...expert.rejection,
            admin: getExpertRejectionMock(Date.now()),
          },
        },
      ]).subscribe();
    } else if (expert.rejection.client) {
      this.dialog
        .open<ConfirmationDialogComponent, IConfirmationDialogData, boolean>(
          ConfirmationDialogComponent,
          {
            width: '400px',
            height: '150px',
            data: {
              message:
                "This will overwrite the client's rejection for the target expert. Are you sure?",
              positiveButtonText: 'Yes',
              negativeButtonText: 'No',
              isDangerous: true,
            },
          }
        )
        .afterClosed()
        .pipe(
          takeWhile((result) => result),
          switchMap(() =>
            this.updateExperts([
              {
                expertId: expert.expertId,
                rejection: {},
                reasonForRejection: 'NULL',
              },
            ])
          )
        )
        .subscribe();
    } else if (expert.rejection.admin) {
      this.updateExperts([
        {
          expertId: expert.expertId,
          rejection: {},
          reasonForRejection: 'NULL',
        },
      ]).subscribe();
    }
  }

  selectPhase(event: OpportunityPhases): void {
    this.selectedExpertsCount = 0;
    this.selectAllToggle = false;
    this.selectedPhase = event;

    this.opportunityExpertsService.upsertMany(
      this.experts.map((expert) => ({
        ...expert,
        isSelected: false,
      }))
    );

    this.router.navigate([
      'admin',
      'client',
      this.client.clientId,
      'opportunity',
      this.opportunity.opportunityId,
      'search',
      this.selectedSearch,
      'phase',
      this.selectedPhase,
    ]);
  }

  selectExpert(selectedExpert: IDisplayExpert): void {
    this.opportunityExpertsService.upsertExpert({
      ...selectedExpert,
      isSelected: !selectedExpert.isSelected,
    });
  }

  selectAllExperts(): void {
    this.selectAllToggle = !this.selectAllToggle;
    this.setExpertSelectedStatus(this.selectAllToggle);
  }

  applyFilterEmails(): void {
    this.filterEmails = !this.filterEmails;
    this.applyFilter();
  }

  applyFilterAutoExperts(): void {
    this.filterAutoExperts = !this.filterAutoExperts;
    this.applyFilter();
  }

  applyFilter(): void {
    this.experts = [this.setIsSelectable].reduce<IDisplayExpert[]>(
      (acc, curr) => curr.bind(this)(acc),
      this.experts
    );

    this.filteredExperts = this.experts.filter(
      (expert) =>
        this.searchFilter(expert) &&
        (expert.connectPhase === this.selectedPhase ||
          this.selectedPhase === 'rejected') &&
        (this.selectedPhaseNotRejected(expert) ||
          this.selectedPhaseRejected(expert)) &&
        (expert.primaryEmail ||
          expert.opportunityEmails.length ||
          !this.filterEmails) &&
        (!this.searchTerm || this.checkFields(expert)) &&
        (expert.isSelectable || !this.filterAutoExperts)
    );

    this.openFilter = false;
    if (!this.filteredExperts.length && this.searchTerm) {
      this.openFilter = true;
      this.filteredExperts = this.experts.filter(
        (expert) => !this.searchTerm || this.checkFields(expert)
      );
    }
    this.updateSelectedCount();
    this.showNoExperts =
      this.filteredExperts.length === 0 && !this.isLoading && !this.showLoader;
  }

  editExpert(expertForEdit: IExpert, focusEngagementId?: string): void {
    const dialogRef = this.dialog.open<
      ExpertProfileDialogComponent,
      IExpertProfileDialogInput,
      IExpert
    >(ExpertProfileDialogComponent, {
      width: '1150px',
      height: '80%',
      data: {
        expert: JSON.parse(JSON.stringify(expertForEdit)),
        opportunity: this.opportunity,
        focus: {
          displayTab: focusEngagementId ? 'billing' : undefined,
          openEngagmentId: focusEngagementId,
        },
      },
    });
    dialogRef.componentInstance.expertSavedSignal
      .pipe(filter((e) => !!e))
      .subscribe((expert) =>
        this.opportunityExpertsService.upsertExpert(expert)
      );
    dialogRef.componentInstance.updatedEngagementsSignal
      .pipe(filter((e) => !!e))
      .subscribe((engagements) =>
        this.opportunityExpertsService.upsertExpert({
          expertId: expertForEdit.expertId,
          engagements,
        })
      );
  }

  openEmailDialog(): void {
    if (this.selectedPhase === 'rejected') return;

    const reachableExperts = this.experts.filter(
      (expert) =>
        expert.isSelected &&
        (expert.primaryEmail || expert.opportunityEmails.length)
    );

    const reachableExpertMap = reachableExperts.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.expertId]: curr,
      }),
      {}
    );

    const experts = this.experts.map((expert) => ({
      ...expert,
      isSelected: !!reachableExpertMap[expert.expertId],
    }));
    this.opportunityExpertsService.upsertMany(experts);

    this.dialog
      .open<
        SendSolicitationDialogComponent,
        ISendSolicitationDialogData,
        undefined
      >(SendSolicitationDialogComponent, {
        width: '1600px',
        height: '80%',
        data: {
          experts: reachableExperts,
          opportunity: this.opportunity,
          activePhase: this.selectedPhase,
          allExperts: experts,
        },
      })
      .afterClosed()
      .subscribe();
  }

  openOpportunityDialog(): void {
    this.dialog
      .open(OpportunityViewDialogComponent, {
        width: '1190px',
        height: '880px',
        data: {
          opportunityId: this.opportunity.opportunityId,
          client: this.client,
          showGoToOpportunity: false,
        },
      })
      .afterClosed()
      .pipe(takeWhile((data) => data))
      .subscribe((data) => {
        const oldKeys = Object.keys(this.opportunity.searches).sort();
        const newKeys = Object.keys(data.searches || {}).sort();
        if (JSON.stringify(oldKeys) !== JSON.stringify(newKeys)) {
          this.opportunity = data;
          this.opportunityExpertsService.resetOpportunity(
            this.opportunity.opportunityId
          );
          this.initialiseData(data);
        } else {
          this.opportunity = data;
        }
      });
  }

  openRespondedDialog(targetPhase: ConnectPhase): void {
    this.dialog
      .open<
        RespondedDialogComponent,
        IRespondedDialogInput,
        IRespondedDialogResponse
      >(RespondedDialogComponent, {
        width: '750px',
        height: '80%',
        data: {
          experts: this.experts.filter((expert) => expert.isSelected),
          opportunity: this.opportunity,
          targetPhase: targetPhase,
        },
      })
      .afterClosed()
      .pipe(
        filter((experts) => !!experts && !!experts.length),
        switchMap((experts) => this.updateExperts(experts))
      )
      .subscribe();
  }

  openConferenceScheduler(expert: IDisplayExpert): void {
    const callAction = (expert.callActions?.request || [])
      .map((d) => d.callAction)
      .find(Boolean);

    const scheduleInput: IScheduleDialogInput = JSON.parse(
      JSON.stringify({
        callAction,
        expert: expert,
        opportunity: this.opportunity,
        client: this.client,
        action: 'schedule',
      })
    );
    this.openSchedulerDialog(scheduleInput).subscribe();
  }

  openConferenceRescheduler(data: IExpertConferenceEdit): void {
    const rescheduleInput: IScheduleDialogInput = JSON.parse(
      JSON.stringify({
        expert: data.expert,
        callAction: data.expertCallAction,
        opportunity: this.opportunity,
        client: this.client,
        action: data.action,
      })
    );
    this.openSchedulerDialog(rescheduleInput).subscribe();
  }

  openCallDetails(data: IExpertConferenceEdit): void {
    this.dialog.open<ViewConnectionComponent, IViewConnectionInput>(
      ViewConnectionComponent,
      {
        ...viewConnectionDialogSize,
        data: {
          callAction: data.expertCallAction,
          isActionsDisabled: true,
        },
      }
    );
  }

  openUpdateEmergencyContact(data: IExpertConferenceEdit): void {
    this.dialog
      .open<UpdateEmergencyContactComponent, string, string>(
        UpdateEmergencyContactComponent,
        {
          data: data.expertCallAction.emergencyContact,
          width: '450px',
          height: '260px',
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((contact) => !!contact),
        switchMap((emergencyContact) =>
          this.expertCallActionService.patch({
            expertActionId: data.expertCallAction.expertActionId,
            emergencyContact,
          })
        ),
        tap((callAction) => {
          const actions: IDisplayExpertCallAction[] =
            data.expert.callActions[callAction.status];

          const action = actions.find(
            (a) => a.callAction.expertActionId === callAction.expertActionId
          );

          const filteredActions = actions
            .filter(
              (a) => a.callAction.expertActionId !== callAction.expertActionId
            )
            .concat({ ...action, callAction });

          this.opportunityExpertsService.upsertExpert({
            expertId: data.expert.expertId,
            callActions: {
              ...data.expert.callActions,
              [callAction.status]: filteredActions,
            },
          });
        }),
        tap(() =>
          this.toastService.sendMessage('Updated emergency contact', 'success')
        ),
        catchError(() => {
          this.toastService.sendMessage(
            'Error occurred when updating emergency contact',
            'error'
          );
          return EMPTY;
        })
      )
      .subscribe();
  }

  getPhase(
    currentPhase: OpportunityPhases,
    direction: string
  ): OpportunityPhases {
    const phases = Array.from(OpportunityResultsComponent.PHASES.keys());
    switch (direction) {
      case OpportunityResultsComponent.PREVIOUS:
        return phases[phases.indexOf(currentPhase) - 1] || 'identified';
      case OpportunityResultsComponent.NEXT:
        if (currentPhase === 'completed') return currentPhase;
        // TODO: Below will never return rejected
        return phases[phases.indexOf(currentPhase) + 1] || 'rejected';
    }
  }

  openSearch(): void {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      width: '100%',
      height: '95%',
      data: {
        experts: this.experts,
        client: this.client,
        opportunity: this.opportunity,
      },
    });
    dialogRef.componentInstance.updateOpportunitySignal
      .pipe(filter((opportunity) => !!opportunity))
      .subscribe(
        (opportunity) => (this.opportunity.searches = opportunity.searches)
      );
    dialogRef
      .afterClosed()
      .subscribe(() => this.initialiseData(this.opportunity));
  }

  attachPhaseMenuOverlay(event: MouseEvent, action: 'previous' | 'next'): void {
    this.closePhaseMenuOverlay();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(event)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.phaseMenu, this.viewContainerRef, {
        action,
      })
    );

    fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.closePhaseMenuOverlay());
  }

  showExpertScreenerResult(expert: IDisplayExpert): void {
    if (expert.screenerResult) {
      this.dialog
        .open<
          ExpertScreenerResultDialogComponent,
          { screenerResult: IExpertScreenerResult },
          void
        >(ExpertScreenerResultDialogComponent, {
          width: '900px',
          height: '80%',
          data: { screenerResult: expert.screenerResult },
          disableClose: false,
        })
        .afterClosed()
        .subscribe();
    }
  }

  showSearchesSelect(): void {
    this.dialog
      .open<OpportunitySearchSelectDialogComponent, string[], string[]>(
        OpportunitySearchSelectDialogComponent,
        {
          width: '900px',
          minHeight: '75%',
          data: this.selectedSearches,
        }
      )
      .afterClosed()
      .pipe(
        takeWhile((res) => !!res),
        map((res) => {
          this.selectedSearches = res;
          this.applyFilter();
        })
      )
      .subscribe();
  }

  closePhaseMenuOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  forwardRightClick(event: MouseEvent): void {
    this.eligiblePhaseMenuOptions = this.getPhasesBeforeOrAfter(
      this.selectedPhase,
      false
    );
    this.attachPhaseMenuOverlay(event, 'next');
  }

  backwardRightClick(event: MouseEvent): void {
    this.eligiblePhaseMenuOptions = this.getPhasesBeforeOrAfter(
      this.selectedPhase,
      true
    );
    this.attachPhaseMenuOverlay(event, 'previous');
  }

  isBackButtonDisabled(): boolean {
    return (
      !this.selectedExpertsCount ||
      (this.selectedExpertsCount > 1 &&
        (this.selectedPhase === 'completed' ||
          this.selectedPhase === 'scheduled'))
    );
  }

  handlePhaseMenuSelect(
    action: 'previous' | 'next',
    phase?: OpportunityPhases
  ): void {
    this.moveExperts(
      phase || this.getPhase(this.selectedPhase, action),
      action
    );
  }

  private setIsSelectable(experts: IDisplayExpert[]): IDisplayExpert[] {
    const outreachPhases: ConnectPhase[] = [
      'identified',
      'firstFollowUp',
      'secondFollowUp',
    ];

    return experts.map((e) => ({
      ...e,
      isSelectable: !(e.campaignId && outreachPhases.includes(e.connectPhase)),
    }));
  }

  private updateSelectedCount(): void {
    this.selectedExpertsCount = this.experts.filter(
      (expert) => expert.isSelected
    ).length;
  }

  private openSchedulerDialog(
    data: IScheduleDialogInput
  ): Observable<IScheduleDialogClosePayload> {
    const dialogRef = this.dialog.open<
      ScheduleComponent,
      IScheduleDialogInput,
      IScheduleDialogClosePayload
    >(ScheduleComponent, {
      ...scheduleDialogSize,
      data,
    });
    return dialogRef.afterClosed().pipe(
      map((payload) => {
        if (payload) {
          this.opportunityExpertsService.upsertExpert(payload.expert);
          this.opportunityExpertsService.getSearches(
            [payload.expert.searchId],
            payload.expert.connectPhase
          );
          if (payload.engagement) {
            this.editExpert(payload.expert, payload.engagement.engagementId);
          }
        }
        return payload;
      })
    );
  }

  private handleInputExpertAction(): void {
    const operationFns: Record<
      typeof this.operation,
      (e: IDisplayExpert) => void
    > = {
      schedule: this.openConferenceScheduler.bind(this),
      details: this.editExpert.bind(this),
      screener: this.showExpertScreenerResult.bind(this),
    };

    if (this.expertId) {
      this.expertsQueryService
        .getById(this.expertId)
        .pipe(filter((e) => e.opportunityId === this.opportunity.opportunityId))
        .subscribe((e) => operationFns[this.operation](e));
    }
  }

  private checkFields(expert: IExpert): boolean {
    return (
      this.checkName(expert) ||
      this.checkAffiliations(expert) ||
      this.checkExpertise(expert) ||
      this.checkLastName(expert) ||
      this.checkEmail(expert)
    );
  }

  private checkAffiliations(expert: IExpert): boolean {
    return (
      expert.affiliations &&
      expert.affiliations
        .join()
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase())
    );
  }

  private checkEmail(expert: IExpert): boolean {
    return (expert.opportunityEmails || []).some((email) =>
      email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private checkExpertise(expert: IExpert): boolean {
    return (
      expert.expertise &&
      expert.expertise
        .join()
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase())
    );
  }

  private checkName(expert: IExpert): boolean {
    const expertFullName = `${expert.firstName} ${expert.lastName}`;
    return (
      expertFullName &&
      expertFullName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private checkLastName(expert: IExpert): boolean {
    return (
      expert.lastName &&
      expert.lastName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  private selectedPhaseNotRejected(expert: IExpert): boolean {
    return (
      this.selectedPhase !== 'rejected' &&
      !expert.rejection.admin &&
      !expert.rejection.client
    );
  }

  private selectedPhaseRejected(expert: IExpert): boolean {
    return (
      this.selectedPhase === 'rejected' &&
      !!(expert.rejection.admin || expert.rejection.client)
    );
  }

  private searchFilter(expert: IExpert): boolean {
    return this.selectedSearches.includes(expert.searchId);
  }

  private getPhasesBeforeOrAfter(
    currentPhase: OpportunityPhases,
    fetchPreceding: boolean
  ): Array<KeyValue<string, string>> {
    const phases = Array.from(OpportunityResultsComponent.PHASES.keys());

    const currentPhaseIndex = phases.indexOf(currentPhase);
    const outReachPhaseIndex = phases.indexOf('outreach');

    let start = 0;
    let end = currentPhaseIndex - 1;

    if (!fetchPreceding) {
      start = currentPhaseIndex + 1;
      end = phases.length - 1;
    } else if (currentPhaseIndex >= outReachPhaseIndex) {
      start = outReachPhaseIndex;
    }

    const result: Array<KeyValue<string, string>> = [];
    while (start <= end) {
      result.push({
        key: phases[start],
        value: OpportunityResultsComponent.PHASES.get(phases[start]),
      });
      start++;
    }

    if (fetchPreceding) {
      return result.reverse();
    } else {
      return result;
    }
  }

  private moveExperts(targetPhase: OpportunityPhases, action: string): void {
    this.closePhaseMenuOverlay();

    if (targetPhase === 'rejected') {
      this.rejectExperts();
    } else if (
      this.selectedExpertsAndInvalid(targetPhase) &&
      action !== 'previous'
    ) {
      this.openRespondedDialog(targetPhase);
    } else {
      this.updatePhase(targetPhase, action);
    }
  }

  private updatePhase(targetPhase: ConnectPhase, action: string): void {
    this.updateExperts(
      this.experts
        .filter((e) => e.isSelected)
        .map((e) => ({
          expertId: e.expertId,
          connectPhase: targetPhase,
        }))
    ).subscribe((experts) => {
      if (
        action === 'previous' &&
        (targetPhase === 'accepted' ||
          targetPhase === 'sentToClient' ||
          targetPhase === 'scheduled')
      ) {
        this.editExpert(experts.find(Boolean));
      }
    });
  }

  private selectedExpertsAndInvalid(targetPhase: ConnectPhase): boolean {
    return (
      this.experts.some((e) => e.isSelected) &&
      !this.expertValidationService.isValid(
        this.experts.filter((e) => e.isSelected),
        targetPhase
      )
    );
  }

  private setExpertSelectedStatus(toggle: boolean): void {
    const filteredExpertIds = new Set(
      this.filteredExperts
        .filter(
          (e) => connectPhaseList.indexOf(e.connectPhase) > 2 || !e.campaignId
        )
        .map((e) => e.expertId)
    );

    this.opportunityExpertsService.upsertMany(
      this.experts.map((e) => ({
        ...e,
        isSelected: filteredExpertIds.has(e.expertId) ? toggle : e.isSelected,
      }))
    );
  }

  private updateExperts(
    experts: IExpertUpdateRequest[],
    resetIsSelected: boolean = true
  ): Observable<IExpert[]> {
    return this.expertsUpdateService.updateMany(experts).pipe(
      map((res) => res.success),
      tap((updatedExperts) =>
        this.opportunityExpertsService.upsertMany(
          resetIsSelected
            ? updatedExperts.map((e) => ({ ...e, isSelected: false }))
            : updatedExperts
        )
      ),
      tap((updatedExperts) =>
        this.opportunityExpertsService.getSearches(
          [...new Set(updatedExperts.map((e) => e.searchId))],
          'screener'
        )
      ),
      catchError((err) => {
        console.log(err);
        return EMPTY;
      })
    );
  }

  private initialiseData(
    opportunity: IOpportunity,
    additionalSearchIds: string[] = []
  ): void {
    this.selectedSearches = [
      ...Object.keys(opportunity.searches),
      ...additionalSearchIds,
    ];
    this.title.setTitle(opportunity.opportunityName);

    this.opportunityExpertsService.setOptions({
      opportunityId: opportunity.opportunityId,
      searchIds: this.selectedSearches,
      fromPhase: 'outreach',
    });
  }
}
