import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ISearchDisplayExpert } from '../models/search-models';

@Component({
  selector: 'app-results-display',
  templateUrl: './results-display.component.html',
})
export class ResultsDisplayComponent implements OnChanges {
  @Input() experts: ISearchDisplayExpert[] = [];
  @Input() selectedCount = 0;
  @Input() totalCount = 0;
  @Input() selectAllToggle: boolean;
  @Input() showSelectionOptions: boolean;
  @Input() showCommercialView: boolean;
  @Input() showSelectOptions = true;
  @Output() filterTermSignal = new EventEmitter();
  @Output() toggleSelectSignal = new EventEmitter();
  @Output() toggleSelectAllSignal = new EventEmitter();
  @Output() downloadExpertListCsvSignal = new EventEmitter();
  @Output() sendExpertsDialogSignal = new EventEmitter();
  @Output() selectionConfigurationSignal = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;

  dataSource = new MatTableDataSource<ISearchDisplayExpert>([]);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.experts) {
      this.dataSource.paginator = this.matPaginator;
      this.dataSource.data = changes.experts.currentValue;
    }
  }

  public trackBy(idx: number, item: ISearchDisplayExpert): string | number {
    return item.id || idx;
  }

  public emitFilterTerm(event: any): void {
    this.filterTermSignal.emit(event.target.value);
  }

  public emitToggleSelect(id: any): void {
    this.toggleSelectSignal.emit(id);
  }

  public emitToggleSelectAll(): void {
    this.toggleSelectAllSignal.emit();
  }

  public emitDownloadExpertListCsv(): void {
    this.downloadExpertListCsvSignal.emit();
  }

  public emitSendExpertsDialog(): void {
    this.sendExpertsDialogSignal.emit();
  }

  public emitSelectionConfiguration(target: HTMLInputElement): void {
    this.selectionConfigurationSignal.emit(target.value);
  }
}
