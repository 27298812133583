import { ISearchQuery } from '../../../admin-portal/search/models/query';
import { IResponseFailItem } from '../../../shared/models/api';

export interface IFeasibilitySegmentResponse {
  opportunityId: string;
  segmentId: string;
  expertsShownToClient: number;
  expertsOutreached: number;
  completedConnections: number;
  responseRate: number;
  open: boolean;
  callDuration: number;
  geographicTarget: string;
  profileType: string;
  opportunityName: string;
  expertsResponded: number;
  committedConnections: number;
  searchTermsUsed: string[];
  countries: string[];
  searchIds: string[];
}

export interface IFeasibilityCreate extends Partial<IFeasibility> {
  salesforceId: string;
  feasibilityName: string;
  feasibility: {
    countries: string[];
    expertise: string[];
  };
  query: ISearchQuery;
}

export interface IFeasibility {
  feasibilityId: string;
  feasibilityName: string;
  feasibility: {
    countries: string[];
    expertise: string[];
  };
  salesforceId: string;
  query: ISearchQuery;
}

export interface IFeasibilityOppSegmentResponse
  extends IFeasibilitySegmentResponse {
  searchQueries: ISearchQuery[];
}

export interface ISearchExpandedTerms extends ISearchExpandedTermsCreate {
  searchExpandedTermId: string;
  dateCreated: number;
  lastUpdated: number;
}

export interface ISearchExpandedTermsCreate {
  term: string;
  foundExpandedTerms: string[];
  usedExpandedTerms: string[];
  opportunityId?: string;
  segmentId?: string;
}

export interface ISearchExpandedTermsReturn {
  success: ISearchExpandedTerms[];
  fail: IResponseFailItem<ISearchExpandedTermsCreate>[];
}
