import { IRecipient } from '../../models/recipients.model';

export const recipientMockData: IRecipient = {
  emails: [{ address: 'mike@techspert.io', type: 'standard' }],
  connectPhase: 'identified',
  id: '1',
  firstName: '',
  lastName: '',
  source: 'deep3',
  segment: 'Academic - Researcher',
};
