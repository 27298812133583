import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@techspert-io/users';

@Component({
  selector: 'app-update-emergency-contact',
  template: `<div
    class="container"
    *ngIf="users$ | async as users"
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <h1>Edit emergency contact</h1>
      <mat-icon [mat-dialog-close]>close</mat-icon>
    </div>

    <form
      [formGroup]="emergencyContactForm"
      (submit)="submit()"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <mat-form-field appearance="outline">
        <mat-label>Emergency contact</mat-label>
        <mat-select
          formControlName="contact"
          data-e2e="emergency-contact-input"
        >
          <mat-option
            *ngFor="let user of users"
            data-e2e="emergency-contact-input-list-item"
            [value]="user.connectId"
          >
            {{ user.firstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-button
        type="submit"
        color="primary"
        data-e2e="emergency-contact-submit-button"
        [disabled]="!emergencyContactForm.valid"
      >
        Submit
      </button>
    </form>
  </div>`,
  styles: [
    `
      .container {
        padding: 16px;
      }

      mat-icon {
        margin-left: auto;
        cursor: pointer;
      }

      mat-icon:hover {
        color: lightgrey;
      }
    `,
  ],
})
export class UpdateEmergencyContactComponent implements OnInit {
  emergencyContactForm = new FormGroup({
    contact: new FormControl(null, Validators.required),
  });

  users$ = this.userService.getAll({ userTypes: ['PM'] });

  constructor(
    @Inject(MAT_DIALOG_DATA) public emergencyContact: string,
    private dialogRef: MatDialogRef<UpdateEmergencyContactComponent>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.emergencyContactForm.setValue({
      contact: this.emergencyContact || '',
    });
  }

  submit(): void {
    this.dialogRef.close(this.emergencyContactForm.get('contact').value);
  }
}
