import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { IClient } from '../models/clients.models';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private readonly baseUrl = '/clients';
  private clientsCache: Observable<IClient[]>;
  private clientCache: Record<string, Observable<IClient>> = {};

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getAll(): Observable<IClient[]> {
    if (!this.clientsCache) {
      this.clientsCache = this.http.get<IClient[]>(this.baseUrl).pipe(
        map((clients) =>
          clients.sort((a, b) => a.clientName.localeCompare(b.clientName))
        ),
        shareReplay(1)
      );
    }
    return this.clientsCache;
  }

  get(clientId: string): Observable<IClient> {
    if (!this.clientCache[clientId]) {
      this.clientCache[clientId] = this.http
        .get<IClient>(`${this.baseUrl}/${clientId}`)
        .pipe(shareReplay(1));
    }
    return this.clientCache[clientId];
  }

  update(client: Partial<IClient>): Observable<IClient> {
    return this.http
      .patch<IClient>(`${this.baseUrl}/${client.clientId}`, client)
      .pipe(
        tap(() =>
          this.toastService.sendMessage('Client feature updated', 'success')
        )
      );
  }
}
