import { Component, Input, OnInit } from '@angular/core';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import {
  IOpportunity,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';

@Component({
  selector: 'app-pod-member-assigner',
  templateUrl: './pod-member-assigner.component.html',
  styleUrls: ['./pod-member-assigner.component.scss'],
})
export class PodMemberAssignerComponent implements OnInit {
  @Input() public opportunity: IOpportunity;
  public users: ConnectUser[];
  public filteredUsers: ConnectUser[] = [];
  public podMemberSelectorToggle = false;

  constructor(
    public cognitoAuthService: CognitoAuthService,
    private usersService: UserService,
    private opportunitiesService: OpportunitiesService
  ) {}

  public ngOnInit(): void {
    this.usersService.getAll({ userTypes: ['PM'] }).subscribe((data) => {
      this.users = data;
    });
  }

  public togglePodMemberSelector(): void {
    this.podMemberSelectorToggle = !this.podMemberSelectorToggle;
  }

  public filterUserList(target: HTMLInputElement): void {
    if (!target.value.trim().length) {
      this.filteredUsers = [];
      this.podMemberSelectorToggle = false;
      return;
    }

    const filterValue = new RegExp(target.value, 'i');
    this.filteredUsers = this.users.filter((user) =>
      filterValue.test(`${user.firstName} ${user.lastName}`)
    );
  }

  public assignToPodOnEnter(): void {
    if (this.filteredUsers[0]) {
      this.assignToPodOnClick(this.filteredUsers[0]);
    } else {
      this.filteredUsers = [];
      this.podMemberSelectorToggle = false;
    }
  }

  public assignToPodOnClick(user: ConnectUser): void {
    if (
      (this.opportunity.opportunityAssigneeIds || []).includes(user.connectId)
    ) {
      this.filteredUsers = [];
      this.podMemberSelectorToggle = false;
      return;
    }

    const payload = {
      opportunityId: this.opportunity.opportunityId,
      opportunityAssigneeIds: [
        ...(this.opportunity.opportunityAssigneeIds || []),
        user.connectId,
      ],
    };

    this.opportunitiesService.update(payload).subscribe((res) => {
      this.opportunity = res;

      this.filteredUsers = [];
      this.podMemberSelectorToggle = false;
    });
  }

  public removePodMember(connectId: string): void {
    const payload = {
      opportunityId: this.opportunity.opportunityId,
      opportunityAssigneeIds: this.opportunity.opportunityAssigneeIds.filter(
        (id) => id !== connectId
      ),
    };

    this.opportunitiesService.update(payload).subscribe((res) => {
      this.opportunity = res;
    });
  }

  public checkIfAlreadyAssigned(connectId: string): boolean {
    return (
      this.opportunity.opportunityAssigneeIds &&
      this.opportunity.opportunityAssigneeIds.includes(connectId)
    );
  }
}
