import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { CognitoAuthService } from '@techspert-io/auth';
import {
  ExpertCallActionService,
  IExpertCallAction,
  IExpertCallActionBase,
} from '@techspert-io/conferences';
import {
  EngagementsCalculationsService,
  EngagementsCreateDefaultsService,
  IEngagement,
  PaymentsService,
} from '@techspert-io/engagements';
import { ExpertActionStatusMap } from '@techspert-io/expert-actions';
import { IExpert } from '@techspert-io/experts';
import {
  IOpportunity,
  IOpportunityClientContact,
} from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import { UserService } from '@techspert-io/users';
import * as Moment from 'moment-timezone';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, EMPTY, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ExpertsUpdateService } from '../../../../features/experts/services/experts-update.service';
import { ConferenceService } from '../../../../shared/services/conference.service';
import { SchedulingCallTimerDirective } from '../../../directives/scheduling-call-timer.directive';
import { SchedulingModalTimerDirective } from '../../../directives/scheduling-modal-timer.directive';
import { Client } from '../../../models/client';
import { ITimezoneWithCountry } from '../../../models/country';
import { AppService } from '../../../services/app.service';
import { IEmailSentEvent } from '../email/email.component';
import {
  EmailType,
  IScheduleDialogClosePayload,
  IScheduleDialogInput,
} from '../schedule-models';

type RescheduleReasons =
  | 'Client request reschedule in advance'
  | 'Expert request reschedule in advance'
  | 'Expert no show - forgot'
  | 'Expert no show - conflict'
  | 'Client no show - forgot'
  | 'Client no show - conflict'
  | 'No reason given';

type CancellationReasons =
  | RescheduleReasons
  | 'Cancellation - client request'
  | 'Cancellation - expert request';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
})
export class ScheduleFormComponent implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  @Input() formInputData: IScheduleDialogInput;
  @Output() updatePayloadSignal =
    new EventEmitter<IScheduleDialogClosePayload>();

  callAction: IExpertCallActionBase;
  emailCallAction: IExpertCallActionBase;
  useInternalConferenceLink = false;

  showBtnLoader: boolean = false;
  tabToShow: string = 'expert';
  isEditingDate: boolean = false;
  expertCountrySupported: boolean = true;
  expertCountrySupportedChecked: boolean = false;
  clientCountrySupported: boolean = true;
  clientCountrySupportedChecked: boolean = false;
  detailsConfirmed: boolean = false;
  moment = Moment;
  primaryClientContact: IOpportunityClientContact =
    {} as IOpportunityClientContact;
  conferenceStepLabel: string = '';
  userTimezone: string;

  clientTimezoneName: string;

  //rescheduler global vars :
  oldDate: string;
  showRescheduleBtnLoader: boolean = false;
  showCancelBtnLoader: boolean = false;
  callCancellation: boolean = false;
  showRescheduleValidation: boolean = false;
  isExpertInvalid: boolean = false;
  reasonForReschedule: RescheduleReasons;
  reasonsForReschedule: RescheduleReasons[] = [
    'Client request reschedule in advance',
    'Expert request reschedule in advance',
    'Expert no show - forgot',
    'Expert no show - conflict',
    'Client no show - forgot',
    'Client no show - conflict',
    'No reason given',
  ];

  expert: IExpert;
  engagements: IEngagement[];
  client: Client;
  emailType: EmailType;
  opportunity: IOpportunity;
  hoursBeforeAutomatedReminderSend: number[];

  reasonForCancellation: CancellationReasons;
  reasonsForCancellation: CancellationReasons[] = [
    'Cancellation - client request',
    'Cancellation - expert request',
    ...this.reasonsForReschedule,
  ];

  @ViewChildren(SchedulingCallTimerDirective)
  timers: QueryList<SchedulingCallTimerDirective>;

  @ViewChild(SchedulingModalTimerDirective)
  modalTimer: SchedulingModalTimerDirective;

  emailSentToClient: boolean;
  emailSentToExpert: boolean;

  users$ = this.usersService.getAll({ userTypes: ['PM'] });

  constructor(
    public cognitoAuthService: CognitoAuthService,
    public conferenceService: ConferenceService,
    public paymentsService: PaymentsService,
    public toastService: ToastService,
    public engagementsCalculationsService: EngagementsCalculationsService,
    public expertsUpdateService: ExpertsUpdateService,
    public appService: AppService,
    private usersService: UserService,
    private callActionService: ExpertCallActionService,
    private cdr: ChangeDetectorRef,
    private engagementsCreateDefaultsService: EngagementsCreateDefaultsService,
    private gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.expert = this.formInputData.expert;
    this.client = this.formInputData.client;
    this.opportunity = this.formInputData.opportunity;

    this.hoursBeforeAutomatedReminderSend =
      this.opportunity.conferenceReminderDefaultSettings.sendEmailBeforeSeconds.map(
        (seconds) => seconds / 3600
      );
    this.userTimezone = this.moment().tz(this.moment.tz.guess()).zoneAbbr();

    this.setupEngagements();
    this.setupPrimaryClientContact();
    this.setupConferenceFormData();
    this.setupConferenceData();
  }

  ngAfterViewInit(): void {
    if (['updateEmail'].includes(this.formInputData.action)) {
      this.callAction = this.formInputData.callAction;
      this.emailCallAction = { ...this.callAction };
      this.emailType =
        this.emailCallAction.status === 'cancelled' ? 'cancel' : 'new';
      this.conferenceStepLabel = 'Disabled';

      this.goToEmailStep();
      this.cdr.detectChanges();
    }
  }

  ascertainPrimaryClientContact(
    contactArray: IOpportunityClientContact[]
  ): IOpportunityClientContact {
    const primaryContact = contactArray.find((contact) => contact.primary);
    return JSON.parse(JSON.stringify(primaryContact || {}));
  }

  onEmailSent({ primaryRecipient }: IEmailSentEvent): void {
    if (primaryRecipient === 'client') {
      this.emailSentToClient = true;
    } else if (primaryRecipient === 'expert') {
      this.emailSentToExpert = true;
    }

    if (
      (this.callAction.blind &&
        this.emailSentToExpert &&
        this.emailSentToClient) ||
      (!this.callAction.blind && this.emailSentToExpert)
    ) {
      this.timers.get(1).sendTimerEvent();
      this.modalTimer.setEmailSent();
    }
  }

  private initialiseSchedule(
    request?: IExpertCallActionBase
  ): IExpertCallActionBase {
    return {
      expertActionId: request?.expertActionId || undefined,
      clientId: request?.clientId || this.opportunity.clientId,
      opportunityId: request?.opportunityId || this.opportunity.opportunityId,
      expertId: request?.expertId || this.expert.expertId,
      ownerUserId:
        request?.ownerUserId || this.cognitoAuthService.loggedInUser.connectId,
      actionType: 'call',
      status: ExpertActionStatusMap.Pending,
      callType: request?.callType || ('zoom-techspert' as const),
      datetime: request?.datetime || this.expert.callTime || '',
      duration: request?.duration || this.opportunity.anticipatedCallTime || 60,
      internalName:
        request?.internalName ||
        `${this.opportunity.opportunityName} & ${this.expert.firstName} ${this.expert.lastName}`,
      externalName:
        request?.externalName || this.opportunity.defaultConferenceTopic,
      blind: !!this.opportunity.blind,
      isRecordingOn:
        !!this.opportunity.callRecording && !!this.expert.recordingAuthorised,
      emergencyContact:
        request?.emergencyContact ||
        this.cognitoAuthService.loggedInUser.connectId,
    };
  }

  assignDate(datetime: string): void {
    this.callAction.datetime = datetime;
    this.isEditingDate = false;
  }

  updateClientTimezoneAndCountry(
    timezoneAndCountry: ITimezoneWithCountry
  ): void {
    this.clientTimezoneName = timezoneAndCountry.timezone;
    this.primaryClientContact.country = timezoneAndCountry.country;
    this.clientCountrySupportedChecked = false;
    if (timezoneAndCountry.country) {
      this.conferenceService
        .checkCountryIsSupported(this.primaryClientContact.country)
        .subscribe((data) => {
          this.clientCountrySupportedChecked = true;
          if (data.support) {
            this.clientCountrySupported = true;
          } else {
            this.clientCountrySupported = false;
          }
        });
    } else {
      this.clientCountrySupported = false;
    }
  }

  updateExpertTimezoneAndCountry(timezoneAndCountry: {
    country: string;
    timezone: string;
  }): void {
    this.expert.timezoneName = timezoneAndCountry.timezone;
    this.expert.country = timezoneAndCountry.country;
    this.expertCountrySupportedChecked = false;
    if (timezoneAndCountry.country) {
      this.conferenceService
        .checkCountryIsSupported(this.expert.country)
        .subscribe((data) => {
          this.expertCountrySupportedChecked = true;
          if (data.support) {
            this.expertCountrySupported = true;
          } else {
            this.expertCountrySupported = false;
          }
        });
    } else {
      this.expertCountrySupported = false;
    }
  }

  openTab(tabName: string): void {
    this.tabToShow = tabName;
  }

  toggleIsEditingData(): void {
    this.isEditingDate = !this.isEditingDate;
  }

  runValidationChecks(): boolean {
    if (
      !this.callAction.datetime ||
      !this.moment(this.callAction.datetime, true).isValid()
    ) {
      this.toastService.sendMessage(
        `Missing or invalid date/time for call.`,
        'error'
      );
      return false;
    } else if (!this.callAction.duration || this.callAction.duration === 0) {
      this.toastService.sendMessage(
        `Please provide a call duration greater than zero.`,
        'error'
      );
      return false;
    } else if (!this.expert.timezoneName) {
      this.toastService.sendMessage(
        `Please select the country and timezone of the expert.`,
        'error'
      );
      return false;
    } else if (!this.clientTimezoneName) {
      this.toastService.sendMessage(
        `Please select the country and timezone of the client.`,
        'error'
      );
      return false;
    } else if (!this.callAction.externalName) {
      this.toastService.sendMessage(`Please provide a topic.`, 'error');
      return false;
    } else if (!this.primaryClientContact.firstName) {
      this.toastService.sendMessage(
        `Please provide a name for the client contact.`,
        'error'
      );
      return false;
    } else {
      return true;
    }
  }

  save(): void {
    if (this.runValidationChecks()) {
      this.showBtnLoader = true;

      if (this.callAction.expertActionId) {
        this.updateAction('new', ExpertActionStatusMap.Pending);
      } else {
        this.createAction();
      }
    }
  }

  saveReschedule(): void {
    this.showRescheduleValidation = true;

    if (!this.reasonForReschedule) {
      this.toastService.sendMessage(
        'Please provide a reason for rescheduling.',
        'error'
      );
    } else if (this.runValidationChecks()) {
      this.showRescheduleBtnLoader = true;

      this.updateAction('update', ExpertActionStatusMap.Pending);

      this.gaService.gtag('event', 'click', {
        event_category: 'conference_reschedule',
        userId: this.cognitoAuthService.loggedInUser.connectId,
        callActionId: this.callAction.expertActionId,
        reason: this.reasonForReschedule,
      });
    }
  }

  cancelCall(): void {
    this.showRescheduleValidation = false;
    if (!this.callAction.duration || this.callAction.duration === 0) {
      this.toastService.sendMessage(
        `Please provide a call duration greater than zero.`,
        'error'
      );
    } else if (!this.callAction.externalName) {
      this.toastService.sendMessage(`Please provide a call topic.`, 'error');
    } else if (!this.primaryClientContact.firstName) {
      this.toastService.sendMessage(
        `Please provide a name for the client contact.`,
        'error'
      );
    } else if (!this.reasonForCancellation) {
      this.toastService.sendMessage(
        'Please provide a reason for cancellation.',
        'error'
      );
    } else {
      this.showCancelBtnLoader = true;
      this.callActionService
        .cancel(this.callAction.expertActionId)
        .pipe(
          switchMap(
            (
              callAction
            ): Observable<{ callAction: IExpertCallAction; expert: IExpert }> =>
              this.expertsUpdateService
                .updateOne({
                  expertId: this.expert.expertId,
                  connectPhase: 'sentToClient',
                  callTime: null,
                  conferences: [],
                })
                .pipe(map((expert) => ({ callAction, expert })))
          ),
          tap(({ callAction, expert }) => {
            this.gaService.gtag('event', 'click', {
              event_category: 'conference_cancellation',
              userId: this.cognitoAuthService.loggedInUser.connectId,
              callActionId: this.callAction.expertActionId,
              reason: this.reasonForCancellation,
            });

            this.sendCancelEvent(expert, callAction);
            this.timers.get(0).sendTimerEvent();
            this.modalTimer.setCallUpdated();
          }),
          catchError((err) => {
            this.showRescheduleBtnLoader = false;
            this.toastService.sendMessage(
              err?.error?.message || err?.message || err || 'Unknown error',
              'error'
            );
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  private sendCancelEvent(
    updatedExpert: IExpert,
    updatedCallAction: IExpertCallAction
  ): void {
    this.emailCallAction = {
      ...updatedCallAction,
      datetime: this.oldDate,
      joinMetadata: {
        ...updatedCallAction.joinMetadata,
        dialIns: [
          {
            country: this.expert.country,
            number: '',
          },
          {
            country: this.primaryClientContact.country,
            number: '',
          },
        ],
      },
    };

    this.emailType = 'cancel';
    this.callCancellation = true;
    this.detailsConfirmed = true;
    this.showCancelBtnLoader = false;

    this.goToEmailStep();

    this.updatePayloadSignal.emit({
      expert: updatedExpert,
      callAction: updatedCallAction,
      engagement: this.getMatchedEngagement(this.oldDate),
    });
  }

  private createOrUpdateEngagement(
    engagement?: IEngagement
  ): Observable<IEngagement> {
    const updatePayload = {
      dateOfEngagement: new Date(this.callAction.datetime).toISOString(),
      quantityEngaged: this.callAction.duration / 60,
      unitsUsed: this.engagementsCalculationsService.calculateUnitsUsed({
        quantityEngaged: this.callAction.duration / 60,
        engagementType: 'call',
      } as IEngagement),
      amountOwed: this.engagementsCalculationsService.calculateOwed({
        quantityEngaged: this.callAction.duration / 60,
        engagementType: 'call',
        rate: engagement ? engagement.rate : 0,
      } as IEngagement),
    };
    if (engagement) {
      return this.paymentsService.update(updatePayload);
    } else {
      const sentToClientPhase = (this.expert.phaseTimestamps || []).find(
        (element) => element.phase === 'sentToClient'
      );

      const createPayload = {
        ...this.engagementsCreateDefaultsService.createEngagement(
          { engagementType: 'call' },
          this.opportunity,
          this.expert,
          this.appService.normaliseUnixTimestampToMilliseconds(
            sentToClientPhase?.timestamp
          )
        ),
        ...updatePayload,
      };

      return this.paymentsService.create(createPayload);
    }
  }

  private getMatchedEngagement(callDateTime: Date | string): IEngagement {
    if (this.engagements) {
      const callDate = new Date(callDateTime).toISOString().split('T')[0];
      return this.engagements.find(
        (e) =>
          e.paymentStatus === 'not-paid' &&
          e.engagementType === 'call' &&
          e.dateOfEngagement &&
          new Date(e.dateOfEngagement).toISOString().split('T')[0] === callDate
      );
    }
    return null;
  }

  private goToEmailStep(): void {
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  private handleSave(
    ca: IExpertCallAction,
    expert: IExpert,
    engagement: IEngagement,
    type: EmailType
  ): void {
    this.showBtnLoader = false;
    this.showRescheduleBtnLoader = false;
    if (ca && ca.expertActionId) {
      this.detailsConfirmed = true;
    }
    const sortedDialInsAction = {
      ...ca,
      ...(ca.joinMetadata
        ? {
            joinMetadata: {
              ...ca.joinMetadata,
              dialIns: ca.joinMetadata.dialIns.sort((a) =>
                this.expert.country === a.country ? -1 : 1
              ),
            },
          }
        : {}),
    };
    this.emailCallAction = sortedDialInsAction;
    this.emailType = type;
    this.updatePayloadSignal.emit({
      expert: expert,
      callAction: sortedDialInsAction,
      engagement: engagement,
    });
  }

  private updateExpertAndEngagements(
    type: EmailType
  ): OperatorFunction<IExpertCallAction, unknown> {
    return (source): Observable<unknown> =>
      source.pipe(
        switchMap((ca) =>
          combineLatest([
            of(ca),
            this.expertsUpdateService.updateOne({
              expertId: this.expert.expertId,
              connectPhase: 'scheduled',
              callTime: ca.datetime,
              conferences: [ca.expertActionId],
            }),
            this.createOrUpdateEngagement(
              this.getMatchedEngagement(
                type === 'new' ? this.callAction.datetime : this.oldDate
              )
            ),
          ])
        ),
        tap(([callAction, expert, engagement]) =>
          this.handleSave(callAction, expert, engagement, type)
        ),
        tap(() => {
          this.timers.get(0).sendTimerEvent();
          this.modalTimer.setCallUpdated();
          this.goToEmailStep();
        }),
        catchError((err) => {
          this.showBtnLoader = false;
          this.showRescheduleBtnLoader = false;
          this.toastService.sendMessage(
            err?.error?.message || err?.message || err || 'Unknown error',
            'error'
          );
          return EMPTY;
        })
      );
  }

  private formatCallAction(): void {
    this.callAction.joinMetadata = {
      ...(this.callAction.joinMetadata || {}),
      dialIns: [
        {
          country: this.expert.country,
          number: '',
        },
        {
          country: this.primaryClientContact.country,
          number: '',
        },
      ],
    };
    this.callAction.datetime = new Date(this.callAction.datetime).toISOString();
  }

  private setupEngagements(): void {
    this.paymentsService
      .query({
        expertId: this.expert.expertId,
      })
      .subscribe((data) => {
        this.engagements = data;
      });
  }

  private setupConferenceData(): void {
    switch (this.formInputData.action) {
      case 'schedule':
        this.callAction = this.initialiseSchedule(
          this.formInputData.callAction
        );
        this.conferenceStepLabel = 'Schedule Conference';
        break;
      case 'reschedule':
        this.oldDate = this.formInputData.callAction.datetime;
        this.callAction = { ...this.formInputData.callAction, datetime: null };
        this.conferenceStepLabel = 'Reschedule Conference';
        break;
      case 'cancel':
        this.oldDate = this.formInputData.callAction.datetime;
        this.callAction = this.formInputData.callAction;
        this.conferenceStepLabel = 'Cancel Conference';
        break;
    }
  }

  private setupConferenceFormData(): void {
    if (this.expert?.country) {
      this.updateExpertTimezoneAndCountry({
        country: this.expert.country,
        timezone: this.expert.timezoneName,
      });
    }

    if (this.primaryClientContact?.country) {
      this.updateClientTimezoneAndCountry({
        country: this.primaryClientContact.country,
        timezone: this.primaryClientContact.timezone.name,
      });
    }
  }

  private setupPrimaryClientContact(): void {
    if (this.opportunity.clientContacts?.length) {
      this.primaryClientContact = this.ascertainPrimaryClientContact(
        this.opportunity.clientContacts
      );
      this.clientTimezoneName = this.primaryClientContact.timezone.name;
    }
  }

  private createAction(): void {
    this.formatCallAction();

    this.callActionService
      .create({
        ownerUserId: this.callAction.ownerUserId,
        clientId: this.callAction.clientId,
        opportunityId: this.callAction.opportunityId,
        expertId: this.callAction.expertId,
        callType: this.callAction.callType,
        blind: this.callAction.blind,
        datetime: this.callAction.datetime,
        duration: this.callAction.duration,
        externalName: this.callAction.externalName,
        internalName: this.callAction.internalName,
        isRecordingOn: this.callAction.isRecordingOn,
        dialInCountries: [
          ...new Set([this.expert.country, this.primaryClientContact.country]),
        ],
        emergencyContact: this.callAction.emergencyContact,
        organizer: {
          email: this.cognitoAuthService.loggedInUser.email,
          mailto: this.cognitoAuthService.loggedInUser.email,
          name: `${this.cognitoAuthService.loggedInUser.firstName} ${this.cognitoAuthService.loggedInUser.lastName}`,
        },
      })
      .pipe(this.updateExpertAndEngagements('new'))
      .subscribe();
  }

  private updateAction(type: EmailType, status?: ExpertActionStatusMap): void {
    this.showRescheduleValidation = true;
    this.callCancellation = false;
    this.formatCallAction();

    this.callActionService
      .update({
        expertActionId: this.callAction.expertActionId,
        callType: this.callAction.callType,
        blind: this.callAction.blind,
        datetime: this.callAction.datetime,
        duration: this.callAction.duration,
        externalName: this.callAction.externalName,
        internalName: this.callAction.internalName,
        isRecordingOn: this.callAction.isRecordingOn,
        status: status || this.callAction.status,
        dialInCountries: [
          ...new Set([this.expert.country, this.primaryClientContact.country]),
        ],
        emergencyContact: this.callAction.emergencyContact,
        ...(type === 'new'
          ? {
              organizer: {
                email: this.cognitoAuthService.loggedInUser.email,
                mailto: this.cognitoAuthService.loggedInUser.email,
                name: `${this.cognitoAuthService.loggedInUser.firstName} ${this.cognitoAuthService.loggedInUser.lastName}`,
              },
            }
          : {}),
      })
      .pipe(this.updateExpertAndEngagements(type))
      .subscribe();
  }
}
