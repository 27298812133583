import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IOmnisearchExpert,
  OmnisearchQueryResponse,
} from '../models/omnisearch.models';

@Injectable({
  providedIn: 'root',
})
export class OmnisearchService {
  private readonly baseUrl = '/omnisearch';
  constructor(private http: HttpClient) {}

  search(query: string): Observable<OmnisearchQueryResponse[]> {
    if (query.length <= 2) {
      return of([]);
    }

    const params = new HttpParams().set('query', query);
    return this.http.get<OmnisearchQueryResponse[]>(`${this.baseUrl}/search`, {
      params,
    });
  }

  getPreviousEngagements(query: {
    emailAddress?: string;
    searchExpertId?: string;
  }): Observable<IOmnisearchExpert[]> {
    if (!query.emailAddress && !query.searchExpertId) {
      return of([]);
    }

    const params = Object.entries(query).reduce((prev, [key, value]) => {
      if (key) {
        return prev.append(key, value);
      }
      return prev;
    }, new HttpParams());

    return this.http.get<IOmnisearchExpert[]>(
      `${this.baseUrl}/previousExperts`,
      { params }
    );
  }
}
