import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CognitoAuthService } from '@techspert-io/auth';
import { FileStoreService } from '@techspert-io/file-store';
import { ToastService } from '@techspert-io/user-alerts';
import { NgxCsvParser } from 'ngx-csv-parser';
import { finalize } from 'rxjs/operators';
import { AppService } from '../../../shared/services/app.service';
import {
  CountryService,
  ICountry,
} from '../../../shared/services/country.service';
import {
  ICommercialCondition,
  ICommercialSearchQuery,
  ICommercialSearchService,
  ICondition,
  IField,
  IHistorialSearchQuery,
  IHistoricalCondition,
  ILinkedInEnrichmentQuery,
  ISearchCombinedQuery,
  ISearchCondition,
  ISearchQuery,
  SearchServiceType,
} from '../models/query';
import { SearchService } from '../services/search.service';
import commercialOptions from './commercial-options';

@Component({
  selector: 'app-query-creator',
  templateUrl: './query-creator.component.html',
  styleUrls: ['./query-creator.component.scss'],
})
export class QueryCreatorComponent implements AfterViewInit, OnChanges {
  @ViewChild('UploadFileInput') uploadFileInputRef: ElementRef;
  @ViewChild('UploadFileInputSearch') uploadFileInputSearchRef: ElementRef;
  @ViewChild('FileInput') uploadCsvInputRef: ElementRef;
  @Output() public payloadSignal = new EventEmitter<
    | ICommercialSearchQuery
    | ISearchQuery
    | IHistorialSearchQuery
    | ILinkedInEnrichmentQuery
    | ISearchCombinedQuery
  >();
  @Output() public serviceChangeSignal = new EventEmitter();
  @Input() public showSearchLoader: boolean;
  @Input() public initQuery: ISearchQuery;
  @Input() public expertise: string[];
  @Input() public hideServiceAndCount = false;
  @Input() public services: SearchServiceType[] = [
    'cognisearch',
    'historical',
    'deep3',
    'deep-next',
    'pdl-commercial',
    'pdl-enrichment',
    'combined-search',
  ];
  public service: SearchServiceType;
  public commercialUploadService: ICommercialSearchService = 'linkedin_urls';
  public commercialUploadServices: ICommercialSearchService[] = [
    'linkedin_urls',
    'npi_numbers',
  ];
  public field: IField;
  public fields: IField[] = [];
  public conditions: ICondition[] = [];
  public expertTargetNumber: number;
  public countries: ICountry[] = [];
  public selectedCountries: string[] = [];
  public doctorsOnly: boolean = false;
  public emailOnly: boolean = true;
  public includePastExperiences: boolean = false;
  public disable: boolean = false;
  public commercialServiceAttributes = [];
  public commercialOptions: Record<string, string[]>;
  public commercialQuery: ICommercialSearchQuery;
  public commercialQueryHasRun: boolean;
  public maxPDLQueryExperts = 1000;
  public fileUploadLoading: boolean;
  private clipboard: Clipboard = navigator.clipboard;

  constructor(
    public toastService: ToastService,
    public countryService: CountryService,
    public appService: AppService,
    private searchService: SearchService,
    private ngxCsvParser: NgxCsvParser,
    private cdref: ChangeDetectorRef,
    private fileStore: FileStoreService,
    private cognitoAuthService: CognitoAuthService
  ) {
    this.commercialOptions = commercialOptions;
  }

  ngAfterViewInit(): void {
    this.countries = this.countryService.countryList;
    this.reset();
    this.assignSearchFields(this.service);

    this.initQuery && this.deconstructUploadedSearch(this.initQuery);
    this.cdref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initQuery?.currentValue) {
      this.deconstructUploadedSearch(changes.initQuery.currentValue);
    }

    if (changes.expertise?.currentValue.length) {
      if (!(this.conditions || []).some((c) => c.fieldValue === 'expertise')) {
        this.conditions = [
          ...this.conditions,
          {
            id: this.appService.createUUID(),
            terms: changes.expertise.currentValue,
            fieldValue: 'expertise',
            fieldDisplay: 'Expertise',
            operator: 'or',
          },
        ];
      } else {
        const idx = this.conditions.findIndex(
          (c) => c.fieldValue === 'expertise'
        );

        this.conditions[idx].terms = [
          ...new Set([
            ...this.conditions[idx].terms,
            ...changes.expertise.currentValue,
          ]),
        ];
      }
    }
  }

  public updateServiceAndIncompatibleFields(
    newService: SearchServiceType
  ): void {
    if (this.inHouseToCommercialChange(newService)) {
      this.assignCommercialSearchFields();
      this.conditions = [];
      this.searchService.resetKnowledgeGraphCache();
    }
    if (this.commercialToInHouseChange(newService)) {
      this.assignSearchFields(newService);
      this.conditions = [];
      this.searchService.resetKnowledgeGraphCache();
    }
    if (this.otherToCognisearchChange(newService)) {
      this.assignSearchFields(newService);
    }
    if (this.congiSearchToOtherChange(newService)) {
      this.assignSearchFields(newService);
    }
    this.service = newService;
    this.commercialQuery = null;
    this.serviceChangeSignal.emit();
  }

  public reset(): void {
    this.service = 'historical';
    this.disable = false;
  }

  public triggerUploadCsvToSearch(): void {
    this.uploadFileInputSearchRef.nativeElement.click();
  }

  public triggerUploadSearch(): void {
    this.uploadFileInputRef.nativeElement.click();
  }

  public triggerUploadCSV(): void {
    this.uploadCsvInputRef.nativeElement.click();
  }

  public isHiddenCondition(condition: ICondition): boolean {
    return condition.operator === 'exclude' && this.service !== 'cognisearch';
  }

  public addCondition(): void {
    this.conditions.unshift({
      id: this.appService.createUUID(),
      terms: [],
      ...this.field,
    });
  }

  public removeCondition(id: string): void {
    if (!this.disable) {
      this.conditions = this.conditions.filter(
        (conditionObj) => conditionObj.id !== id
      );
    }
  }

  public addConditionTerm(id: string, term: string): void {
    this.conditions = this.conditions.map((condition) => {
      if (condition.id === id) {
        condition.terms = [...condition.terms, term.toLowerCase().trim()];
      }
      return condition;
    });
  }

  public removeConditionTerm(id: string, removalTerm: string): void {
    this.conditions = this.conditions.map((condition) => {
      if (condition.id === id) {
        condition.terms = condition.terms.filter(
          (term) => term !== removalTerm
        );
      }
      return condition;
    });
  }

  public copy(condition: ICondition): void {
    const terms = condition.terms.join('| ');
    this.clipboard.writeText(terms).then(() => {
      this.toastService.sendMessage(
        'Successfully copied search terms to clipboard',
        'success'
      );
    });
  }

  public onListChange(event: string[], id: string): void {
    this.conditions = this.conditions.map((condition) => {
      if (condition.id === id) {
        condition.terms = event;
      }
      return condition;
    });
  }

  public assignSelectedCountry(country: ICountry): void {
    this.selectedCountries = [...this.selectedCountries, country.abbreviation];
  }

  public removeCountry(removalAlpha2Code: string): void {
    this.selectedCountries = this.selectedCountries.filter(
      (alpha2Code) => alpha2Code !== removalAlpha2Code
    );
  }

  public toggleIncludePastExperiences(): void {
    if (!this.disable) {
      this.includePastExperiences = !this.includePastExperiences;
    }
  }

  public sendCsvToSearch(target: HTMLInputElement): void {
    this.fileUploadLoading = true;

    this.fileStore
      .uploadFiles(
        Array.from(target.files),
        'commercial-upload',
        this.cognitoAuthService.loggedInUser.connectId
      )
      .pipe(
        finalize(() => {
          this.toastService.sendMessage(
            'Thank you for helping us collect data',
            'success'
          );
          this.fileUploadLoading = false;
        })
      )
      .subscribe();
  }

  public uploadSearch(target: HTMLInputElement): void {
    const file = target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (): void => {
      const result = JSON.parse(reader.result as string);
      if (result.service === 'pdl-commercial') {
        this.deconstructUploadedCommercialSearch(result);
      } else {
        this.deconstructUploadedSearch(result);
      }
    };
  }

  public parseCsv(target: HTMLInputElement): void {
    this.ngxCsvParser
      .parse(target.files[0], { header: false, delimiter: ',' })
      .subscribe(
        (result: string[][]) => {
          const filteredAttributes =
            this.commercialUploadService === 'linkedin_urls'
              ? result
                  .flat()
                  .filter(Boolean)
                  .filter(
                    (e) =>
                      e.startsWith('https://www.linkedin.com/in') ||
                      e.startsWith('https://uk.linkedin.com/in/') ||
                      e.startsWith('https://www.linkedin.com/sales')
                  )
                  .map((e) =>
                    e.replace(
                      'https://uk.linkedin.com/in/',
                      'https://linkedin.com/in/'
                    )
                  )
              : result.flat().filter((e) => !isNaN(+e));
          this.commercialServiceAttributes = [...new Set(filteredAttributes)];

          if (!this.commercialServiceAttributes.length) {
            this.toastService.sendMessage('0 valid expert attributes', 'error');
          } else if (this.commercialServiceAttributes.length <= 5000) {
            this.submitQuery();
          } else {
            this.toastService.sendMessage(
              'You can only insert 5000 attributes per upload',
              'error'
            );
          }
        },
        (error) =>
          error.message
            ? this.toastService.sendMessage(error.message, 'error')
            : null
      );
  }

  public exportSearch(): void {
    const date = new Date().toISOString().slice(0, 10);
    if (this.conditions.length > 0) {
      let exportPayload:
        | ISearchQuery
        | ICommercialSearchQuery
        | ISearchCombinedQuery;
      if (this.service === 'pdl-commercial') {
        exportPayload = this.createCommercialSearchPayload(this.service);
      } else if (this.service === 'combined-search') {
        exportPayload = this.createCombinedSearchPayload();
      } else if (this.service !== 'pdl-enrichment') {
        exportPayload = this.createSearchPayload(this.service);
      }
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(exportPayload)
      )}`;
      const dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', `search-query-${date}.json`);
      dlAnchorElem.click();
    }
  }

  public isSearchValid(): boolean {
    return (
      this.expertTargetNumber > 0 &&
      this.expertTargetNumber <= 10000 &&
      this.countries &&
      this.conditions.length &&
      this.conditions.every((c) => c.terms.length)
    );
  }

  public deconstructUploadedSearch(query: ISearchQuery): void {
    this.service = query.service;
    this.assignSearchFields(this.service);
    this.expertTargetNumber = query.count;
    this.selectedCountries = query.countries || [];
    this.conditions = this.deconstructQueryConditions(query.conditions || []);
  }

  public deconstructUploadedCommercialSearch(
    query: ICommercialSearchQuery
  ): void {
    this.service = query.service;
    this.assignCommercialSearchFields();
    this.expertTargetNumber = query.size;
    this.includePastExperiences = query.include_past_experiences;
    this.selectedCountries = query.countries || [];
    this.conditions = this.deconstructQueryConditions(query.conditions || []);
  }

  public submitQuery(): void {
    if (this.service === 'pdl-enrichment') {
      this.payloadSignal.emit({
        service: 'pdl-enrichment',
        attributes: this.commercialServiceAttributes,
        pdlEnrichmentService: this.commercialUploadService,
      });
    } else if (this.service === 'pdl-commercial') {
      const payload = this.createCommercialSearchPayload(this.service);
      if (this.payloadValidator(payload)) {
        this.payloadSignal.emit(payload);
        this.commercialQuery = payload;
        this.commercialQueryHasRun = true;
        this.disable = true;
      }
    } else if (this.service === 'historical') {
      const payload = this.createHistoricalSearchPayload();
      if (this.payloadValidator(payload)) this.payloadSignal.emit(payload);
    } else if (this.service === 'cognisearch') {
      const payload = this.createCogniSearchPayload();
      if (this.payloadValidator(payload)) this.payloadSignal.emit(payload);
    } else if (this.service === 'combined-search') {
      const payload = this.createCombinedSearchPayload();
      if (this.payloadValidator(payload)) this.payloadSignal.emit(payload);
    } else {
      const payload = this.createSearchPayload(this.service);
      if (this.payloadValidator(payload)) this.payloadSignal.emit(payload);
    }
  }

  private assignCommercialSearchFields(): void {
    const fields: IField[] = [
      { fieldValue: 'position', fieldDisplay: 'Position', operator: 'or' },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Past & Present affiliation',
        level: 'all',
        operator: 'or',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Current affiliation',
        level: 'current',
        operator: 'or',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Past affiliation',
        level: 'past',
        operator: 'or',
      },
      {
        fieldValue: 'experience_level',
        fieldDisplay: 'Exp Level',
        operator: 'or',
      },
      { fieldValue: 'expertise', fieldDisplay: 'Expertise', operator: 'or' },
      {
        fieldValue: 'industry',
        fieldDisplay: 'Industry',
        operator: 'or',
      },
    ];

    const avoidFields: IField[] = [
      {
        fieldValue: 'position',
        fieldDisplay: 'Deprioritise position',
        operator: 'not',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Deprioritise past & present affiliation',
        level: 'all',
        operator: 'not',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Deprioritise current affiliation',
        level: 'current',
        operator: 'not',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Deprioritise past affiliation',
        level: 'past',
        operator: 'not',
      },
      {
        fieldValue: 'experience_level',
        fieldDisplay: 'Deprioritise exp level',
        operator: 'not',
      },
      {
        fieldValue: 'expertise',
        fieldDisplay: 'Deprioritise expertise',
        operator: 'not',
      },
      {
        fieldValue: 'industry',
        fieldDisplay: 'Deprioritise industry',
        operator: 'not',
      },
    ];
    this.fields = [...fields, ...avoidFields];
    this.expertTargetNumber = 100;
    this.field = this.fields[0];
  }

  private assignSearchFields(service: SearchServiceType): void {
    const fields: IField[] = [
      { fieldValue: 'expertise', fieldDisplay: 'Expertise', operator: 'or' },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Affiliation',
        operator: 'or',
      },
      { fieldValue: 'role', fieldDisplay: 'Role', operator: 'or' },
      { fieldValue: 'name', fieldDisplay: 'Name', operator: 'or' },
    ];

    const deprioritiseFields: IField[] = [
      {
        fieldValue: 'expertise',
        fieldDisplay: 'Deprioritise expertise',
        operator: 'not',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Deprioritise affiliation',
        operator: 'not',
      },
      {
        fieldValue: 'role',
        fieldDisplay: 'Deprioritise role',
        operator: 'not',
      },
      {
        fieldValue: 'name',
        fieldDisplay: 'Deprioritise name',
        operator: 'not',
      },
    ];

    const excludeFields: IField[] = [
      {
        fieldValue: 'expertise',
        fieldDisplay: 'Exclude expertise',
        operator: 'exclude',
      },
      {
        fieldValue: 'affiliation',
        fieldDisplay: 'Exclude affiliation',
        operator: 'exclude',
      },
      {
        fieldValue: 'role',
        fieldDisplay: 'Exclude role',
        operator: 'exclude',
      },
    ];

    const deprioritiseFieldsFiltered =
      service === 'cognisearch'
        ? deprioritiseFields.filter((f) => f.fieldValue !== 'name')
        : deprioritiseFields;

    this.fields = [
      ...fields,
      ...deprioritiseFieldsFiltered,
      ...(service === 'cognisearch' ? excludeFields : []),
    ];

    this.field = this.fields[0];
  }

  private inHouseToCommercialChange(newService: SearchServiceType): boolean {
    return (
      ['pdl-commercial', 'pdl-enrichment'].includes(newService) &&
      [
        'deep-next',
        'deep3',
        'historical',
        'cognisearch',
        'combined-search',
      ].includes(this.service)
    );
  }

  private commercialToInHouseChange(newService: SearchServiceType): boolean {
    return (
      [
        'deep-next',
        'deep3',
        'historical',
        'cognisearch',
        'combined-search',
      ].includes(newService) &&
      ['pdl-commercial', 'pdl-enrichment'].includes(this.service)
    );
  }

  private otherToCognisearchChange(newService: SearchServiceType): boolean {
    return (
      ['cognisearch'].includes(newService) &&
      [
        'deep-next',
        'deep3',
        'historical',
        'cognisearch',
        'combined-search',
      ].includes(this.service)
    );
  }

  private congiSearchToOtherChange(newService: SearchServiceType): boolean {
    return (
      [
        'deep-next',
        'deep3',
        'historical',
        'cognisearch',
        'combined-search',
      ].includes(newService) && ['cognisearch'].includes(this.service)
    );
  }

  private deconstructQueryConditions(
    conditions: (ISearchCondition | ICommercialCondition)[]
  ): ICondition[] {
    return conditions.map((condition) => {
      if (condition.operator === 'not') {
        const field = this.fields.find(
          (field) =>
            field.fieldValue === condition.field && field.operator === 'not'
        );
        return {
          id: this.appService.createUUID(),
          terms: condition.terms,
          ...field,
        };
      }
      if (condition.operator === 'exclude') {
        const field = this.fields.find(
          (field) =>
            field.fieldValue === condition.field && field.operator === 'exclude'
        );
        return {
          id: this.appService.createUUID(),
          terms: condition.terms,
          ...field,
        };
      }
      const field = this.fields.find(
        (field) =>
          field.fieldValue === condition.field && field.operator === 'or'
      );
      return {
        id: this.appService.createUUID(),
        terms: condition.terms,
        ...field,
      };
    });
  }

  private payloadValidator(
    payload: ISearchQuery | ICommercialSearchQuery | IHistorialSearchQuery
  ): boolean {
    if (this.hasNoTerms(payload.conditions)) {
      this.toastService.sendMessage('No terms have been specified', 'error');
      return false;
    }
    if (payload.service === 'cognisearch') {
      if (this.hasOnlyAvoidConditions(payload.conditions, 'exclude')) {
        this.toastService.sendMessage(
          'You can not run an exclude only query',
          'error'
        );
        return false;
      }
    }
    if (payload.service === 'pdl-commercial') {
      if (this.hasOnlyAvoidConditions(payload.conditions, 'not')) {
        this.toastService.sendMessage(
          'You can not run an avoid only query',
          'error'
        );
        return false;
      }
      if (this.hasMultipleAvoidTermCondition(payload)) {
        this.toastService.sendMessage(
          'You can only use 1 term per avoid condition',
          'error'
        );
        return false;
      }
    }
    return true;
  }

  private hasNoTerms(conditions: { terms: string[] }[]): boolean {
    return conditions.every((condition) => !condition.terms.length);
  }

  private hasOnlyAvoidConditions(
    conditions: { operator: string }[],
    operator: 'not' | 'exclude'
  ): boolean {
    return conditions.every((condition) => condition.operator === operator);
  }

  private hasMultipleAvoidTermCondition(
    payload: ISearchQuery | ICommercialSearchQuery | IHistorialSearchQuery
  ): boolean {
    return payload.conditions.some(
      (condition) => condition.terms.length > 1 && condition.operator === 'not'
    );
  }

  private createCombinedSearchPayload(): ISearchCombinedQuery {
    return {
      ...this.createHistoricalSearchPayload(),
      service: 'combined-search',
    };
  }

  private createHistoricalSearchPayload(): IHistorialSearchQuery {
    return {
      service: 'historical',
      count: this.expertTargetNumber,
      countries: this.selectedCountries,
      conditions: this.formatHistoricalConditions(
        (c) => !this.isHiddenCondition(c)
      ),
      emailOnly: this.emailOnly,
      doctorsOnly: this.doctorsOnly,
    };
  }

  private createCogniSearchPayload(): IHistorialSearchQuery {
    return {
      service: 'cognisearch',
      count: this.expertTargetNumber,
      countries: this.selectedCountries,
      conditions: this.formatHistoricalConditions(),
      emailOnly: this.emailOnly,
      doctorsOnly: this.doctorsOnly,
    };
  }

  private formatHistoricalConditions(
    filter = (con: ICondition) => !!con
  ): IHistoricalCondition[] {
    return this.conditions.filter(filter).map((condition) => ({
      match: true,
      field: condition.fieldValue,
      operator: condition.operator,
      use_wildcards: true,
      terms: condition.terms.map((term) => term.toLowerCase().trim()),
    }));
  }

  private createSearchPayload(service: ISearchQuery['service']): ISearchQuery {
    return {
      service,
      count: this.expertTargetNumber,
      countries: this.selectedCountries,
      conditions: this.formatConditions(),
      emailOnly: this.emailOnly,
      doctorsOnly: this.doctorsOnly,
    };
  }

  private formatConditions(): ISearchCondition[] {
    return this.conditions
      .filter((a) => !this.isHiddenCondition(a))
      .map((condition) => ({
        operator: condition.operator,
        match: true,
        matchPhrase: true,
        field: condition.fieldValue,
        terms: condition.terms.map((term) => term.toLowerCase().trim()),
      }));
  }

  private createCommercialSearchPayload(
    service: ICommercialSearchQuery['service']
  ): ICommercialSearchQuery {
    return {
      size: 100,
      from: 0,
      include_past_experiences: this.includePastExperiences,
      countries: this.selectedCountries,
      conditions: this.formatCommercialConditions(),
      service,
    };
  }

  private formatCommercialConditions(): ICommercialCondition[] {
    return this.conditions
      .filter((a) => !this.isHiddenCondition(a))
      .map((condition) => ({
        operator: condition.operator,
        field: condition.fieldValue,
        level: condition.level ? condition.level : 'all',
        terms: condition.terms.map((term) => term.toLowerCase().trim()),
      }));
  }

  public extendCommercialResults(): void {
    this.commercialQuery = {
      ...this.commercialQuery,
      from: (this.commercialQuery.from += 100),
    };

    if (this.payloadValidator(this.commercialQuery))
      this.payloadSignal.emit(this.commercialQuery);
  }

  public resetPull(): void {
    this.commercialQueryHasRun = false;
    this.disable = false;
    this.commercialQuery = {
      ...this.commercialQuery,
      from: 0,
    };
  }
}
