import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CognitoAuthService, ConnectUser } from '@techspert-io/auth';
import { ClientsService, IClient } from '@techspert-io/clients';
import {
  ExpertsCreateService,
  ExpertSegment,
  expertSegmentList,
  IExpert,
  IExpertCreateRequest,
} from '@techspert-io/experts';
import { ISearchExpandedTermsReturn } from '@techspert-io/feasibility';
import {
  IOpportunity,
  IOpportunitySegmentWithId,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import {
  ExpertSourcesService,
  IExpertSourceAsyncCreateReq,
  IExpertSourceCommercialSyncCreateReq,
  IExpertSourceSyncCreateReq,
} from '@techspert-io/search';
import { ToastService } from '@techspert-io/user-alerts';
import { UserService } from '@techspert-io/users';
import { Observable } from 'rxjs';
import { ICreateManyResponse } from '../../../shared/models/api';
import { AppService } from '../../../shared/services/app.service';
import { EmailService } from '../../../shared/services/email.service';
import {
  ICloneExpertsQuery,
  ICommercialSearchQuery,
  ILinkedInEnrichmentQuery,
  ISearchCombinedQuery,
  ISearchQuery,
} from '../models/query';
import {
  IAfterSaveAction,
  ISearchDisplayExpert,
  ISelectOpportunityDialogData,
} from '../models/search-models';
import { SearchService } from '../services/search.service';

interface ICreateExpertAndSourceRes {
  redirectParams?: Record<string, string>;
  searchId: string;
  expertRes: ICreateManyResponse<IExpert>;
}

@Component({
  selector: 'app-select-opportunity-dialog',
  templateUrl: './select-opportunity-dialog.component.html',
})
export class SelectOpportunityDialogComponent implements OnInit {
  @ViewChild('OpportunityInput') opportunityInput: ElementRef;
  @ViewChild('SearchInput') searchInput: ElementRef;
  @ViewChild('ExpertTargetInput') expertTargetInput: ElementRef;
  @Output() dialogModeUpdatedOpportunitySignal =
    new EventEmitter<IAfterSaveAction>();

  public selectedCount: number;
  public experts: ISearchDisplayExpert[];
  public clientList: IClient[] = [];
  public opportunityList: IOpportunity[] = [];
  public searchList: string[] = [];
  public client?: IClient;
  public opportunity?: IOpportunity;
  public newSearchName: string;
  public query:
    | ISearchQuery
    | ICommercialSearchQuery
    | ILinkedInEnrichmentQuery
    | ICloneExpertsQuery
    | ISearchCombinedQuery;
  public showSendAndGoButtonLoader: boolean = false;
  public showSendAndRemainButtonLoader: boolean = false;
  public showSendAndRemainButtonSuccessMessage: boolean;

  public segments = expertSegmentList;

  public segment: ExpertSegment | '' = '';
  public opportunitySegments: IOpportunitySegmentWithId[];
  public opportunitySegment?: IOpportunitySegmentWithId;
  public connectUsers$: Observable<ConnectUser[]>;
  public expertOwner: ConnectUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISelectOpportunityDialogData,
    public dialogRef: MatDialogRef<SelectOpportunityDialogComponent>,
    public clientsService: ClientsService,
    public emailService: EmailService,
    public cognitoAuthService: CognitoAuthService,
    public router: Router,
    private toastService: ToastService,
    private expertsService: ExpertsCreateService,
    private userService: UserService,
    private searchService: SearchService,
    private expertSourcesService: ExpertSourcesService,
    private opportunitiesService: OpportunitiesService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.experts = this.data.experts;
    this.query = this.data.query;
    this.selectedCount = this.data.selectedCount;
    if (this.data.dialogMode) {
      this.client = this.data.client;
      this.opportunity = this.data.opportunity;
      this.assignOpportunitySegmentsWithIds();
      this.searchList = Object.keys(this.opportunity.searches);
      if (this.data.searchName) {
        this.newSearchName = this.data.searchName;
      }
    } else {
      this.clientsService.getAll().subscribe((clients) => {
        this.clientList = clients;
      });
    }

    this.expertOwner = this.cognitoAuthService.loggedInUser;
    this.connectUsers$ = this.userService.getAll({ userTypes: ['PM'] });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public assignClientAndGetOpportunities(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.clearClientAndOpportunitySpecificFields();
    this.client = this.clientList.find(
      (client) => client.clientName === target.value
    );

    if (!this.client) {
      this.opportunityList = [];
    } else {
      this.opportunitiesService
        .query({ clientIds: [this.client.clientId] })
        .subscribe((data) => {
          this.opportunityList = data;
        });
    }
  }

  public assignOpportunity(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.clearOpportunitySpecificFields();
    this.opportunity = this.opportunityList.find(
      (opportunity) => opportunity.opportunityName === target.value
    );

    if (!this.opportunity) {
      this.searchList = [];
    } else {
      this.searchList = Object.keys(this.opportunity.searches);
      this.assignOpportunitySegmentsWithIds();
    }
  }

  public assignSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.newSearchName = target.value;
  }

  public assignExpertTarget(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.opportunitySegment = this.opportunitySegments.find(
      (expertTarget) => expertTarget.id === target.value
    );
  }

  public async sendExperts(afterSaveAction: 'go' | 'remain'): Promise<void> {
    try {
      const formattedSearchName = this.hyphenateSearchName(this.newSearchName);
      this.chooseCorrectLoader(afterSaveAction);
      const connectFormatExperts =
        this.getConnectFormatExperts(formattedSearchName);

      if (this.query.service === 'cognisearch') {
        await this.expertSourcesService
          .createAsyncCognisearch({
            opportunityId: this.opportunity.opportunityId,
            segment: this.segment,
            opportunitySegmentId: this.opportunitySegment.id,
            searchName: formattedSearchName,
            requestCount: this.query.count,
            type: 'async',
            query: {
              service: 'cognisearch',
              countries: this.query.countries || [],
              size: this.query.count,
              conditions: this.query.conditions,
            },
            defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
          })
          .toPromise();

        await this.saveKnowledgeGraphUseage();

        this.dialogModeUpdatedOpportunitySignal.emit({
          bubbleClose: afterSaveAction === 'go',
          updatedOpportunity: this.opportunity,
        });

        return this.takeAfterSaveAction(afterSaveAction, {});
      }

      if (this.query.service === 'expert-clone') {
        await this.expertSourcesService
          .createAsyncClone({
            opportunityId: this.opportunity.opportunityId,
            segment: this.segment,
            opportunitySegmentId: this.opportunitySegment.id,
            searchName: formattedSearchName,
            type: 'async',
            query: {
              service: 'expert-clone',
              expertIds: this.query.expertIds,
            },
            sourceOpportunityId: this.query.sourceOpportunityId,
            defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
          })
          .toPromise();

        this.dialogModeUpdatedOpportunitySignal.emit({
          bubbleClose: afterSaveAction === 'go',
          updatedOpportunity: this.opportunity,
        });

        return this.takeAfterSaveAction(afterSaveAction, {});
      }

      const { redirectParams, searchId, expertRes } = await (this.query
        .service === 'combined-search'
        ? this.createExpertsAndSourceAsync(
            formattedSearchName,
            connectFormatExperts
          )
        : this.createExpertsAndSourceSync(
            formattedSearchName,
            connectFormatExperts
          ));

      const updatedOpportunity = await this.appendSearchToOpportunity({
        opportunityId: this.opportunity.opportunityId,
        searches: {
          [searchId]: {
            query: this.query,
            stats: {},
          },
        },
      });

      this.toastService.sendMessage(
        `Added ${expertRes.success.length} of ${
          connectFormatExperts.length
        } experts via ${
          ['pdl-commercial', 'pdl-enrichment'].includes(this.query.service)
            ? 'commercial service'
            : this.query.service
        } to ${this.opportunity.opportunityName}`,
        'success'
      );

      await this.saveKnowledgeGraphUseage();

      this.dialogModeUpdatedOpportunitySignal.emit({
        bubbleClose: afterSaveAction === 'go',
        updatedOpportunity,
      });
      this.takeAfterSaveAction(afterSaveAction, redirectParams || {});
    } catch (err) {
      this.showSendAndGoButtonLoader = false;
      this.showSendAndRemainButtonLoader = false;
      this.toastService.sendMessage(err, 'error');
    }
  }

  private async createExpertsAndSourceAsync(
    searchName: string,
    connectFormatExperts: IExpertCreateRequest[]
  ): Promise<ICreateExpertAndSourceRes> {
    const { batchId, searchId, expertSourceId } =
      await this.createExpertSources(searchName, {});

    const expertRes = await this.bulkCreateExpertsUnderNewSearch(
      connectFormatExperts.map((e) => ({
        ...e,
        searchId,
        ...(expertSourceId ? { expertSourceId } : {}),
      }))
    );

    return {
      redirectParams: { ['batch-id']: batchId },
      searchId,
      expertRes,
    };
  }

  private async createExpertsAndSourceSync(
    searchName: string,
    connectFormatExperts: IExpertCreateRequest[]
  ): Promise<ICreateExpertAndSourceRes> {
    const expertSourceId = this.appService.createUUID();

    const expertRes = await this.bulkCreateExpertsUnderNewSearch(
      connectFormatExperts.map((e) => ({
        ...e,
        searchId: searchName,
        ...(expertSourceId ? { expertSourceId } : {}),
      }))
    );

    await this.createExpertSources(searchName, {
      expertSourceId,
      expertsAdded: expertRes.success.length,
      expertsFound: this.experts.length,
    });

    return {
      searchId: searchName,
      expertRes,
    };
  }

  expertOwnerCompare(a: ConnectUser, b: ConnectUser): Boolean {
    return a?.connectId === b?.connectId;
  }

  private async createExpertSources(
    searchName: string,
    additionalData: {
      expertSourceId?: string;
      expertsAdded?: number;
      expertsFound?: number;
    }
  ): Promise<{
    batchId?: string;
    expertSourceId?: string;
    searchId?: string;
  }> {
    if (this.query.service === 'expert-clone') {
      // [JP:CO-328] This is for types, none of the methods call this anymore...
      return;
    }

    const basePayload = {
      opportunityId: this.opportunity.opportunityId,
      segment: this.segment,
      opportunitySegmentId: this.opportunitySegment.id,
      searchName: searchName,
    };

    if (this.query.service === 'combined-search') {
      const { batchId, searches } = await this.expertSourcesService
        .create<IExpertSourceAsyncCreateReq>({
          ...basePayload,
          requestCount: this.query.count,
          type: 'async',
          query: this.query,
          defaultExpertData: { ownerConnectId: this.expertOwner.connectId },
        })
        .toPromise();

      const firstSearch = searches
        .filter((s) => s.searchName.includes('historical'))
        .sort()
        .find(Boolean);

      return {
        batchId,
        expertSourceId: firstSearch?.expertSourceId,
        searchId: firstSearch?.searchName || searchName,
      };
    }

    if (this.query.service === 'pdl-enrichment') {
      const { searches, batchId } = await this.expertSourcesService
        .create<IExpertSourceSyncCreateReq>({
          ...basePayload,
          ...additionalData,
          requestCount: this.experts.length,
          type: 'sync',
          query: {
            service: this.query.service,
            size: this.experts.length,
          },
        })
        .toPromise();

      const firstSearch = searches.find(Boolean);

      return {
        batchId,
        expertSourceId: firstSearch?.expertSourceId,
        searchId: firstSearch?.searchName || searchName,
      };
    }

    const { searches, batchId } = await (this.query.service === 'pdl-commercial'
      ? this.expertSourcesService.create<IExpertSourceCommercialSyncCreateReq>({
          ...basePayload,
          ...additionalData,
          requestCount: this.query.size,
          type: 'sync',
          query: {
            conditions: this.query.conditions,
            countries: this.query.countries,
            service: this.query.service,
            size: this.query.size,
            fromIndex: this.query.from,
            include_past_experiences: this.query.include_past_experiences,
          },
        })
      : this.expertSourcesService.create<IExpertSourceSyncCreateReq>({
          ...basePayload,
          ...additionalData,
          requestCount: this.query.count,
          type: 'sync',
          query: {
            conditions: this.query.conditions,
            countries: this.query.countries,
            service: this.query.service,
            size: this.query.count,
            fromIndex: 0,
          },
        })
    ).toPromise();

    const firstSearch = searches.find(Boolean);

    return {
      batchId,
      expertSourceId: firstSearch?.expertSourceId,
      searchId: firstSearch?.searchName || searchName,
    };
  }

  private getConnectFormatExperts(searchName: string): IExpertCreateRequest[] {
    return this.experts.map((searchExpert) => {
      let targetFields: Partial<IExpertCreateRequest>;
      const expert = {
        ...searchExpert.source,
        opportunityId: this.opportunity.opportunityId,
        recordingAuthorised: this.opportunity.callRecording,
        searchId: searchName,
        ownerConnectId: this.expertOwner.connectId,
      };
      if (this.opportunitySegment) {
        targetFields = {
          geographicTarget: this.opportunitySegment.geography,
          profileType: this.opportunitySegment.segment,
          opportunitySegmentId: this.opportunitySegment.id,
        };
      }

      return {
        ...expert,
        ...targetFields,
        ...(this.segment ? { expertSegment: this.segment } : {}),
      };
    });
  }

  private takeAfterSaveAction(
    afterSaveAction: 'go' | 'remain',
    queryParams: Record<string, string>
  ): void {
    switch (afterSaveAction) {
      case 'go':
        this.showSendAndGoButtonLoader = false;
        this.router.navigate(
          [
            '/admin/client/',
            this.client.clientId,
            'opportunity',
            this.opportunity.opportunityId,
          ],
          { queryParams }
        );
        this.closeDialog();
        break;
      case 'remain':
        this.showSendAndRemainButtonLoader = false;
        this.showSendAndRemainButtonSuccessMessage = true;
        break;
    }
  }

  private hyphenateSearchName(searchName: string): string {
    return searchName.trim().replace(/\s/g, '-').toLowerCase();
  }

  private chooseCorrectLoader(afterSaveAction: string): void {
    switch (afterSaveAction) {
      case 'go':
        this.showSendAndGoButtonLoader = true;
        break;
      case 'remain':
        this.showSendAndRemainButtonLoader = true;
        break;
    }
  }

  private assignOpportunitySegmentsWithIds(): void {
    this.opportunitySegments = Object.entries(this.opportunity.segments)
      .map(([key, segment]) => {
        return {
          ...segment,
          id: key,
        };
      })
      .filter((s) => s.active);
  }

  private clearClientAndOpportunitySpecificFields(): void {
    this.opportunity = null;
    this.opportunityInput.nativeElement.value = '';
    this.clearOpportunitySpecificFields();
  }

  private clearOpportunitySpecificFields(): void {
    this.newSearchName = '';
    this.searchList = [];
    this.searchInput.nativeElement.value = '';
    this.segment = '';
    this.opportunitySegment = null;
    this.opportunitySegments = [];
    this.expertTargetInput.nativeElement.value = '';
  }

  private async bulkCreateExpertsUnderNewSearch(
    experts: IExpertCreateRequest[]
  ): Promise<ICreateManyResponse<IExpert>> {
    return this.expertsService.createMany(experts).toPromise();
  }

  private appendSearchToOpportunity(newSearchPayload: {
    opportunityId: string;
    searches: IOpportunity['searches'];
  }): Promise<IOpportunity> {
    return this.opportunitiesService
      .appendSearchToOpportunity(newSearchPayload)
      .toPromise();
  }

  private saveKnowledgeGraphUseage(): Promise<ISearchExpandedTermsReturn> {
    if (
      this.query.service === 'deep3' ||
      this.query.service === 'deep-next' ||
      this.query.service === 'historical' ||
      this.query.service === 'combined-search' ||
      this.query.service === 'pdl-commercial' ||
      this.query.service === 'cognisearch'
    ) {
      return this.searchService
        .saveKeptKnowledgeGraphTerms(
          this.query.conditions,
          this.opportunity.opportunityId,
          this.opportunitySegment?.id
        )
        .toPromise();
    }
  }
}
