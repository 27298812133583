import { ExpertFile } from '../models/expert-file.model';

export const baseExpertFileMock: ExpertFile = {
  fileId: 'fileId',
  fileName: 'fileName',
  fileExtension: 'txt',
  fileKey: 'fileName.txt',
  type: 'manual',
  deleted: false,
  expertId: 'expertId',
  pendingApproval: true,
  lastUpdated: 1,
  dateCreated: 2,
  createdBy: 'createdBy',
  lastUpdatedBy: 'lastUpdatedBy',
};
