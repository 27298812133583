import { IExpertCallAction } from '@techspert-io/conferences';
import { IEngagement } from '@techspert-io/engagements';
import { IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import { Client } from '../../../shared/models/client';

export const scheduleDialogSize = {
  width: '1190px',
  height: '916px',
};

export interface IScheduleDialogInput {
  expert: IExpert;
  opportunity: IOpportunity;
  client: Client;
  action: 'schedule' | 'reschedule' | 'updateEmail' | 'cancel' | 'complete';
  callAction?: IExpertCallAction;
}

export interface IScheduleDialogClosePayload {
  expert: IExpert;
  callAction: IExpertCallAction;
  engagement?: IEngagement;
}

export type EmailType = 'new' | 'update' | 'cancel';
