import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WINDOW } from '@techspert-io/auth';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications-dialog',
  styleUrls: ['./notifications-dialog.component.scss'],
  templateUrl: './notifications-dialog.component.html',
})
export class NotificationsDialogComponent {
  searchFilter$ = new BehaviorSubject<boolean>(false);
  notifications$ = combineLatest([
    this.notificationService.notifications$,
    this.searchFilter$,
  ]).pipe(
    map(([notifs, searchFilter]) => ({
      notifs: notifs.filter((n) => n.archived === searchFilter),
      count: notifs.filter((n) => !n.archived).length,
    }))
  );

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<NotificationsDialogComponent>,
    private notificationService: NotificationService,
    @Inject(WINDOW) private window: Window
  ) {}

  archiveNotification(notificationId: string): void {
    this.notificationService.archiveNotification(notificationId).subscribe();
  }

  clearNotifications(): void {
    this.notificationService.clearNotifications().subscribe();
  }

  filterNotifications(archived: boolean): void {
    this.searchFilter$.next(archived);
  }

  routeAction(actionLink: string): void {
    actionLink.startsWith('http')
      ? this.window.open(actionLink)
      : this.router.navigate([actionLink]);

    this.closeModal();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
