import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EngagementPaymentProviders,
  EngagementPaymentReasons,
  EngagementType,
  IEngagement,
  paymentReasonsList,
} from '@techspert-io/engagements';
import { IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import { EMPTY, Observable } from 'rxjs';
import { DeleteEngagementDialogComponent } from '../../../../../../shared/components/delete-engagement-dialog/delete-engagement-dialog.component';
import { AppService } from '../../../../../../shared/services/app.service';
import { PaymentProvidersService } from '../../../../../../shared/services/payment-providers.service';

export interface INewEngagement {
  engagementType: EngagementType;
  reason?: EngagementPaymentReasons;
}
@Component({
  selector: 'app-expert-profile-billing',
  templateUrl: './expert-profile-billing.component.html',
  styleUrls: ['./expert-profile-billing.component.scss'],
})
export class ExpertProfileBillingComponent implements OnInit {
  @Input() public expert: IExpert;
  @Input() public opportunity: IOpportunity;
  @Input() public engagements: IEngagement[] = [];
  @Input() public focusEngagmentId?: string;
  @Output() public addEngagementSignal = new EventEmitter<INewEngagement>();
  @Output() public deleteEngagementSignal = new EventEmitter<
    IExpert['expertId']
  >();
  @Output() public sentToClientDateChangeSignal = new EventEmitter<number>();
  public paymentReasons: EngagementPaymentReasons[] = paymentReasonsList;
  public sentToClientDate: string;

  panelStateOpen: Record<string, boolean> = {};
  paymentProviders$: Observable<EngagementPaymentProviders[]> = EMPTY;

  constructor(
    public dialog: MatDialog,
    public appService: AppService,
    private paymentProvidersService: PaymentProvidersService
  ) {}

  ngOnInit(): void {
    this.paymentProviders$ = this.paymentProvidersService.getProviders();

    this.setSentToClientDate();
  }

  public updateSentToClientDate(target: HTMLInputElement): void {
    if (target.value)
      this.sentToClientDateChangeSignal.emit(new Date(target.value).getTime());
  }

  public addEngagement(
    engagementType: EngagementType,
    reason?: EngagementPaymentReasons
  ): void {
    if (!reason) {
      this.addEngagementSignal.emit({ engagementType });
    } else {
      this.addEngagementSignal.emit({ engagementType, reason });
    }
  }

  public openDeleteEngagementDialog(engagementId: string): void {
    const dialogRef = this.dialog.open(DeleteEngagementDialogComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.deleteEngagementSignal.emit(engagementId);
      }
    });
  }

  toggleAccordion(engagementId: string, state: boolean): void {
    this.panelStateOpen[engagementId] = state;
  }

  private setSentToClientDate(): void {
    const sentToClientPhase = this.expert.phaseTimestamps.find(
      (phaseTimestamp) => phaseTimestamp.phase === 'sentToClient'
    );
    if (sentToClientPhase) {
      this.sentToClientDate = this.convertTimestampToDate(
        sentToClientPhase.timestamp
      );
    }
  }

  private convertTimestampToDate(timestamp: number): string {
    return new Date(
      this.appService.normaliseUnixTimestampToMilliseconds(timestamp)
    )
      .toISOString()
      .split('T')[0];
  }
}
