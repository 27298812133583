import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IExpert } from '@techspert-io/experts';
import { OmnisearchService } from '@techspert-io/omnisearch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-expert-profile-biography',
  templateUrl: './expert-profile-biography.component.html',
  styleUrls: ['./expert-profile-biography.component.scss'],
})
export class ExpertProfileBiographyComponent implements OnInit {
  @Input() public expert: IExpert;
  @Output() public saveBioSignal = new EventEmitter();

  bios$: Observable<string[]>;

  constructor(private omnisearchService: OmnisearchService) {}

  ngOnInit(): void {
    if (!this.expert.tagline) {
      this.expert.tagline = '';
    }

    this.bios$ = this.omnisearchService
      .getPreviousEngagements({
        emailAddress: this.expert.primaryEmail,
        searchExpertId: this.expert.searchExpertData?.id,
      })
      .pipe(
        map((engagements) =>
          engagements
            .map((engagement) => engagement.bio?.trim())
            .filter(Boolean)
        )
      );
  }

  appendBio(bio: string): void {
    this.expert.bio = `${
      this.expert.bio ? this.expert.bio + '<br/>' : ''
    }${bio}`;
  }

  public emitSaveBio(): void {
    this.saveBioSignal.emit({
      expertId: this.expert.expertId,
      bio: this.expert.bio,
      tagline: this.expert.tagline,
    });
  }

  public onGoogleSearch(): void {
    window.open(
      `https://www.google.com/search?q=${this.expert.firstName}+${this.expert.lastName}`,
      '_blank'
    );
  }

  public onGoogleScholar(): void {
    window.open(
      `https://scholar.google.co.uk/citations?view_op=search_authors&mauthors=${this.expert.firstName}+${this.expert.lastName}`,
      '_blank'
    );
  }

  public onLinkedIn(): void {
    const url = this.expert.linkedInUrl
      ? `https://www.${this.expert.linkedInUrl}`
      : `https://www.linkedin.com/search/results/people/?keywords=${this.expert.firstName}%20${this.expert.lastName}`;

    window.open(url, '_blank');
  }
}
