export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  base: 'https://exp.connect.techspert.com',
  connectApiBaseUrl: 'https://exp.api.connect.techspert.io',
  searchApiBaseUrl: 'https://exp.api.search.techspert.io',
  searchApiBase2Url: 'https://exp.api.search.techspert.com',
  portalBase: 'https://exp.portal.techspert.com',
  expertPortalBase: 'https://exp.expert.techspert.com',
  salesforceBase: '$EXPSALESFORCE',
  envName: 'exp',
  gaMeasurementId: 'G-HKR8H0VMG2',
  raygunApiKey: 'Ubp8tYWrTCCZsWEhA5swA',
  auth0: {
    domain: 'exp.id.techspert.com',
    clientId: 'DTuWPd7zhuU5kGEaecLCtI3rTyFUKTyR',
    audience: 'https://exp.api.techspert.io',
    redirectUri: 'https://exp.connect.techspert.com',
    responseType: 'code',
    connection: 'All-Passwordless-Users',
  },
};
