import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-engagement-dialog',
  templateUrl: './delete-engagement-dialog.component.html',
  styleUrls: ['./delete-engagement-dialog.component.scss'],
})
export class DeleteEngagementDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteEngagementDialogComponent>
  ) {}

  public closeConfirm(): void {
    this.dialogRef.close('delete');
  }

  public closeCancel(): void {
    this.dialogRef.close();
  }
}
