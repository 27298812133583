import { IExpert } from './experts.models';

export type EmailStatus =
  | 'cnNotSentDuplicate'
  | 'cnPendingSend'
  | 'cnNotSentUnengaged'
  | 'bounce'
  | 'spam'
  | 'open'
  | 'delivered'
  | 'blocked'
  | 'rejected'
  | 'accepted';

export interface IStatusHistory {
  event: EmailStatus;
  timestamp: number;
}

export interface ISolicitationOutreach {
  emailId: string;
  recipient: string;
  expertId: string;
  batchId: string;
  connectPhase: IExpert['connectPhase'] | '';
  searchExpertId: string;
  trackingId: string;
  opportunityId: string;
  templateId?: string;
  templateContent?: Record<string, unknown | unknown[]>;
  templateParentId?: string;
  referenceId?: number;
  status?: EmailStatus;
  statusHistory?: IStatusHistory[];
  dateCreated: number;
}
