import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IExpertActionEmail } from '@techspert-io/expert-actions';
import { Observable } from 'rxjs';
import { Email, IEmailMessage } from '../models/email';

export enum ActionEmailType {
  SCHEDULE = 'schedule',
  RESCHEDULE = 'reschedule',
  CANCELLATION = 'cancellation',
}

export enum ActionEmailRecipientType {
  EXPERT = 'expert',
  CLIENT = 'client',
  NONBLIND = 'non-blind',
}

export interface ICreateCallActionPayload {
  expertActionId: string;
  type: ActionEmailType;
  recipientType: ActionEmailRecipientType;
  email: Email;
  reminderHtml?: string;
}

type CallActionEmailAndMessage = {
  message?: IEmailMessage;
  actionMessage: IExpertActionEmail;
};

@Injectable()
export class ConferenceService {
  private readonly baseUrl = '/schedule';

  constructor(private http: HttpClient) {}

  checkCountryIsSupported(country: string): Observable<{ support: boolean }> {
    return this.http.post<{ support: boolean }>(`${this.baseUrl}/support`, {
      country,
    });
  }

  sendCallActionEmail(payload: ICreateCallActionPayload): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/callActionEmails`, payload);
  }

  getEmailsForCallActionId(
    callActionId: string
  ): Observable<CallActionEmailAndMessage[]> {
    return this.http.get<CallActionEmailAndMessage[]>(
      `${this.baseUrl}/callActionEmails/${callActionId}`
    );
  }
}
