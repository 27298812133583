import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AuthModule } from '@techspert-io/auth';
import { ConferencesModule } from '@techspert-io/conferences';
import { ErrorHandlingModule } from '@techspert-io/errorHandling';
import { ExpertsModule } from '@techspert-io/experts';
import { SolicitationModule } from '@techspert-io/solicitation';
import { UserAlertsModule } from '@techspert-io/user-alerts';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { AdminPortalModule } from './admin-portal/admin-portal.module';
import { ClientOpportunityModule } from './admin-portal/clients/client-opportunity.module';
import { DashboardModule } from './admin-portal/dashboard/dashboard.module';
import { PaymentsModule } from './admin-portal/payments/payments.module';
import {
  SEARCH_API_BASE_2_URL,
  SEARCH_API_BASE_URL,
} from './admin-portal/search/services/search.service';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { CoreModule } from './core/core.module';
import { EngagementsModule } from './features/engagements/engagements.module';
import { PORTAL_URL } from './features/opportunities/pipes/portal-links.pipe';
import { BtxSharedModule } from './shared/btx-shared.module';
import { SALESFORCE_URL } from './shared/pipes/salesforce-links.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FullCalendarModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BtxSharedModule,
    CoreModule,
    AdminPortalModule,
    DashboardModule,
    PaymentsModule,
    ClientOpportunityModule,
    AuthModule.forRoot(
      environment.auth0.domain,
      environment.connectApiBaseUrl,
      environment.base
    ),
    Auth0Module.forRoot({
      ...environment.auth0,
      useRefreshTokens: true,
      redirectUri: window.location.origin,
      cacheLocation: 'localstorage',
    }),
    ExpertsModule,
    ConferencesModule,
    EngagementsModule,
    SolicitationModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaMeasurementId),
    ErrorHandlingModule.forRoot(
      environment.raygunApiKey,
      environment.appVersion
    ),
    UserAlertsModule,
  ],
  providers: [
    {
      provide: PORTAL_URL,
      useValue: environment.portalBase,
    },
    {
      provide: SALESFORCE_URL,
      useValue: environment.salesforceBase,
    },
    {
      provide: SEARCH_API_BASE_URL,
      useValue: environment.searchApiBaseUrl,
    },
    {
      provide: SEARCH_API_BASE_2_URL,
      useValue: environment.searchApiBase2Url,
    },
    {
      provide: LOCALE_ID,
      useValue:
        `${navigator.language}`.toLowerCase() === 'en-us'
          ? navigator.language
          : 'en-GB',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
