import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AppService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chunkArray<T = any>(chunkSize: number, array: T[]): T[][] {
    return array.reduce((acc, each, index, src) => {
      if (!(index % chunkSize)) {
        return [...acc, src.slice(index, index + chunkSize)];
      }
      return acc;
    }, []);
  }

  createUUID(): string {
    return uuidv4();
  }

  /** Normalises second or millisecond inputs into milliseconds for Unix. Won't work for times before 09/09/2001 01:46:40 GMT*/
  normaliseUnixTimestampToMilliseconds(timestamp: number): number {
    const mag = Math.ceil(Math.log10(timestamp + 1));

    return mag >= 13 ? timestamp : timestamp * 10 ** (13 - mag);
  }

  removeStrictExpertProps<T>(obj: T, ...extraProps: string[]): Partial<T> {
    const toRemove = [
      's_bios',
      's_images',
      's_papers',
      's_phones',
      's_qualifications',
      'expertId',
      'opportunityId',
      'lastUpdated',
      'searchId',
      'searchExpertData',
      'emailHistory',
      'isSelected',
      'isSelectable',
      'conference',
      'conferenceReminder',
      'seenByClient',
      'salesforceId',
      'engagements',
      'screenerConfig',
      'callActions',
    ].concat(extraProps);

    return Object.entries(obj)
      .filter(([key]) => !toRemove.includes(key))
      .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {} as T);
  }

  removeUnchangedProps<T extends {}>(item: T, original: T): Partial<T> {
    return Object.entries(item).reduce<Partial<T>>(
      (prev, [key, value]) =>
        JSON.stringify(value) === JSON.stringify(original[key])
          ? prev
          : { ...prev, [key]: value },
      {}
    );
  }
}
