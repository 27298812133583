import { Injectable } from '@angular/core';
import { IOpportunitySegments } from '../models/opportunity.models';

export interface IAggregatedOpportunitySegment {
  geography: string;
  segments: IAggregatedOpportunitySegmentSegment[];
}
interface IAggregatedOpportunitySegmentSegment {
  segment: string;
  amount: number;
}

@Injectable({
  providedIn: 'root',
})
export class OpportunitySegmentsService {
  getOpportunityTargetsByGeographyDict(
    opportunitySegments: IOpportunitySegments
  ): Record<string, string[]> {
    return Object.values(opportunitySegments).reduce(
      (prev, curr) =>
        curr.active
          ? {
              ...prev,
              [curr.geography]: [...(prev[curr.geography] || []), curr.segment],
            }
          : prev,
      {}
    );
  }

  aggregateSegments(
    opportunitySegments: IOpportunitySegments
  ): IAggregatedOpportunitySegment[] {
    if (opportunitySegments) {
      return Object.entries(
        Object.entries(opportunitySegments).reduce<
          Record<string, IAggregatedOpportunitySegmentSegment[]>
        >((prev, [, opportunitySegment]) => {
          if (opportunitySegment.active) {
            const aggregateSegment = [
              ...(prev[opportunitySegment.geography] || []),
              {
                segment: opportunitySegment.segment,
                amount: opportunitySegment.amount,
              },
            ];
            return {
              ...prev,
              [opportunitySegment.geography]: aggregateSegment,
            };
          }
          return prev;
        }, {})
      ).map(([geography, segments]) => {
        return { geography, segments };
      });
    }
    return [];
  }

  getOpportunitySegmentId(
    segments: IOpportunitySegments,
    identifiers: {
      geographicTarget?: string;
      profileType?: string;
    }
  ): string | undefined {
    return Object.entries(segments)
      .filter(
        ([, v]) =>
          v.active &&
          v.geography === identifiers.geographicTarget &&
          v.segment === identifiers.profileType
      )
      .map(([k]) => k)
      .find(Boolean);
  }
}
