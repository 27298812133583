import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CognitoAuthService } from '@techspert-io/auth';
import {
  ClientContactDialogComponent,
  IClientContactForm,
  IOpportunity,
  IOpportunityClientContact,
  IOpportunityUpdateRequest,
  OpportunitiesService,
} from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';
import { Observable } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.scss'],
})
export class ClientContactsComponent implements OnInit {
  @Input() opportunity: IOpportunity;
  public contactList: IOpportunityClientContact[] = [];

  constructor(
    private opportunitiesService: OpportunitiesService,
    private usersService: UserService,
    private dialog: MatDialog,
    private cognitoAuthService: CognitoAuthService
  ) {}

  ngOnInit(): void {
    if (this.opportunity && this.opportunity.clientContacts) {
      this.contactList = [...this.opportunity.clientContacts];
    }
  }

  openContactForm(): void {
    this.openModal()
      .pipe(switchMap((contact) => this.saveNewContact(contact)))
      .subscribe();
  }

  openEditContactForm(contact: IOpportunityClientContact): void {
    this.openModal({
      ...contact,
      options: {
        hideInviteToggle: true,
      },
    })
      .pipe(
        switchMap((contactRes) => {
          const { options, ...editedContact } = contactRes;

          return this.usersService
            .updateUserForClientContact({
              clientContact: { ...contact, ...editedContact },
              opportunityData: {
                publicDisplayId: this.opportunity.publicDisplayId,
                opportunityName: this.opportunity.opportunityName,
                clientId: this.opportunity.clientId,
              },
              sendInviteEmail: !!options.sendInviteEmail,
              senderEmail: this.cognitoAuthService.loggedInUser.email,
            })
            .pipe(
              tap((ret) => {
                const clientContacts = this.opportunity.clientContacts.map(
                  (cc) =>
                    cc.connectId === ret.connectId
                      ? {
                          ...ret,
                          primary:
                            this.opportunity.primaryContactId === ret.connectId,
                        }
                      : cc
                );
                this.opportunity = {
                  ...this.opportunity,
                  clientContacts,
                };
                this.contactList = clientContacts;
              })
            );
        })
      )
      .subscribe();
  }

  deleteContactFromList(connectId: string): void {
    this.update({
      opportunityId: this.opportunity.opportunityId,
      clientContactIds: this.opportunity.clientContactIds.filter(
        (cc) => cc !== connectId
      ),
      ...(this.opportunity.primaryContactId === connectId
        ? { primaryContactId: '' }
        : {}),
    }).subscribe();
  }

  assignAsPrimaryContact(connectId: string): void {
    this.update({
      opportunityId: this.opportunity.opportunityId,
      primaryContactId: connectId,
    }).subscribe();
  }

  private saveNewContact(
    contactRes: IClientContactForm
  ): Observable<IOpportunity> {
    const { options, ...contact } = contactRes;

    return this.usersService
      .updateUserForClientContact({
        clientContact: contact,
        opportunityData: {
          publicDisplayId: this.opportunity.publicDisplayId,
          opportunityName: this.opportunity.opportunityName,
          clientId: this.opportunity.clientId,
        },
        sendInviteEmail: !!options.sendInviteEmail,
        senderEmail: this.cognitoAuthService.loggedInUser.email,
      })
      .pipe(
        switchMap((ret) =>
          this.update({
            opportunityId: this.opportunity.opportunityId,
            clientContactIds: [
              ...new Set([...this.opportunity.clientContactIds, ret.connectId]),
            ],
          })
        )
      );
  }

  private update(update: IOpportunityUpdateRequest): Observable<IOpportunity> {
    return this.opportunitiesService.update(update).pipe(
      tap((res) => {
        this.opportunity = res;
        this.contactList = [...res.clientContacts];
      })
    );
  }

  private openModal(
    data?: Partial<IClientContactForm>
  ): Observable<IClientContactForm> {
    const dialogRef = this.dialog.open<
      ClientContactDialogComponent,
      Partial<IClientContactForm>,
      IClientContactForm
    >(ClientContactDialogComponent, {
      width: '840px',
      data,
    });
    return dialogRef.afterClosed().pipe(takeWhile((d) => !!d));
  }
}
