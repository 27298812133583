import { IUserBase, UserType } from '@techspert-io/auth';

export interface IUserCreateRequest extends Partial<IUserBase> {
  email: string;
  firstName: string;
  lastName: string;
  userType: UserType;
}

export interface IGetUsersRequest {
  clientIds?: string[];
  userTypes?: UserType[];
}

export interface IUpsertUserForClientContactRequest {
  clientContact: IUpsertUserForClientContactRequestContact;
  opportunityData: IUpdateUserForClientContactRequestOpportunityData;
  sendInviteEmail: boolean;
  senderEmail: string;
}

export interface IUpsertUserForClientContactRequestContact {
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  timezone: { abbr: string; name: string; offset: string };
}

export interface IUpdateUserForClientContactRequestOpportunityData {
  publicDisplayId: string;
  opportunityName: string;
  clientId: string;
}
