import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  EngagementsCreateDefaultsService,
  IEngagement,
  PaymentsService,
} from '@techspert-io/engagements';
import {
  ExpertAvailabilitiesService,
  IExpertAction,
  IExpertAvailabilitiesAction,
  IExpertAvailabilitiesActionTimeSlot,
} from '@techspert-io/expert-actions';
import {
  ConnectPhase,
  connectPhaseList,
  ExpertsQueryService,
  ExpertsUpdateService,
  IDisplayExpert,
  IExpert,
  IExpertUpdateRequest,
  ReasonForRejection,
  ValidationError,
} from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import * as MomentTz from 'moment-timezone';
import { combineLatest, EMPTY, from, merge, Observable, of } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  reduce,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ITimezoneWithCountry } from '../../../../../shared/models/country';
import { CurrencyService } from '../../../../../shared/services/currency.service';
import { INewEngagement } from './expert-profile-billing/expert-profile-billing.component';
import { IExpertProfileDialogInput } from './expert-profile-dialog-models';

interface ISelect<V = string, D = string> {
  value: V;
  display: D;
}

@Component({
  selector: 'app-expert-profile-dialog',
  templateUrl: './expert-profile-dialog.component.html',
  styleUrls: ['./expert-profile-dialog.component.scss'],
})
export class ExpertProfileDialogComponent implements OnInit {
  @Output() public expertSavedSignal = new EventEmitter<IExpert>();
  @Output() public updatedEngagementsSignal = new EventEmitter<IEngagement[]>();

  public availabilitiesAction: Omit<
    IExpertAvailabilitiesAction,
    keyof IExpertAction
  > & { expertActionId?: string; expertId: string };
  public title = 'Expert profile';
  public opportunity: IOpportunity;
  public newCallTime: string;
  public momentTz = MomentTz;
  public displayTab: string = 'details';
  public updateErrors: string[];
  public expert: IDisplayExpert;
  public expertAvailability: IExpertAvailabilitiesActionTimeSlot[] = [];
  public engagements: IEngagement[];
  public showPaymentNotes: boolean = false;
  public sentToClientDate: string;
  public prev_bios: { title: string; bio: string }[] = [];
  public isPocEmailValid = true;

  rejectionReasons: ISelect<ReasonForRejection>[] = [
    { value: 'Wrong geography', display: 'Wrong geography' },
    { value: 'Irrelevant expertise', display: 'Irrelevant expertise' },
    { value: 'Scope change', display: 'Scope change' },
    { value: 'Project cancellation', display: 'Project cancellation' },
    { value: 'Quota reached', display: 'Quota reached' },
    { value: 'Client dropped off', display: 'Client dropped off' },
    { value: 'Client rejected', display: 'Client rejected' },
    { value: 'Other', display: 'Other' },
    { value: 'Emails bounced', display: 'Emails bounced' },
    { value: 'Failed screener', display: 'Failed screener' },
    { value: 'NULL', display: 'N/A' },
  ];

  connectPhases: ISelect<ConnectPhase>[] = [
    { value: 'identified', display: 'Identified' },
    { value: 'firstFollowUp', display: 'Follow up 1' },
    { value: 'secondFollowUp', display: 'Follow up 2' },
    { value: 'outreachComplete', display: 'Outreach complete' },
    { value: 'outreach', display: 'Responded' },
    { value: 'screener', display: 'Screener' },
    { value: 'sentToClient', display: 'In Portal' },
    { value: 'accepted', display: 'Accepted' },
    { value: 'scheduled', display: 'Scheduled' },
    { value: 'completed', display: 'Completed' },
  ];

  isSaving: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ExpertProfileDialogComponent>,
    public paymentsService: PaymentsService,
    public dialog: MatDialog,
    public toastService: ToastService,
    private expertsService: ExpertsUpdateService,
    private expertsQueryService: ExpertsQueryService,
    private currencyService: CurrencyService,
    @Inject(MAT_DIALOG_DATA) public data: IExpertProfileDialogInput,
    private engagementsCreateDefaultsService: EngagementsCreateDefaultsService,
    private expertAvailabilitiesService: ExpertAvailabilitiesService
  ) {
    this.opportunity = {
      ...data.opportunity,
      anticipatedCallTime: data.opportunity?.anticipatedCallTime || 60,
    };
  }

  public ngOnInit(): void {
    const expertId =
      typeof this.data.expert === 'string'
        ? this.data.expert
        : this.data.expert.expertId;

    const expert$ =
      typeof this.data.expert === 'string'
        ? this.expertsQueryService.getById(expertId)
        : of(this.data.expert);

    combineLatest([
      this.paymentsService.query({ expertId }),
      expert$,
      this.expertAvailabilitiesService.getById(expertId),
    ]).subscribe(([engagements, expert, actions]) => {
      this.availabilitiesAction = actions.find(
        (a) => a.slotRequestType === 'expert'
      ) || {
        slotDuration: (this.opportunity.anticipatedCallTime || 60) * 60,
        timeSlots: [],
        selectedSlots: [],
        slotRequestType: 'expert' as const,
        expertId: expert.expertId,
        expertActionId: undefined,
      };
      this.expertAvailability = this.availabilitiesAction.timeSlots;
      this.expert = expert;
      this.engagements = engagements;
      if (this.data.focus?.displayTab) {
        this.viewTab(this.data.focus.displayTab);
      }
    });
  }

  public viewTab(
    tabName:
      | 'availability'
      | 'details'
      | 'billing'
      | 'validation'
      | 'bio'
      | 'screener'
      | 'clientNotes'
      | 'files'
      | 'previous-experts'
  ): void {
    this.displayTab = tabName;
  }

  // Availibility:
  public updateTimezoneAndCountry(
    timezoneAndCountry: ITimezoneWithCountry
  ): void {
    this.expert.timezoneName = timezoneAndCountry.timezone;
    this.expert.country = timezoneAndCountry.country;
  }

  public deleteAvailability(stateDate: string): void {
    this.expertAvailability = this.expertAvailability.filter(
      (availabilityObj) => availabilityObj.start !== stateDate
    );
  }

  public deleteAllAvailabilities(): void {
    this.expertAvailability = [];
  }

  public deleteCallTime(): void {
    this.expert.callTime = null;
  }

  public assignAvailability(
    availability: IExpertAvailabilitiesActionTimeSlot
  ): void {
    if (this.expertAvailability?.length) {
      if (
        !this.expertAvailability.some(
          (a) =>
            new Date(a.start).getTime() ===
            new Date(availability.start).getTime()
        )
      ) {
        this.expertAvailability.push(availability);
        this.expertAvailability = this.expertAvailability.sort((a, b) => {
          const sa = new Date(a.start).getTime();
          const sb = new Date(b.start).getTime();
          return sa < sb ? -1 : sa > sb ? 1 : 0;
        });
        this.expert.requestedAnotherTime = false;
      } else {
        this.toastService.sendMessage(
          'Duplicate availability entered! Duplicates omitted',
          'error'
        );
      }
    } else {
      this.expertAvailability = [availability];
      this.expert.requestedAnotherTime = false;
    }
  }

  public updateCallTime(date: string): void {
    const newCallTime = this.momentTz
      .tz(date, this.expert.timezoneName)
      .toISOString();

    const callTimeAvailabilityAlreadyExists = this.expertAvailability.some(
      (availability) => availability.start === newCallTime
    );
    if (!callTimeAvailabilityAlreadyExists) {
      const endTime = this.momentTz
        .tz(
          this.momentTz(date)
            .add(this.opportunity.anticipatedCallTime, 'minutes')
            .format('YYYY-MM-DDTHH:mm'),
          this.expert.timezoneName
        )
        .toISOString();
      this.expertAvailability.push({
        start: newCallTime,
        end: endTime,
      });
    }
    this.expert.callTime = newCallTime;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  // Bio Logic:
  public saveBio(payload: IExpertUpdateRequest): void {
    this.updateExpert(payload)
      .pipe(catchError((res) => this.handleUpdateErrors(res)))
      .subscribe();
  }

  // Close/Save logic:

  public save(willCloseDialog = false): void {
    const targetPhaseIndex = connectPhaseList.indexOf(this.expert.connectPhase);
    // Check on primaryEmail & currency as backend validation currently sees empty strings as valid for required properties, engagement validation should also be in the backend

    const expertThirdParty = this.expert.source === 'third-party';

    if (
      !expertThirdParty &&
      this.expert.primaryEmail &&
      !this.isPocEmailValid
    ) {
      this.toastService.sendMessage('Invalid PoC email', 'error');
    } else if (
      !expertThirdParty &&
      !this.expert.primaryEmail &&
      targetPhaseIndex >= 4
    ) {
      if (!this.expert.currency && targetPhaseIndex >= 6) {
        this.toastService.sendMessage(
          'Please provide a PoC email & currency',
          'error'
        );
      } else {
        this.toastService.sendMessage('Please provide a PoC email', 'error');
      }
    } else if (
      this.expert.costBand !== 'Standard' &&
      this.expert.unitsPerHour < 0.01
    ) {
      this.toastService.sendMessage('unitsPerHour must be >= 0.01', 'error');
    } else if (!this.expert.opportunitySegmentId && targetPhaseIndex >= 5) {
      this.toastService.sendMessage(
        'Please provide a Geographic target & Profile type',
        'error'
      );
    } else if (
      this.expert.currency &&
      !this.currencyService.currencyCodes.includes(this.expert.currency)
    ) {
      this.toastService.sendMessage('Please provide a valid currency', 'error');
    } else if (
      !this.expert.currency &&
      ((targetPhaseIndex >= 6 && !expertThirdParty) || this.engagements.length)
    ) {
      this.toastService.sendMessage('Please provide a currency', 'error');
    } else if (
      this.engagements.find((e) => e.engagementType === 'payment' && !e.reason)
    ) {
      this.toastService.sendMessage(
        'Please ensure all Payment engagements have a payment reason selected',
        'error'
      );
    } else if (
      this.engagements.find((e) => e.engagementType === 'call' && e.rate < 0)
    ) {
      this.toastService.sendMessage(
        'Please ensure all Payment engagements have a valid call rate',
        'error'
      );
    } else if (
      this.engagements.find(
        (e) => e.paymentType === 'thirdParty' && !e.paymentProvider
      )
    ) {
      this.toastService.sendMessage(
        'Please ensure all Third Party engagements have a third party selected',
        'error'
      );
    } else if (!this.expert.firstName?.trim()) {
      this.toastService.sendMessage('Firstname is required', 'error');
    } else if (!this.expert.lastName?.trim()) {
      this.toastService.sendMessage('Lastname is required', 'error');
    } else {
      this.isSaving = true;

      merge(
        this.upsertAvailabilities(this.expert.callTime),
        this.updateExpert(this.expert).pipe(
          tap((expert) =>
            this.toastService.sendMessage(
              `${expert.firstName} ${expert.lastName} details updated`,
              'success'
            )
          ),
          tap((expert) => this.expertSavedSignal.emit(expert)),
          switchMap(() => this.updateEngagements()),
          tap((e) => {
            this.engagements = e;
            if (this.expert.connectPhase === 'completed')
              this.updatedEngagementsSignal.emit(e);
          }),
          tap(() => willCloseDialog && this.closeDialog()),
          catchError((err) => this.handleUpdateErrors(err)),
          finalize(() => (this.isSaving = false))
        )
      ).subscribe();
    }
  }

  private upsertAvailabilities(
    callTime: string
  ): Observable<IExpertAvailabilitiesAction> {
    const basePayload = {
      expertId: this.availabilitiesAction.expertId,
      timeSlots: this.expertAvailability,
      selectedSlots: this.expertAvailability.filter(
        (a) => a.start === callTime
      ),
      slotDuration: (this.opportunity.anticipatedCallTime || 60) * 60,
      slotRequestType: this.availabilitiesAction.slotRequestType,
      ...(this.availabilitiesAction.expertActionId
        ? { expertActionId: this.availabilitiesAction.expertActionId }
        : {}),
    };

    const upsertFn$ = basePayload.expertActionId
      ? this.expertAvailabilitiesService.update({
          ...basePayload,
          expertActionId: basePayload.expertActionId,
        })
      : this.expertAvailabilitiesService.create(basePayload);

    return upsertFn$.pipe(
      tap((action) => (this.availabilitiesAction = action))
    );
  }

  private updateExpert(expert: IExpertUpdateRequest): Observable<IExpert> {
    this.updateErrors = undefined;
    return this.expertsService.updateOne(expert);
  }

  private handleUpdateErrors(err: Error): Observable<string[]> {
    if (err instanceof ValidationError) {
      this.viewTab('validation');
      this.updateErrors = err.error.messages;
    } else {
      console.error('Unknown Error', err);
    }
    return EMPTY;
  }

  private updateEngagements(): Observable<IEngagement[]> {
    if (!this.engagements.length) {
      return of([]);
    }
    return from(this.engagements).pipe(
      map((e) => {
        const setDefault = (key: string): number =>
          !e[key] || isNaN(+e[key]) ? 0 : e[key];

        return {
          ...e,
          unitsUsedAdjustment: setDefault('unitsUsedAdjustment'),
          amountOwed: setDefault('amountOwed'),
          currency: this.expert.currency,
        };
      }),
      mergeMap((engagement) => this.paymentsService.updateLegacy(engagement)),
      reduce((prev: IEngagement[], curr) => [...prev, curr], [])
    );
  }

  public updateSentToClientDate(timestamp: number): void {
    if (
      this.expert.phaseTimestamps.some(
        (phaseTimeStamp) => phaseTimeStamp.phase === 'sentToClient'
      )
    ) {
      this.expert.phaseTimestamps = this.expert.phaseTimestamps.map(
        (phaseTimeStamp) => {
          if (phaseTimeStamp.phase === 'sentToClient') {
            phaseTimeStamp.timestamp = timestamp;
          }
          return phaseTimeStamp;
        }
      );
    } else {
      this.expert.phaseTimestamps = [
        ...this.expert.phaseTimestamps,
        {
          phase: 'sentToClient',
          timestamp: timestamp,
        },
      ];
    }
  }

  public deleteEngagement(engagementId: string): void {
    this.paymentsService
      .delete(engagementId)
      .pipe(
        tap(() => {
          this.engagements = this.engagements.filter(
            (engagement) => engagement.engagementId !== engagementId
          );
        })
      )
      .subscribe();
  }

  public addEngagement(newEngagement: INewEngagement): void {
    if (this.validateAddEngagementRequest()) {
      this.paymentsService
        .create(
          this.engagementsCreateDefaultsService.createEngagement(
            newEngagement,
            this.opportunity,
            this.expert,
            this.sentToClientDate
          )
        )
        .pipe(
          tap((e) => {
            this.engagements = [...this.engagements, e];
          })
        )
        .subscribe();
    }
  }

  private validateAddEngagementRequest(): boolean {
    if (!this.expert.primaryEmail && !this.expert.currency) {
      this.toastService.sendMessage(
        'Please assign a poc email & currency to create a engagement',
        'error'
      );
      return false;
    } else if (!this.expert.primaryEmail) {
      this.toastService.sendMessage(
        'Please assign a poc email to create a engagement',
        'error'
      );
      return false;
    } else if (!this.expert.currency) {
      this.toastService.sendMessage(
        'Please assign a currency to create a engagement',
        'error'
      );
      return false;
    }
    return true;
  }
}
