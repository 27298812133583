import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  EngagementPaymentReasons,
  paymentReasonsList,
  PaymentsService,
} from '@techspert-io/engagements';
import { IPaymentDashboardEngagement } from '../payments-models';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent {
  public engagement: IPaymentDashboardEngagement;
  public paymentReasons: EngagementPaymentReasons[] = paymentReasonsList;

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { engagement: IPaymentDashboardEngagement },
    private paymentsService: PaymentsService
  ) {
    this.engagement = this.data.engagement;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveChanges(): void {
    this.paymentsService
      .updateLegacy({
        engagementId: this.engagement.engagementId,
        notes: this.engagement.notes,
        transactionId: this.engagement.transactionId,
        ...(this.engagement.engagementType === 'payment'
          ? { reason: this.engagement.reason }
          : {}),
      })
      .subscribe(() => {
        this.closeDialog();
      });
  }
}
