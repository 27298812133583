import { IEngagement } from '@techspert-io/engagements';

export const engagementMockData: IEngagement = {
  engagementId: '1234b',
  engagementTitle: 'title',
  engagementType: 'call',
  dateOfEngagement: '2011-10-05T00:00:00.000Z',
  currency: 'USD',
  paymentStatus: 'not-paid',
  rate: 0,
  quantityEngaged: 0,
  unitsUsed: 0,
  unitsUsedAdjustment: 0,
  amountOwed: 0,
  paymentType: 'transferwise',
  notes: '',
  transactionId: '',
  email: 'exp@email.com',
  opportunityName: 'oppName',
  opportunityId: 'oppId',
  expertName: 'expertName',
  expertId: 'expertId',
  paymentActive: false,
  paymentProvider: 'Techspert',
  lastAccepted: '2011-10-05T00:00:00.000Z',
};
