import { Injectable } from '@angular/core';
import { IExpert } from '@techspert-io/experts';
import { IOpportunity } from '@techspert-io/opportunities';
import {
  EngagementPaymentReasons,
  EngagementType,
  IEngagement,
} from '../models/engagements.models';
import { EngagementsTitleService } from './engagements-title.service';

@Injectable()
export class EngagementsCreateDefaultsService {
  constructor(private engagementsTitleService: EngagementsTitleService) {}

  createEngagement(
    newEngagement: {
      engagementType: EngagementType;
      reason?: EngagementPaymentReasons;
    },
    opportunity: IOpportunity,
    expert: IExpert,
    sentToClientDate?: string | number | Date
  ): IEngagement {
    const engagement: IEngagement = {
      engagementTitle: this.engagementsTitleService.createEngagementTitle(
        newEngagement.engagementType,
        opportunity.opportunityName,
        expert
      ),
      engagementType: newEngagement.engagementType,
      dateOfEngagement: new Date().toISOString(),
      paymentStatus: 'not-paid',
      rate: 0,
      quantityEngaged: 0,
      unitsUsed: 0,
      unitsUsedAdjustment: 0,
      amountOwed: 0,
      paymentType: 'transferwise',
      notes: '',
      transactionId: '',
      currency: expert.currency,
      email: expert.primaryEmail,
      opportunityName: opportunity.opportunityName,
      opportunityId: opportunity.opportunityId,
      expertName: `${expert.firstName} ${expert.lastName}`,
      expertId: expert.expertId,
      paymentActive: false,
      lastAccepted: '',
      paymentProvider: 'Techspert',
    };
    if (sentToClientDate) {
      engagement.lastAccepted = new Date(sentToClientDate).toISOString();
    }
    if (newEngagement.engagementType === 'payment') {
      engagement.reason = newEngagement.reason;
    }
    return engagement;
  }
}
