import { IDataAccessLogs, IExpertActionBase } from './expertActions.models';

export interface IExpertAvailabilitiesAction
  extends IExpertAvailabilitiesActionBase,
    IDataAccessLogs {}

export interface IExpertAvailabilitiesActionTimeSlot {
  start: string;
  end: string;
}

export interface IExpertAvailabilitiesActionBase extends IExpertActionBase {
  actionType: 'availabilities';
  slotRequestType: 'expert' | 'client';
  slotDuration: number; // seconds
  timeSlots: IExpertAvailabilitiesActionTimeSlot[];
  selectedSlots: IExpertAvailabilitiesActionTimeSlot[];
  acceptedBy?: string;
  acceptedDate?: string;
}
