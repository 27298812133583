import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectUser } from '@techspert-io/auth';
import { ExpertsQueryService } from '@techspert-io/experts';
import { OpportunitiesService } from '@techspert-io/opportunities';
import { UserService } from '@techspert-io/users';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExpertProfileDialogComponent } from '../../../../admin-portal/clients/client-opportunity-searches/opportunity-results/expert-profile-dialog/expert-profile-dialog.component';
import { OpportunityViewDialogComponent } from '../../../../shared/components/opportunity-view-dialog/opportunity-view-dialog.component';
import {
  IOmnisearchOpportunity,
  OmnisearchEntity,
  OmnisearchQueryResponse,
} from '../../models/omnisearch.models';

@Component({
  selector: 'app-omnisearch-result',
  templateUrl: './omnisearch-result.component.html',
  styleUrls: ['./omnisearch-result.component.scss'],
})
export class OmnisearchResultComponent {
  @Input() omnisearchResponse: OmnisearchQueryResponse;
  @Input() searchTerm: string;

  users$: Observable<Record<string, ConnectUser>> = this.usersService
    .getAll({ userTypes: ['PM'] })
    .pipe(
      map((u) =>
        u.reduce<Record<string, ConnectUser>>(
          (prev, curr) => ({
            ...prev,
            [curr.connectId]: curr,
          }),
          {}
        )
      )
    );

  constructor(
    private dialog: MatDialog,
    private expertsQueryService: ExpertsQueryService,
    private opportunitiesService: OpportunitiesService,
    private usersService: UserService
  ) {}

  getFullName(user?: Partial<ConnectUser>): string {
    if (!user) {
      return 'unknown';
    }

    return user.firstName + ' ' + user.lastName;
  }

  openDetailsDialog(): void {
    if (this.omnisearchResponse.entity === 'opportunities') {
      this.openOpportunityDialog(this.omnisearchResponse);
    } else if (
      this.omnisearchResponse.entity === OmnisearchEntity.experts ||
      this.omnisearchResponse.entity === OmnisearchEntity.solicitationExperts
    ) {
      this.openExpertsDialog(this.omnisearchResponse);
    }
  }

  private openOpportunityDialog({
    id,
    clientId,
  }: IOmnisearchOpportunity): void {
    this.dialog.open(OpportunityViewDialogComponent, {
      width: '1190px',
      height: '830px',
      data: {
        opportunityId: id,
        client: {
          clientId,
        },
        showGoToOpportunity: true,
      },
    });
  }

  private openExpertsDialog({
    id,
    oppId,
  }: {
    id: string;
    oppId: string;
  }): void {
    combineLatest([
      this.expertsQueryService.getById(id),
      this.opportunitiesService.get(oppId),
    ]).subscribe(([expert, opportunity]) => {
      this.dialog.open(ExpertProfileDialogComponent, {
        width: '1190px',
        height: '830px',
        data: {
          expert: expert,
          opportunity: opportunity,
        },
      });
    });
  }
}
