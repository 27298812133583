import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, reduce } from 'rxjs/operators';
import {
  ConnectPhase,
  IDisplayExpert,
  IQueryExpertSearchRequest,
} from '../models/experts.models';

export interface IQueryExpertSearchResponse {
  experts: IDisplayExpert[];
  totals: Record<ConnectPhase, number>;
}

export type SearchQueryResponse = {
  searchId: string;
  experts: IDisplayExpert[];
  totals: Record<ConnectPhase, number>;
};

@Injectable({
  providedIn: 'root',
})
export class ExpertsQueryService {
  private readonly baseUrl = '/experts';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  query(request: IQueryExpertSearchRequest): Observable<SearchQueryResponse[]> {
    return from(request.searchIds.slice(0).sort()).pipe(
      mergeMap((searchId) =>
        this.http
          .get<IQueryExpertSearchResponse>(this.baseUrl, {
            params: {
              opportunityId: request.opportunityId,
              searchId: encodeURIComponent(searchId),
              ...(request.fromPhase ? { fromPhase: request.fromPhase } : {}),
            },
          })
          .pipe(
            map(({ experts, totals }) => ({ experts, totals, searchId })),
            catchError(() => {
              this.toastService.sendMessage(
                `Retrieving data for '${searchId}' failed`,
                'error'
              );
              return of({
                experts: [],
                totals: {
                  identified: 0,
                  firstFollowUp: 0,
                  secondFollowUp: 0,
                  outreachComplete: 0,
                  outreach: 0,
                  screener: 0,
                  sentToClient: 0,
                  accepted: 0,
                  scheduled: 0,
                  completed: 0,
                },
                searchId,
              });
            })
          )
      ),
      reduce<
        IQueryExpertSearchResponse & { searchId: string },
        SearchQueryResponse[]
      >((acc, curr) => [...acc, curr], [])
    );
  }

  getById(expertId: string): Observable<IDisplayExpert> {
    return this.http.get<IDisplayExpert>(`${this.baseUrl}/${expertId}`);
  }
}
