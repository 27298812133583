import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { IClient } from '@techspert-io/clients';
import { IOpportunity } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import { ExportToCsv } from 'export-to-csv';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { EmailService } from '../../shared/services/email.service';
import { ICommercialEnrichmentResultDict } from './models/experts/search-response/commercial-enrichment';
import {
  ICommercialSearchQuery,
  ILinkedInEnrichmentQuery,
  ISearchQuery,
  SearchQuery,
} from './models/query';
import {
  ISearchDisplayExpert,
  ISearchReturn,
  ISelectOpportunityDialogData,
} from './models/search-models';
import { ResultsDisplayFilterService } from './results-display-filter/results-display-filter.service';
import { SelectOpportunityDialogComponent } from './select-opportunity-dialog/select-opportunity-dialog.component';
import { SearchService } from './services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output() public dialogModeUpdatedOpportunitySignal = new EventEmitter();
  @Input() dialogMode: boolean = false;
  @Input() opportunity: IOpportunity = null;
  @Input() client: IClient = null;
  @Input() searchName?: string;
  @Input() query?: SearchQuery;
  public selectedCount: number;
  public selectAllToggle: boolean = false;
  public selectionConfiguration: 'emails' | 'all' = 'all';
  public showSelectionOptions: boolean = false;
  public experts: ISearchDisplayExpert[] = [];
  public erroredUrls: string[] = [];
  public filteredExperts: ISearchDisplayExpert[] = [];
  public showSearchLoader: boolean = false;
  public showCommercialView: boolean = false;
  public viewSummary: boolean = false;
  public queryExpertTotal: number;

  private destroy$ = new Subject();

  get opportunityName(): string {
    return this.opportunity ? `(${this.opportunity.opportunityName})` : '';
  }

  constructor(
    public router: Router,
    public searchService: SearchService,
    public emailService: EmailService,
    public cognitoAuthService: CognitoAuthService,
    public dialog: MatDialog,
    public toastService: ToastService,
    private filters: ResultsDisplayFilterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        filter((d) => !!d.metaData),
        map((d) => d.metaData),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.client = data.client;
        this.opportunity = data.opportunity;
        this.query = data.query;
        this.dialogMode = true;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public reRunPdlEnrichment(): void {
    if (this.query.service === 'pdl-enrichment') {
      this.runSearch(
        {
          service: this.query.service,
          attributes: this.erroredUrls,
          pdlEnrichmentService: this.query.pdlEnrichmentService,
        },
        true
      );
    }
  }

  public assignSelectionConfiguration(option: 'emails' | 'all'): void {
    this.selectionConfiguration = option;
  }

  public runSearch(
    payload: ISearchQuery | ICommercialSearchQuery | ILinkedInEnrichmentQuery,
    appendSearch?: boolean
  ): void {
    this.filters.reset();
    this.viewSummary = false;
    this.showSearchLoader = true;
    this.query = payload;
    this.showSelectionOptions = payload.service === 'pdl-enrichment';
    this.showCommercialView = payload.service === 'pdl-commercial';
    if (payload.service === 'pdl-enrichment') {
      this.runPdlEnrichment(payload, appendSearch).subscribe();
    } else {
      this.runSearches(payload).subscribe();
    }
  }

  public clearExperts(): void {
    this.filters.reset();
    this.viewSummary = false;
    this.experts = [];
    this.filteredExperts = [];
    this.selectedCount = 0;
    this.selectAllToggle = false;
  }

  public filterExperts(term: string): void {
    this.filteredExperts = this.experts.filter((expert) =>
      Object.values(expert.source).some((value) =>
        JSON.stringify(value).toLowerCase().includes(term.toLowerCase())
      )
    );
  }

  public filterExpertsAdv(filterExperts: ISearchDisplayExpert[]): void {
    this.filteredExperts = filterExperts;
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public toggleSelectExpert(id: string): void {
    this.filteredExperts = this.filteredExperts.map((expert) => {
      if (expert.id === id) {
        expert.selected = !expert.selected;
      }
      return expert;
    });
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public toggleSelectExperts(): void {
    this.selectAllToggle = !this.selectAllToggle;
    if (this.selectionConfiguration === 'emails') {
      this.toggleSelectEmailsOnly();
    } else {
      this.toggleSelectAll();
    }
    this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
  }

  public downloadExpertListCsv(): void {
    const date = new Date().toISOString().slice(0, 10);
    const formattedExperts = this.filteredExperts.map((e) =>
      this.formatForCSV(e.source)
    );

    const csvExporter = new ExportToCsv({
      fieldSeparator: ',',
      filename: `Expert-list-${date}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Expert-list-${date}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(formattedExperts);
  }

  public openSendExpertsDialog(): void {
    const selectedExperts = this.filteredExperts.filter(
      (expert) => expert.selected
    );

    if (this.query.service === 'pdl-enrichment') {
      delete this.query.attributes;
    }
    const sendExpertsDialogData: ISelectOpportunityDialogData = {
      selectedCount: selectedExperts.length,
      experts: selectedExperts,
      dialogMode: this.dialogMode,
      opportunity: this.opportunity,
      client: this.client,
      query: this.query,
      source: this.query.service,
    };
    if (this.searchName) {
      sendExpertsDialogData.searchName = this.searchName;
    }
    const dialogRef = this.dialog.open(SelectOpportunityDialogComponent, {
      // add search name for extend search
      data: sendExpertsDialogData,
      width: '600px',
      height: 'max-content',
    });
    dialogRef.componentInstance.dialogModeUpdatedOpportunitySignal.subscribe(
      (updatedOpportunityPayload) => {
        this.query = null;
        this.searchName = null;
        this.dialogModeUpdatedOpportunitySignal.emit(updatedOpportunityPayload);
      }
    );
  }

  private runSearches(
    payload: ISearchQuery | ICommercialSearchQuery
  ): Observable<ISearchReturn> {
    return this.searchService.search(payload).pipe(
      tap(
        (data) => {
          if (Array.isArray(data.experts) && !data.experts.length) {
            this.toastService.sendMessage(
              'No experts found for your query. Please try a different query.',
              'error'
            );
          }
          this.showSearchLoader = false;
          if (payload.service === 'pdl-commercial') {
            this.experts = [...data.experts, ...this.experts];
            this.filteredExperts = this.experts;
          } else {
            this.experts = data.experts;
            this.filteredExperts = data.experts;
          }
          this.selectedCount = this.calculateSelectedCount(
            this.filteredExperts
          );
        },
        (error: Error): void => {
          this.showSearchLoader = false;
          if (error.message) {
            switch (error.message) {
              case 'API rate limit (10 requests per second) has exceeded':
                this.toastService.sendMessage(
                  'Commercial search at capacity, please try again shortly',
                  'error'
                );
                break;
              default:
                this.toastService.sendMessage(error.message, 'error');
            }
          }
        }
      )
    );
  }

  private calculateSelectedCount(experts): number {
    return experts.filter((expert) => expert.selected).length;
  }

  private toggleSelectAll(): void {
    this.filteredExperts = this.filteredExperts.map((e) => {
      e.selected = this.selectAllToggle;
      return e;
    });
  }

  private toggleSelectEmailsOnly(): void {
    if (this.selectAllToggle) {
      this.filteredExperts = this.filteredExperts.map((e) => {
        if (e.source.opportunityEmails?.length > 0) {
          e.selected = this.selectAllToggle;
        }
        return e;
      });
    } else {
      this.toggleSelectAll();
    }
  }

  private runPdlEnrichment(
    payload: ILinkedInEnrichmentQuery,
    append: boolean
  ): Observable<ICommercialEnrichmentResultDict> {
    return this.searchService.parseAndSearchLinkedIn(payload).pipe(
      tap((linkedInResponse) => {
        if (!linkedInResponse.success.length) {
          this.toastService.sendMessage(
            'No experts found for your query. Please try a different query.',
            'error'
          );
        }
        if (linkedInResponse.fail.length) {
          this.toastService.sendMessage(
            'Commercial search limit exceeded, some failed ',
            'error'
          );
        }
        this.showSearchLoader = false;
        this.experts = append
          ? [...(this.experts || []), ...linkedInResponse.success]
          : linkedInResponse.success;
        this.filteredExperts = this.experts;
        this.selectedCount = this.calculateSelectedCount(this.filteredExperts);
        this.erroredUrls = linkedInResponse.fail;
      })
    );
  }

  private formatForCSV(
    expert: ISearchDisplayExpert['source']
  ): Record<string, string> {
    return Object.entries(expert).reduce((prev, [key, val]) => {
      if (key === 'opportunityEmails') {
        return { ...prev, otherEmails: val.join(', ') };
      }
      if (Array.isArray(val)) {
        return { ...prev, [key]: val.map((d) => d).join(', ') };
      }
      if (key === 'searchExpertData' && typeof val === 'object') {
        return {
          ...prev,
          searchExpertId: val.id || '',
          searchTrackingId: val.trackingId || '',
        };
      }
      return { ...prev, [key]: val || '' };
    }, {});
  }
}
