import { Component, Input, OnInit } from '@angular/core';
import { IDisplayExpert } from '@techspert-io/experts';

@Component({
  selector: 'app-expert-profile-screener',
  templateUrl: './expert-profile-screener.component.html',
  styleUrls: ['./expert-profile-screener.component.scss'],
})
export class ExpertProfileScreenerComponent implements OnInit {
  @Input() public expert: IDisplayExpert;

  ngOnInit(): void {
    if (!this.expert.screenerResponse) {
      this.expert.screenerResponse = '';
    }
  }
}
