import { Component, Input, OnInit } from '@angular/core';
import {
  ExpertRejectionReasonPipe,
  IExpert,
  IExpertProp,
} from '@techspert-io/experts';
import { IOmnisearchExpert, OmnisearchService } from '@techspert-io/omnisearch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IExpertDataSource {
  opportunityName: string;
  expertLink: string[];
  dataSource: IExpertProp[];
}

@Component({
  selector: 'app-expert-profile-previous-experts',
  templateUrl: './expert-profile-previous-experts.component.html',
  styleUrls: ['./expert-profile-previous-experts.component.scss'],
  providers: [ExpertRejectionReasonPipe],
})
export class ExpertProfilePreviousExpertsComponent implements OnInit {
  @Input() expert: IExpert;

  displayedColumns = ['field', 'value', 'copy'];
  dataSource$: Observable<IExpertDataSource[]>;

  constructor(private omnisearchService: OmnisearchService) {}

  ngOnInit(): void {
    this.dataSource$ = this.omnisearchService
      .getPreviousEngagements({
        emailAddress: this.expert.primaryEmail,
        searchExpertId: this.expert.searchExpertData?.id,
      })
      .pipe(map((experts) => this.formatExperts(experts)));
  }

  private formatExperts(experts: IOmnisearchExpert[]): IExpertDataSource[] {
    return experts
      .filter((e) => e.id !== this.expert.expertId)
      .map((expert) => this.mapExperts(expert));
  }

  private mapExperts(expert: IOmnisearchExpert): IExpertDataSource {
    const dataSource = Object.entries(expert)
      .filter(([k]) =>
        [
          'currency',
          'profileType',
          'geographicTarget',
          'timezoneName',
          'bio',
        ].includes(k)
      )
      .map(([field, value]) => ({
        field,
        value,
      }));

    const expertLink = this.buildExpertLink(expert);

    return {
      opportunityName: expert.oppName,
      expertLink,
      dataSource,
    };
  }

  buildExpertLink(expert: IOmnisearchExpert): string[] {
    return [
      'admin',
      'client',
      expert.clientId,
      'opportunity',
      expert.oppId,
      'search',
      'all-searches',
      'phase',
      'completed',
      'details',
      expert.id,
    ];
  }
}
