import { ISolicitationOutreach } from '../../models/solicication-outreach.models';

export const baseSolicitationOutreachMock: ISolicitationOutreach = {
  emailId: 'emailId',
  recipient: 'recipient',
  expertId: 'expertId',
  batchId: 'batchId',
  connectPhase: 'outreach',
  searchExpertId: 'searchExpertId',
  trackingId: 'trackingId',
  opportunityId: 'opportunityId',
  dateCreated: 1234,
};
