import { IUserBase } from '../models/user';

export const mockConnectUser: IUserBase = {
  connectId: 'abcd',
  email: '123@email.com',
  firstName: 'Fuser',
  lastName: 'Luser',
  userType: 'PM',
  userRoles: [],
  roles: [],
  active: true,
  dateCreated: 123,
  lastUpdated: 1234,
  clientIds: ['cId'],
  country: 'US',
  timezone: {
    name: 'UTC',
    abbr: 'UTC',
    offset: '+00:00',
  },
  profile: {
    phoneNumber: '1234',
    jobTitle: 'associate',
    linkedInUrl: 'linked-in-url',
  },
};

export const mockClientConnectUser: IUserBase = {
  connectId: 'abcd',
  email: '123@email.com',
  firstName: 'Fuser',
  lastName: 'Luser',
  userType: 'Client',
  userRoles: ['projectUser'],
  roles: [],
  active: true,
  dateCreated: 123,
  lastUpdated: 1234,
  clientIds: ['cId'],
  country: 'UK',
  timezone: {
    name: 'UTC',
    abbr: 'UTC',
    offset: '+00:00',
  },
  profile: {},
};
