import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IClient } from '@techspert-io/clients';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';

export interface IUsersFilterEvent {
  userType: string[];
  name: string;
  clientId: string;
}

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss'],
})
export class UsersFilterComponent implements OnInit {
  @Input() clients: IClient[] = [];
  @Output() onChange = new EventEmitter<IUsersFilterEvent>();

  formGroup: FormGroup = new FormGroup({
    userType: new FormControl(null),
    name: new FormControl(null),
    client: new FormControl(null),
  });

  filteredClients$ = this.formGroup.get('client').valueChanges.pipe(
    startWith(''),
    map((value) => (typeof value === 'string' ? value : value?.clientName)),
    map((value) =>
      value
        ? this.clients.filter((client) =>
            client.clientName.toLowerCase().includes(value.toLowerCase())
          )
        : this.clients
    )
  );

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(250),
        map(
          (formData): IUsersFilterEvent => ({
            userType: formData.userType || [],
            name: formData.name,
            clientId:
              formData.client && formData.client.clientId
                ? formData.client.clientId
                : null,
          })
        ),
        tap((value) => this.onChange.emit(value))
      )
      .subscribe();
  }

  clientDisplayFn(client: IClient): string {
    return client && client.clientName ? client.clientName : '';
  }
}
