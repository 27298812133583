import { IUserBase } from '@techspert-io/auth';
import { IUpsertUserForClientContactRequest } from '@techspert-io/users';
import { ITimezone } from '../../../shared/models/country';

export interface IOpportunityOptions {
  callRecording: boolean;
  blind: boolean;
  screenerEnabled?: boolean;
  closedEmailSend: boolean;
  automatedScheduling: boolean;
  conferenceReminderDefaultSettings: IConferenceReminderDefaultSettings;
  failedScreenerEmailSend?: boolean;
  screenerComparisonEnabled?: boolean;
  completedScreenerEmailSend?: boolean;
  omnisearchHidden?: boolean;
  omnisearchExpertNotification?: boolean;
  screenshare: boolean; // TODO: Not sure how/if this is used
  callTranscripts: boolean; // TODO: Not sure how/if this is used
  cepLog: boolean; // TODO: Not sure how/if this is used
}

export interface IClientContact {
  connectId?: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  primary: boolean;
  timezone: ITimezone;
}

export interface IStatPhase {
  activeExperts: number;
  experts: number;
  clientRejected: number;
  adminRejected: number;
  blocked: number;
}

export interface IDisplayStat {
  geography: string;
  segment: string;
  screening: number;
  inPortal: number;
  connectionsScheduled: number;
  connectionsCompleted: number;
  connectionsConfirmed: number;
  target: number;
}

export interface ISearch {
  query: unknown;
  stats: IStats | {};
}

export interface ISearches {
  [key: string]: ISearch;
}

export interface IStats {
  [key: string]: {
    [key: string]: IBaseStats;
  };
}

export interface IBaseStats {
  identified?: IStatPhase;
  firstFollowUp?: IStatPhase;
  secondFollowUp?: IStatPhase;
  outreachComplete?: IStatPhase;
  outreach?: IStatPhase;
  screener?: IStatPhase;
  sentToClient?: IStatPhase;
  accepted?: IStatPhase;
  scheduled?: IStatPhase;
  completed?: IStatPhase;
}

export interface IAggregatedStats {
  geography: string;
  segment: string;
  stats: IBaseStats;
}

export interface IOpportunityAssignee {
  email: string;
  firstName: string;
  lastName: string;
  connectId?: string;
}

export interface IOpportunitySegment {
  geography: string;
  segment: string;
  amount: number;
  hidden: boolean;
  active: boolean;
  unitsPerEngagement?: number;
}

export interface IOpportunitySegmentWithId extends IOpportunitySegment {
  id: string;
}

export type IOpportunitySegments = Record<string, IOpportunitySegment>;
export type IOpportunitySegmentConfigs = Record<
  string,
  IOpportunitySegmentConfig
>;

type SalesforceStageName =
  | 'Not actively selling'
  | 'Actively selling'
  | 'Contract opportunity identified'
  | 'Initial discussion'
  | 'Outline proposal sent'
  | 'Formal contract negotiation'
  | 'Qualified'
  | 'Pre-Bid'
  | 'RFQ'
  | 'Repeat client, managed by PM team'
  | 'Project in progress'
  | 'To be invoiced'
  | 'First RFQ received'
  | 'Initial proposal sent'
  | 'First go ahead'
  | 'First project delivered - chasing for next'
  | 'Next projects being quoted and closed'
  | 'Stable Client - handed to PM and CS'
  | 'Closed Lost'
  | 'Closed Won'
  | 'Not relevant.';

type SalesforceFormOfConnection = 'Quant' | 'Qual';
type SegmentSurveyProvider = 'External' | 'Qualtrics';

export interface IOpportunitySlackChannel {
  name: string;
  id: string;
  type: string;
}

export interface IOpportunityClientContact extends IUserBase {
  primary?: boolean;
}

export interface IOpportunity extends IOpportunityOptions {
  closeDate: string;
  countries: string[];
  startDate: string;
  endDate: string;
  lastUpdated: string;
  opportunityId: string;
  opportunityName: string;
  opportunityOwner: string;
  salesforceId: string;
  status: string;
  clientContactIds: string[];
  primaryContactId?: string;
  clientContacts: IOpportunityClientContact[];
  expertTargetQuantity?: number;
  searches: ISearches;
  opportunityAssigneeIds: string[];
  opportunityAssignees: IUserBase[];
  anticipatedCallTime?: number;
  clientId: string;
  notes?: string;
  segments: IOpportunitySegments;
  onHold: boolean;
  stageName: SalesforceStageName;
  publicDisplayId: string;
  projectType: SalesforceFormOfConnection;

  files: string[];
  defaultConferenceTopic?: string;
  defaultSurveyProvider?: SegmentSurveyProvider;
  externalSurveyConfig?: IOpportunitySegmentConfigExternalSurvey;

  /* nullable due to legacy opps */
  segmentConfig?: IOpportunitySegmentConfigs;
  screenerConfig?: IOpportunityScreenerConfig;
  slackChannels: IOpportunitySlackChannel[];
}

export interface IConferenceReminderDefaultSettings {
  sendEmailBeforeSeconds: number[];
  enabled: boolean;
}

export interface ISalesForceOpportunity {
  sfOppId: string;
  sfAccountId: string;
  closeDate: string;
  clientContacts: IClientContact[];
  segments: IOpportunitySegments;
  countries: string[];
  name: string;
  ownerName: string;
  clientName: string;
  clientId: string;
  currency: string;
  lastModifiedDate: Date;
  blind: boolean;
  closedEmailSend: boolean;
  createSlackChannel: boolean;
  projectType: SalesforceFormOfConnection;
  defaultSurveyProvider: SegmentSurveyProvider;
}

export interface IOpportunityCreateRequest
  extends Partial<
    Omit<IOpportunity, 'clientContacts' | 'opportunityAssignees'>
  > {
  clientId: string;
  sfOpportunityId: string;
  createSlackChannel: boolean;
  pmEmail: string;
  opportunityName: string;
  countries: string[];
  closeDate: string;
  opportunityOwner: string;
  notes?: string;
  segments: IOpportunitySegments;
  clientContactRequests: Omit<
    IUpsertUserForClientContactRequest,
    'opportunityData' | 'senderEmail'
  >[];
  primaryContactEmail: string;
}

export interface IOpportunitySegmentConfigKeys extends Record<string, string> {
  availabilityStart?: string;
  availabilityEnd?: string;
}

export interface IOpportunitySegmentConfig {
  screener?: {
    segmentId: string;
    segmentName: string;
    mailingListId: string;
    surveyId: string;
    libraryId: string;
    emailSubject: string;
    messageId: string;
  };
  owner: string[];
  cover: string[];
  templateKeys: IOpportunitySegmentConfigKeys;
  conferenceTopic?: string;
}

export interface IOpportunitySegmentConfigExternalSurvey {
  type: 'Decipher';
  linkBase: string;
  linkParams: Record<string, string>;
  surveyId: string;
}

export interface IOpportunityScreenerConfigItem {
  name: string;
  id: string;
}

export interface IOpportunityScreenerConfig {
  mailingListId: string;
  libraryId: string;
  surveys: IOpportunityScreenerConfigItem[];
  messages: IOpportunityScreenerConfigItem[];
}

export interface IOpportunityUpdateClientContact {
  email: string;
  primary?: boolean;
  country: string;
  firstName: string;
  lastName: string;
  timezone: { abbr: string; name: string; offset: string };
}

export interface IOpportunityUpdateAssignee {
  email: string;
}

export interface IOpportunityUpdateRequest
  extends Partial<
    Omit<IOpportunity, 'clientContacts' | 'opportunityAssignees'>
  > {
  opportunityId: string;
}

export interface IClientContactForm extends IOpportunityClientContact {
  primary?: boolean;
  options: {
    sendInviteEmail?: boolean;
    hideInviteToggle?: boolean;
  };
}

export interface IOpportunityUpdateForm
  extends Omit<IOpportunityUpdateRequest, 'clientContacts'> {
  clientContacts: IClientContactForm[];
  opportunityAssignees?: IUserBase[];
}
