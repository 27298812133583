import { IDataAccessLogs, IExpertActionBase } from './expertActions.models';

export interface IExpertRespondentAction
  extends IExpertRespondentActionBase,
    IDataAccessLogs {}

export interface IExpertRespondentActionBase extends IExpertActionBase {
  actionType: 'respondent';
  referenceId: string;
  responseType: 'outreachEmail' | 'expertPortal';
  responses: IExpertRespondentActionResponse[];
}

export interface IExpertRespondentActionResponse {
  categories: string[];
  body: string;
  dateCreated: number;
}
