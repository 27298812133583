import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as Moment from 'moment';
import * as MomentTz from 'moment-timezone';
import { IClientContact } from '../../models/opportunity';

type ClientContactPerspective = {
  name: string;
  rawStartTime: string;
  datetime: string;
};

@Component({
  selector: 'app-timezone-perspective-viewer',
  templateUrl: './timezone-perspective-viewer.component.html',
  styleUrls: ['./timezone-perspective-viewer.component.scss'],
})
export class TimezonePerspectiveViewerComponent implements OnChanges {
  public moment = Moment;
  public momentTz = MomentTz;
  @Input() selectedCallTime: string;
  @Input() startTime: string;
  @Input() endTime: string;
  @Input() expertTimezone: string;
  @Input() clientContactsArray: IClientContact[] = [];
  public expertPerspective: string;
  public clientContactPerspectives: ClientContactPerspective[];
  @Output() availabilityForDeletionSignal = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startTime &&
      changes.startTime.currentValue !== changes.startTime.previousValue &&
      this.expertTimezone
    ) {
      this.expertPerspective = `${this.momentTz
        .tz(this.startTime, this.expertTimezone)
        .format('ddd MMM D, HH:mm')} to ${this.momentTz
        .tz(this.endTime, this.expertTimezone)
        .format('HH:mm (z)')}`;
      this.clientContactPerspectives = (this.clientContactsArray || [])
        .filter((c) => c.timezone?.name)
        .map((contact) => ({
          name: `${contact.firstName} ${contact.lastName}`,
          rawStartTime: this.momentTz
            .tz(this.startTime, this.expertTimezone)
            .tz(contact.timezone.name)
            .format('HH:mm'),
          datetime: `${this.momentTz
            .tz(this.startTime, this.expertTimezone)
            .tz(contact.timezone.name)
            .format('ddd MMM D, HH:mm')} to ${this.momentTz
            .tz(this.endTime, this.expertTimezone)
            .tz(contact.timezone.name)
            .format('HH:mm (z)')}`,
        }));
    } else if (
      changes.selectedCallTime &&
      changes.selectedCallTime.currentValue !==
        changes.selectedCallTime.previousValue
    ) {
      this.expertPerspective = `${this.momentTz
        .tz(this.selectedCallTime, this.expertTimezone)
        .format('ddd MMM D, HH:mm (z)')}`;
      this.clientContactPerspectives = (this.clientContactsArray || [])
        .filter((c) => c.timezone?.name)
        .map((contact) => ({
          name: `${contact.firstName} ${contact.lastName}`,
          rawStartTime: this.momentTz
            .tz(this.selectedCallTime, this.expertTimezone)
            .tz(contact.timezone.name)
            .format('HH:mm'),
          datetime: `${this.momentTz
            .tz(this.selectedCallTime, this.expertTimezone)
            .tz(contact.timezone.name)
            .format('ddd MMM D, HH:mm (z)')}`,
        }));
    }
  }

  checkSociabilityOfTime(datetime: string): boolean {
    const format = 'hh:mm';
    const time = this.moment(datetime, format);
    const beforeTime = this.moment('00:00', format);
    const afterTime = this.moment('04:00', format);
    return time.isBetween(beforeTime, afterTime);
  }

  emitAvailabilityForDeletion(): void {
    if (this.startTime) {
      this.availabilityForDeletionSignal.emit(this.startTime);
    } else if (this.selectedCallTime) {
      this.availabilityForDeletionSignal.emit(this.selectedCallTime);
    }
  }
}
