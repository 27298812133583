import {
  AppState,
  AuthService as Auth0Service,
  IdToken,
} from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';

export const createAuthServiceMock = (
  idToken: IdToken = null,
  accessToken = 'Access Token Value'
): Auth0Service<AppState> => {
  return {
    idTokenClaims$: of(idToken),
    logout: (): void => {
      return;
    },
    getAccessTokenSilently: (): Observable<string> => of(accessToken),
  } as unknown as Auth0Service<AppState>;
};
