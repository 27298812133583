import { IExpert } from '@techspert-io/experts';
import { IResponseFailItem } from './api';

export class Email {
  tags: string[];
  sender: string | IEmailAddress;
  recipient: string;
  emailType: 'solicitation' | 'conference' | 'internal-comms';
  subject: string;
  htmlContent: string;
  textContent: string;
  replyTo?: IEmailAddress;
  event?: IICalEvent;
  cc?: string[];
  bcc?: IEmailAddress[];
}

export interface IEmailMessage extends Email {
  messageId: string;
  /** date message created as unix timestamp - now a number legacy data uses a string*/
  created: number | string;
  lastUpdated: number | string;
  sender: string;
  status: MessageStatusMap;
}

export enum MessageStatusMap {
  connectUnsubscribed = 'cn-unsubscribed',
  connectBlockedDomain = 'cn-blockedDomained',
  connectBounced = 'cn-bounced',
  connectPermissionRejected = 'cn-permissions-rejected',
  connectSendFailure = 'cn-send-failure',
  connectDeferred = 'cn-deferred',
  connectSendElapsed = 'cn-send-elapsed',
  connectSent = 'cn-sent',
  providerProcessing = 'pr-processing',
  providerDropped = 'pr-dropped',
  providerDeferred = 'pr-deferred',
  providerBounce = 'pr-bounce',
  providerDelivered = 'pr-delivered',
  providerOpen = 'pr-open',
  providerClick = 'pr-click',
  providerSpam = 'pr-spam-report',
  providerUnsubscribe = 'pr-unsubscribe',
  providerResubscribe = 'pr-resubscribe',
  userUnsubscribe = 'user-unsubscribe',
}

export interface IICalEvent {
  start: string;
  end: string;
  summary: string;
  description?: string;
  attendees: IICalPerson[];
  location?: string;
  status: 'CANCELLED' | 'CONFIRMED';
  organizer: IICalPerson;
  uid?: string;
  sequence?: number;
}

export interface IEmailAddress {
  email: string;
  name?: string;
}

export interface ISolicitationEmail extends Email {
  preheader?: string;
}

export interface ICreateSolicitationResponse {
  success: IPrepEmail[];
  fail: IResponseFailItem<IPrepEmail>[];
  duplicate: IPrepEmail[];
  notSent: IPrepEmail[];
}

export interface IPrepEmail extends IEmailTracking {
  email: Email;
  batchId: string;
  expertId: string;
  opportunityId: string;
  connectPhase: IExpert['connectPhase'] | '';
}

export interface IAutomatedSolicitationPayload {
  emails: IPrepEmail[];
}

export interface IEmailTracking {
  expertId: string;
  opportunityId: string;
  connectPhase: IExpert['connectPhase'] | '';
  searchExpertId?: string;
  trackingId?: string;
  templateId?: string;
  templateParentId?: string;
  source: IExpert['source'] | '';
  isPoCEmail: boolean;
}

interface IICalPerson {
  email: string;
  mailto: string;
  name: string;
}
