import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IExpertAvailabilitiesActionTimeSlot } from '@techspert-io/expert-actions';
import { IExpert } from '@techspert-io/experts';
import { IOpportunityClientContact } from '@techspert-io/opportunities';
import { ToastService } from '@techspert-io/user-alerts';
import * as moment from 'moment';

@Component({
  selector: 'app-expert-profile-availability',
  templateUrl: './expert-profile-availability.component.html',
  styleUrls: ['./expert-profile-availability.component.scss'],
})
export class ExpertProfileAvailabilityComponent {
  @ViewChild('UpdateCallTimeInput') public updateCallTimeInput: ElementRef;
  @Input() public availabilities: IExpertAvailabilitiesActionTimeSlot[];
  @Input() public callTime: IExpert['callTime'];
  @Input() public clientContacts: IOpportunityClientContact[];
  @Input() public expertTimezone: IExpert['timezoneName'];
  @Input() public anticipatedCallTime = 60;
  @Output() public assignAvailabilitySignal = new EventEmitter();
  @Output() public updatedCallTimeSignal = new EventEmitter<string>();
  @Output() public deleteCallTimeSignal = new EventEmitter();
  @Output() public deleteAvailabilitySignal = new EventEmitter();
  @Output() public deleteAllAvailabilitiesSignal = new EventEmitter();

  public showUpdateCallTimeInput: boolean = false;
  public newCallTime: string;
  public availibilityCap: number = 5;
  public capAvailibilities: boolean = true;

  constructor(private toastService: ToastService) {}

  public emitAssignAvailability(
    event: IExpertAvailabilitiesActionTimeSlot[]
  ): void {
    this.assignAvailabilitySignal.emit(event);
  }

  public toggleUpdateCallTime(): void {
    this.showUpdateCallTimeInput = !this.showUpdateCallTimeInput;
  }

  public emitUpdatedCallTime(): void {
    this.updatedCallTimeSignal.emit(this.newCallTime);
    this.showUpdateCallTimeInput = false;
  }

  public emitDeleteCallTime(callTime: string): void {
    this.deleteCallTimeSignal.emit(callTime);
  }

  public emitDeleteAvailability(
    event: IExpertAvailabilitiesActionTimeSlot
  ): void {
    this.deleteAvailabilitySignal.emit(event);
  }

  public emitDeleteAllAvailabilities(): void {
    this.deleteAllAvailabilitiesSignal.emit();
  }

  public copyText(): void {
    navigator.clipboard.writeText(this.formatAvailability());
    this.toastService.sendMessage(
      `Successfully copied to clipboard`,
      'success'
    );
  }

  public formatAvailability(): string {
    const contactZones = this.clientContacts
      .sort((a, b) => Number(b.primary) - Number(a.primary))
      .map((c) => ({ name: c.timezone.name, abbr: c.timezone.abbr }))
      .filter((e, i, arr) => arr.findIndex((a) => a.abbr === e.abbr) === i);
    return (this.availabilities || [])
      .map((availability) =>
        this.constructContactTimesByZone(availability, contactZones)
      )
      .join(' \n');
  }

  private constructContactTimesByZone(
    availability: IExpertAvailabilitiesActionTimeSlot,
    zones: { name: string; abbr: string }[]
  ): string {
    return zones
      .map((zone, i) => `${this.printTime(zone, availability, !!i)}`)
      .join(' / ');
  }

  private printTime(
    zone: { name: string; abbr: string },
    availability: IExpertAvailabilitiesActionTimeSlot,
    includeBrackets = false
  ): string {
    const startMoment = moment.tz(availability.start, zone.name);
    const endMoment = moment.tz(availability.end, zone.name);

    const result = `${startMoment.format(
      'dddd Do MMMM h:mmA'
    )} to ${endMoment.format('h:mmA')} ${moment(availability.start)
      .tz(zone.name)
      .format('z')}`;

    return includeBrackets ? `(${result})` : result;
  }
}
