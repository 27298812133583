/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type IResource = 'solicitation-outreach';

@Injectable({ providedIn: 'root' })
export class DataService {
  private readonly baseUrl = '/data';

  constructor(private http: HttpClient) {}

  public create<T = any>(resource: IResource, item: any): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${resource}/create`, item);
  }
}
