import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type cbChange = (c: string[]) => void;
type cbTouch = () => void;

@Component({
  selector: 'ct-chip-list-control',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: ChipListControlComponent,
      multi: true,
    },
  ],
  template: `
    <ct-chip-list
      [list]="list"
      [placeholder]="placeholder"
      [label]="label"
      [hint]="hint"
      [errorMessage]="errorMessage"
      [disabled]="disabled"
      (addItemSignal)="addItem($event)"
      (removeItemSignal)="removeItem($event)"
    >
    </ct-chip-list>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ChipListControlComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() hint?: string;
  @Input() errorMessage?: string;

  touched = false;
  disabled = false;
  list: string[] = [];
  set val(val: string[]) {
    this.list = val;
  }

  onChange: cbChange = () => void 0;
  onTouch: cbTouch = () => void 0;

  writeValue(value: string[]): void {
    this.val = value;
  }

  registerOnChange(fn: cbChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: cbTouch): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addItem(item: string): void {
    if (item) {
      this.list = [...this.list, item];
      this.change();
    }
  }

  removeItem(item: string): void {
    this.list = this.list.filter((l) => l !== item);
    this.change();
  }

  private change(): void {
    if (!this.touched) {
      this.onTouch();
      this.touched = true;
    }
    this.onChange(this.list);
  }
}
