import {
  ICommercialCondition,
  IHistoricalCondition,
  ISearchCondition,
} from '../../../admin-portal/search/models/query';

interface IExpertSourceQueryCondition {
  match: boolean;
  field: string;
  terms: string[];
  operator: string;
  use_wildcards: boolean;
}

interface IExpertSourceQuery {
  conditions: IExpertSourceQueryCondition[];
  countries: string[];
  service: string;
  fromIndex: number;
  size: number;
}

export interface IExpertSource extends IExpertSourceBase {
  dateCreated: number;
  createdBy: string;
  lastUpdated: number;
  updatedBy: string;
}

export interface IExpertSourceBase {
  expertSourceId: string;
  source: 'async-search' | 'sync-search' | 'manual' | 'client-portal';
  opportunityId: string;
  batchId: string;
  /* Expert Segment **/
  segment: string;
  opportunitySegmentId: string;
  searchName: string;
  status: 'pending' | 'started' | 'success' | 'failed' | 'deleted';

  query: ExpertSourceQuery;
  defaultExpertData?: Partial<IExpertSourceDefaultExpertData>;

  expertsAdded: number;
  expertsFound: number;
  error?: string;
}

export type ExpertSourceCreateRequest =
  | IExpertSourceAsyncCreateReq
  | IExpertSourceSyncCreateReq
  | IExpertSourceCommercialSyncCreateReq;

export interface IExpertSourceDefaultExpertData {
  ownerConnectId: string;
}

export interface IExpertSourceCreateBaseRequest {
  opportunityId: string;
  segment: IExpertSourceBase['segment'];
  opportunitySegmentId: string;
  searchName: string;
  requestCount: number;
  type: 'async' | 'sync';
  query: unknown;
  defaultExpertData?: Partial<IExpertSourceDefaultExpertData>;
}

export interface IExpertSourceAsyncCreateReq
  extends IExpertSourceCreateBaseRequest {
  type: 'async';
  query: {
    conditions: (
      | ISearchCondition
      | ICommercialCondition
      | IHistoricalCondition
    )[];
    countries: string[];
    size?: number;
    service: 'cognisearch' | 'combined-search';
  };
}

export interface IExpertCloneAsyncCreateReq {
  opportunityId: string;
  segment: IExpertSourceBase['segment'];
  opportunitySegmentId: string;
  searchName: string;
  type: 'async';
  query: {
    expertIds: string[];
    service: 'expert-clone';
  };
  sourceOpportunityId: string;
  defaultExpertData?: Partial<IExpertSourceDefaultExpertData>;
}

export interface IExpertSourceSyncCreateReq
  extends IExpertSourceCreateBaseRequest {
  type: 'sync';
  query: ExpertSourceQuery;
  expertSourceId?: string;
  expertsAdded?: number;
  expertsFound?: number;
}
export interface IExpertSourceCommercialSyncCreateReq
  extends IExpertSourceCreateBaseRequest {
  type: 'sync';
  query: IExpertSourceCommercialSearchQuery;
  expertSourceId?: string;
  expertsAdded?: number;
  expertsFound?: number;
}

export interface IExpertSourceCreateResponse {
  searches: IExpertSource[];
  batchId: string;
}

export type ExpertSourceQuery =
  | IExpertSourceSearchQuery
  | IExpertSourceCommercialSearchQuery
  | IExpertSourceCloneQuery
  | IExpertSourceOtherQuery;

export interface IExpertSourceSearchQuery {
  fromIndex: number;
  size: number;
  conditions: ISearchCondition[];
  countries: string[];
  service: 'cognisearch' | 'historical' | 'deep3' | 'deep-next';
}

export interface IExpertSourceCommercialSearchQuery {
  fromIndex: number;
  size: number;
  conditions: ICommercialCondition[];
  countries: string[];
  service: 'pdl-commercial';
  include_past_experiences: boolean;
}

export interface IExpertSourceCloneQuery {
  size: number;
  service: 'expert-clone' | 'omnisearch';
  sourceOpportunityId?: string;
}

export interface IExpertSourceOtherQuery {
  size: number;
  service: 'manual-upload' | 'pdl-enrichment';
}
