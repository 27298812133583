import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'opp-settings-combined-form',
  templateUrl: './settings-combined-form.component.html',
  styleUrls: ['./settings-combined-form.component.scss'],
})
export class SettingsCombinedFormComponent {
  get settingsForm(): FormGroup {
    return this.rootFormGroup.form;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}
}
