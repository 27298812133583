import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ISolicitationOutreach } from '../models/solicication-outreach.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertsSolicationOutreachService {
  private readonly baseUrl = '/experts';
  private cache: Record<string, Observable<ISolicitationOutreach[]>> = {};

  constructor(private http: HttpClient) {}

  get(expertId: string): Observable<ISolicitationOutreach[]> {
    if (!this.cache[expertId]) {
      this.cache[expertId] = this.http
        .get<ISolicitationOutreach[]>(
          `${this.baseUrl}/${expertId}/solicitationOutreach`
        )
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this.cache[expertId];
  }
}
