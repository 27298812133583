import { DOCUMENT } from '@angular/common';
import {
  HttpBackend,
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, scan, tap } from 'rxjs/operators';

export interface IFileProgressEvent {
  fileName: string;
  fileKey?: string;
  percentageComplete: number;
  status: 'started' | 'ongoing' | 'complete' | 'failed';
}

interface ITemporaryURLResponse {
  uploadUrl: string;
  fileKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileStoreService {
  private readonly baseUrl = '/filestore';
  private httpNoAuth = new HttpClient(this.httpBackend);

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public removeFile(filename: string): Observable<unknown> {
    return this.http.post<ITemporaryURLResponse>(`${this.baseUrl}/delete`, {
      filename,
    });
  }

  public downloadFile(
    fileKey: string,
    fileName: string
  ): Observable<IFileProgressEvent> {
    return this.http
      .get<string>(`${this.baseUrl}/presigned`, {
        params: { fileKey },
      })
      .pipe(mergeMap((getUrl) => this.download(getUrl, fileName)));
  }

  public uploadFiles(
    files: File[],
    resource: 'opportunities' | 'experts' | 'commercial-upload',
    resourceId: string
  ): Observable<IFileProgressEvent[]> {
    return from(files).pipe(
      mergeMap((file) =>
        this.presignedPut(file.name, resource, resourceId).pipe(
          mergeMap((res) =>
            this.uploadFile(file, res).pipe(
              map((fileRes) => ({ ...fileRes, fileKey: res.fileKey }))
            )
          )
        )
      ),
      map((item) => ({ [item.fileKey]: item })),
      scan((items, item) => ({ ...items, ...item }), {}),
      map((dictionary) => Object.values(dictionary))
    );
  }

  private uploadFile(
    file: File,
    res: ITemporaryURLResponse
  ): Observable<IFileProgressEvent> {
    const req = new HttpRequest('PUT', res.uploadUrl, file, {
      reportProgress: true,
    });
    return this.httpNoAuth.request(req).pipe(
      map((event) => this.getProgressEvent(event, file.name)),
      catchError(() => this.handleError(file.name))
    );
  }

  private download(
    url: string,
    fileName: string
  ): Observable<IFileProgressEvent> {
    return this.httpNoAuth
      .get(url, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        filter((event) => event.type !== HttpEventType.ResponseHeader),
        tap((t) => {
          if (t.type === HttpEventType.Response) {
            const a = this.document.createElement('a');
            a.href = URL.createObjectURL(t.body);
            a.setAttribute('download', fileName);
            a.click();
          }
        }),
        map((event) => this.getProgressEvent(event, fileName)),
        catchError(() => this.handleError(fileName))
      );
  }

  private handleError(fileName: string): Observable<IFileProgressEvent> {
    return of({
      fileName,
      percentageComplete: 0,
      status: 'failed',
    });
  }

  private getProgressEvent(
    event: HttpEvent<unknown>,
    fileName: string
  ): IFileProgressEvent {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          fileName,
          percentageComplete: 0,
          status: 'started',
        };

      case HttpEventType.DownloadProgress:
      case HttpEventType.UploadProgress:
        return {
          fileName,
          percentageComplete: Math.round((100 * event.loaded) / event.total),
          status: 'ongoing',
        };

      case HttpEventType.Response:
        return {
          fileName,
          percentageComplete: 100,
          status: 'complete',
        };
    }
  }

  private presignedPut(
    fileName: string,
    resource: 'experts' | 'opportunities' | 'commercial-upload',
    resourceId: string
  ): Observable<ITemporaryURLResponse> {
    return this.http.post<ITemporaryURLResponse>(`${this.baseUrl}/presigned`, {
      fileName,
      resource,
      resourceId,
    });
  }
}
