import { Email } from '../../../../shared/models/email';

export const emailMockData: Email = {
  tags: [],
  sender: 'from@',
  recipient: 'to@',
  subject: 'subject',
  emailType: 'solicitation',
  htmlContent: '<p>hi</p>',
  textContent: 'hi',
  cc: [],
};
