import {
  AfterContentInit,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: 'select[appCountryOptionsDividers]',
})
export class CountryOptionsDividersDirective implements AfterContentInit {
  constructor(
    private el: ElementRef<HTMLSelectElement>,
    private renderer: Renderer2
  ) {}

  ngAfterContentInit(): void {
    const existingOptions = this.getExistingOptions();

    if (existingOptions.length > 2) {
      const firstOption = existingOptions.find((d) => d.value.length === 2);
      const afghanistanOption = existingOptions.find((d) => d.value === 'AF');

      if (afghanistanOption) {
        this.addOption('--FREQUENTLY USED--', firstOption);
        this.addOption('--ALL COUNTRIES--', afghanistanOption);
      }
    }
  }

  private getExistingOptions(): HTMLOptionElement[] {
    return Array.from(this.el.nativeElement.querySelectorAll('option'));
  }

  private addOption(text: string, refChild: HTMLOptionElement): void {
    const option = this.createOption(text);
    this.renderer.insertBefore(this.el.nativeElement, option, refChild);
  }

  private createOption(text: string): HTMLOptionElement {
    const option = this.renderer.createElement('option') as HTMLOptionElement;
    option.value = undefined;
    option.text = text;
    option.disabled = true;
    return option;
  }
}
