import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ExpertSourceCreateRequest,
  IExpertCloneAsyncCreateReq,
  IExpertSource,
  IExpertSourceAsyncCreateReq,
  IExpertSourceCreateBaseRequest,
  IExpertSourceCreateResponse,
} from '../models/expert-profiles.models';

@Injectable({
  providedIn: 'root',
})
export class ExpertSourcesService {
  private readonly baseUrl = '/experts-search';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  getByOpportunity(opportunityId: string): Observable<IExpertSource[]> {
    return this.http.get<IExpertSource[]>(this.baseUrl, {
      params: { opportunityId },
    });
  }

  createAsyncClone(
    req: IExpertCloneAsyncCreateReq
  ): Observable<IExpertSourceCreateResponse> {
    return this.http
      .post<IExpertSourceCreateResponse>(`${this.baseUrl}/clone`, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Clone started for ${req.query.expertIds.length} experts`,
            'success'
          )
        )
      );
  }

  createAsyncCognisearch(
    req: IExpertSourceAsyncCreateReq
  ): Observable<IExpertSourceCreateResponse> {
    return this.http
      .post<IExpertSourceCreateResponse>(this.baseUrl, req)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `Cognisearch started querying for ${req.requestCount} experts`,
            'success'
          )
        )
      );
  }

  create<T = ExpertSourceCreateRequest>(
    req: T & IExpertSourceCreateBaseRequest
  ): Observable<IExpertSourceCreateResponse> {
    return this.http
      .post<IExpertSourceCreateResponse>(`${this.baseUrl}`, req)
      .pipe(
        tap(
          (res) => req.type === 'async' && this.showCreateMessage(res.searches)
        )
      );
  }

  private showCreateMessage(
    res: IExpertSourceCreateResponse['searches']
  ): void {
    if (res.every((e) => e.status === 'success')) {
      return this.toastService.sendMessage(
        `Combined searches: ${res.length} started`,
        'success'
      );
    }
    if (res.every((e) => e.status === 'failed')) {
      return this.toastService.sendMessage(
        `Combined searches: All failed`,
        'error'
      );
    }
    const success = res.filter((e) => e.status === 'success');
    const fail = res.filter((e) => e.status === 'failed');
    return this.toastService.sendMessage(
      `Combined searches: ${success.length} started. ${fail.length} failed`,
      'error'
    );
  }
}
