import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileStoreModule } from '../file-store/file-store.module';
import { ExpertFileEditDialogComponent } from './components/expert-file-edit-dialog/expert-file-edit-dialog.component';
import { ExpertFilesComponent } from './components/expert-files/expert-files.component';
import { ExpertFileService } from './services/expert-file.service';
import { ExpertFilesStateService } from './services/expert-files-state.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxFileDropModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    FileStoreModule,
  ],
  declarations: [ExpertFilesComponent, ExpertFileEditDialogComponent],
  providers: [ExpertFileService, ExpertFilesStateService],
  exports: [ExpertFilesComponent],
})
export class ExpertFilesModule {}
