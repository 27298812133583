import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-textarea-editor',
  templateUrl: './textarea-editor.component.html',
  styleUrls: ['./textarea-editor.component.css'],
})
export class TextareaEditorComponent implements OnInit {
  @Input() resize = false;
  @Input() editorValue = '';
  @Output() editorValueChange = new EventEmitter<string>();

  editorApiKey = 'giif5mqxrrv6nz6qkt0rldjgdr6zuuohvdt24pkw31i4pedq';
  tinyEditorInit = {
    resize: false,
    height: '100%',
    plugins: 'link lists advlist wordcount',
    toolbar:
      'undo redo styleselect bold italic underline link alignleft aligncenter alignright bullist numlist outdent indent removeformat',
  };

  ngOnInit(): void {
    this.tinyEditorInit = {
      ...this.tinyEditorInit,
      resize: this.resize,
    };
  }

  onChange(): void {
    this.editorValueChange.emit(this.editorValue);
  }
}
