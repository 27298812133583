import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { IOpportunitySegmentConfigExternalSurvey } from '../../../models/opportunity.models';

@Component({
  selector: 'opp-survey-settings-form',
  templateUrl: './survey-settings-form.component.html',
  styleUrls: ['./survey-settings-form.component.scss'],
})
export class SurveySettingsFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  get oppForm(): FormGroup {
    return this.rootFormGroup.control;
  }

  get defaultSurveyProviderCtrl(): FormControl {
    return this.oppForm.get('defaultSurveyProvider') as FormControl;
  }

  get isExternal(): boolean {
    return this.defaultSurveyProviderCtrl.value === 'External';
  }

  get surveyConfigForm(): FormGroup {
    return this.rootFormGroup.control.get('externalSurveyConfig') as FormGroup;
  }

  get linkErrors(): string {
    return this.surveyConfigForm.get('linkInput').errors?.urlError;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.surveyConfigForm.addControl('linkInput', new FormControl());
    this.surveyConfigForm.addValidators(this.surveyFormValidator());
    this.setupLinkInputCtrl();

    this.defaultSurveyProviderCtrl.valueChanges
      .pipe(
        tap(() => this.surveyConfigForm.updateValueAndValidity()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private surveyFormValidator(): ValidatorFn {
    return (ctrl): ValidationErrors => {
      const formValues = ctrl.value as IOpportunitySegmentConfigExternalSurvey;
      const linkInput = ctrl.get('linkInput');

      if (this.isExternal) {
        if (!linkInput.value) {
          linkInput.setErrors({ urlError: 'Enter a URL' });
          return {};
        }

        try {
          new URL(linkInput.value);
        } catch (error) {
          linkInput.setErrors({ urlError: 'Enter a valid URL' });
          return {};
        }

        if (
          !Object.values(formValues.linkParams || {}).some(
            (v) => v === '[action_id]'
          )
        ) {
          linkInput.setErrors({
            urlError: "Cannot find '[action_id]' (techID)",
          });
          return {};
        }
      }

      linkInput.setErrors(null);
      return null;
    };
  }

  private setupLinkInputCtrl(): void {
    const linkInputCtrl = this.surveyConfigForm.get('linkInput');

    linkInputCtrl.valueChanges
      .pipe(
        filter<string>(
          (val) => !!val && val.includes('decipherinc.com/survey/')
        ),
        map((val) => {
          try {
            return new URL(val);
          } catch (error) {
            return new URL('http://a.example');
          }
        }),
        tap((url) => this.updateForm(url)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    linkInputCtrl.setValue(this.buildLinkInput(this.surveyConfigForm.value));
  }

  private buildLinkInput(opp: IOpportunitySegmentConfigExternalSurvey): string {
    if (!opp.linkBase) {
      return null;
    }
    const params = new URLSearchParams(opp.linkParams);
    return `${opp.linkBase}?${params}`;
  }

  private updateForm(url: URL): void {
    const surveyId = url.pathname.substring('/survey/'.length);
    const paramsMap = [...url.searchParams.entries()].reduce(
      (prev, [id, val]) => ({
        ...prev,
        [id]: ['techID'].includes(id) ? '[action_id]' : val,
      }),
      {}
    );
    if (Object.values(paramsMap).some((p) => p === '[action_id]')) {
      this.surveyConfigForm.patchValue(
        {
          type: 'Decipher',
          linkBase: `${url.origin}${url.pathname}`,
          linkParams: paramsMap,
          surveyId: surveyId,
        },
        { emitEvent: false }
      );
    } else {
      this.surveyConfigForm.patchValue(
        {
          type: null,
          linkBase: null,
          linkParams: null,
          surveyId: null,
        },
        { emitEvent: false }
      );
    }
  }
}
