import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IOpportunity } from '../models/opportunity.models';

export interface IOpportunityCreateScreenerConfig {
  opportunityId: string;
  surveyName: string;
  messageId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OpportunityScreenersService {
  private readonly baseUrl = '/opportunities';

  constructor(private http: HttpClient, private toastService: ToastService) {}

  create({
    opportunityId,
    ...payload
  }: IOpportunityCreateScreenerConfig): Observable<IOpportunity> {
    return this.http
      .post<IOpportunity>(this.buildUrl(opportunityId), payload)
      .pipe(
        tap(() =>
          this.toastService.sendMessage(
            `${payload.surveyName} Screener Created`,
            'success'
          )
        ),
        catchError((err) => this.handleError(err))
      );
  }

  private buildUrl(opportunityId: string): string {
    return `${this.baseUrl}/${opportunityId}/screeners`;
  }

  private handleError(err: Error): Observable<IOpportunity> {
    if (err instanceof HttpErrorResponse) {
      this.toastService.sendMessage(err.error.message, 'error');
      return throwError(new Error(err.error.message));
    }
    return throwError(err);
  }
}
