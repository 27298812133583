import { Component, ElementRef, Input, ViewChild } from '@angular/core';

type InputProps = {
  lineColor: string;
  currentValue: string;
  circleText: string;
  percentageRatio: string;
};

@Component({
  selector: 'app-donut-svg',
  templateUrl: './donut-svg.component.html',
  styleUrls: ['./donut-svg.component.scss'],
})
export class DonutSvgComponent {
  @Input() inputProps: InputProps;
  @ViewChild('LineSegment') lineSegment: ElementRef;
}
