import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { CognitoAuthService } from '../services/cognito-auth.service';
import { AUTH0_DOMAIN } from '../services/token';
import { createAuthServiceMock } from './authMock';

interface IAuthTestingModuleOptions {
  authToken: string;
  hostedUrl: string;
  auth0Domain: string;
}

const authToken = 'authTokenVal';
const hostedUrl = 'http://auth-url.com';
const auth0Domain = 'http://auth0.com';

const defaultOptions: Partial<IAuthTestingModuleOptions> = {
  authToken,
  hostedUrl,
  auth0Domain,
};

@NgModule()
export class AuthTestingModule {
  static forRoot(
    options = defaultOptions
  ): ModuleWithProviders<AuthTestingModule> {
    return {
      ngModule: AuthTestingModule,
      providers: [
        {
          provide: AUTH0_DOMAIN,
          useValue: options.auth0Domain || auth0Domain,
        },
        {
          provide: Auth0Service,
          useValue: createAuthServiceMock(null, options.authToken),
        },
        CognitoAuthService,
      ],
    };
  }
}
