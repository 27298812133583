import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectUser } from '@techspert-io/auth';
import { UserService } from '@techspert-io/users';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IUserDialogInput {
  filterEmails?: string[];
  connectId?: string;
  email?: string;
}

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss'],
})
export class UsersDialogComponent implements OnInit {
  users$: Observable<ConnectUser[]>;

  constructor(
    private usersService: UserService,
    private dialogRef: MatDialogRef<UsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserDialogInput
  ) {}

  ngOnInit(): void {
    this.users$ = this.usersService
      .getAll({ userTypes: ['PM'] })
      .pipe(
        map((users) =>
          users.filter((u) => !(this.data.filterEmails || []).includes(u.email))
        )
      );
  }

  submitUser(user: ConnectUser[]): void {
    this.dialogRef.close(user);
  }
}
