import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ExpertSource } from '@techspert-io/experts';
import {
  FeasibilityService,
  ISearchExpandedTermsReturn,
} from '@techspert-io/feasibility';
import { ToastService } from '@techspert-io/user-alerts';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  publishReplay,
  reduce,
  refCount,
  take,
} from 'rxjs/operators';
import { AppService } from '../../../shared/services/app.service';
import {
  ICommercialEnrichmentExpert,
  ICommercialEnrichmentResponse,
  ICommercialEnrichmentResultDict,
} from '../models/experts/search-response/commercial-enrichment';
import {
  IDeepExpert,
  IDeepResponse,
} from '../models/experts/search-response/deep';
import {
  ISearchExpertV3,
  ISearchResponse,
  v3SearchExpert,
} from '../models/experts/search-response/search-response';
import {
  ICommercialCondition,
  ICommercialSearchQuery,
  IHistoricalCondition,
  ILinkedInEnrichmentQuery,
  IRelatedTerms,
  ISearchCombinedQuery,
  ISearchCondition,
  ISearchQuery,
} from '../models/query';
import { ISearchDisplayExpert, ISearchReturn } from '../models/search-models';

export const SEARCH_API_BASE_URL = new InjectionToken<string>(
  'SEARCH_API_BASE_URL',
  {
    providedIn: 'root',
    factory: (): string => '',
  }
);
export const SEARCH_API_BASE_2_URL = new InjectionToken<string>(
  'SEARCH_API_BASE_2_URL',
  {
    providedIn: 'root',
    factory: (): string => '',
  }
);

@Injectable()
export class SearchService {
  defaultPageSize = 1500;
  private knowledgeGraphCache: Record<string, Observable<IRelatedTerms>> = {};

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private toastService: ToastService,
    private feasibilityService: FeasibilityService,
    @Inject(SEARCH_API_BASE_URL) private baseUrl: string,
    @Inject(SEARCH_API_BASE_2_URL) private base2Url: string
  ) {}

  search(
    payload: ISearchQuery | ISearchCombinedQuery | ICommercialSearchQuery
  ): Observable<ISearchReturn> {
    switch (payload.service) {
      case 'deep3':
        return this.paginatedSearch(
          payload,
          this.mapDeepToConnectExpert.bind(this),
          payload.count,
          0,
          this.defaultPageSize
        );
      case 'cognisearch':
        return this.paginatedSearch(
          { ...payload, count: Math.min(payload.count, 300) },
          this.mapToConnectExpert.bind(this),
          payload.count,
          0,
          100000
        );
      case 'historical':
      case 'combined-search':
        return this.paginatedSearch(
          payload,
          this.mapToConnectExpert.bind(this),
          payload.count,
          0,
          this.defaultPageSize
        );
      case 'pdl-commercial':
        return this.paginatedSearch(
          payload,
          this.mapToConnectExpert.bind(this),
          payload.size,
          payload.from,
          Math.min(100, this.defaultPageSize)
        );
      case 'deep-next':
        return this.http
          .post<IDeepResponse>(`${this.baseUrl}/v1/deep/search`, payload)
          .pipe(
            map((res) => ({
              experts: res.results.map((expert) =>
                this.mapDeepToConnectExpert(
                  expert,
                  expert.source.tracking_id,
                  payload.service
                )
              ),
              total: res.total,
            }))
          );
    }
  }

  parseAndSearchLinkedIn(
    payload: ILinkedInEnrichmentQuery
  ): Observable<ICommercialEnrichmentResultDict> {
    return from(this.appService.chunkArray(100, payload.attributes)).pipe(
      mergeMap((urls) =>
        this.searchLinkedInEnrichment({
          ...payload,
          attributes: urls,
        })
      ),
      reduce(
        (prev, curr) => ({
          success: [...prev.success, ...curr.success],
          fail: [...prev.fail, ...curr.fail],
        }),
        {
          success: [],
          fail: [],
        }
      )
    );
  }

  expertiseAutocomplete(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    expertise: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    limit: number | 'all'
  ): Observable<string[]> {
    // CT-1522 Enable when search endpoint online
    return of([]);
    // return this.http
    //   .get<IExpertiseAutocompleteResponse>(
    //     `${this.baseUrl}/v1/autocomplete/expertise?query=${expertise}&limit=${limit}`
    //   )
    //   .pipe(
    //     map((data) => data.completions),
    //     catchError(() => of([]))
    //   );
  }

  knowledgeGraph(terms: string[]): Observable<IRelatedTerms> {
    const idx = terms.join(',');
    if (!this.knowledgeGraphCache[idx]) {
      this.knowledgeGraphCache[idx] = this.http
        .post<IRelatedTerms>(`${this.baseUrl}/v2/knowledgegraph/search`, {
          terms,
        })
        .pipe(
          catchError((err) => {
            this.toastService.sendMessage(err.message, 'error');
            return of({
              disease_ontology_child: [],
              disease_ontology_parent: [],
              disease_ontology_synonym: [],
              mag_child: [],
              mag_parent: [],
              mesh_child: [],
              mesh_parent: [],
              mesh_synonym: [],
            });
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.knowledgeGraphCache[idx];
  }

  resetKnowledgeGraphCache(): void {
    this.knowledgeGraphCache = {};
  }

  saveKeptKnowledgeGraphTerms(
    conditions: (
      | ISearchCondition
      | ICommercialCondition
      | IHistoricalCondition
    )[],
    opportunityId?: string,
    segmentId?: string
  ): Observable<ISearchExpandedTermsReturn> {
    const usedExpandedTerms = conditions.flatMap((c) => c.terms);
    const { keys, values$ } = Object.entries(this.knowledgeGraphCache).reduce<{
      keys: string[];
      values$: Observable<IRelatedTerms>[];
    }>(
      (prev, curr) => ({
        keys: [...prev.keys, curr[0]],
        values$: [...prev.values$, curr[1]],
      }),
      {
        keys: [],
        values$: [],
      }
    );

    return combineLatest(values$).pipe(
      mergeMap((values) =>
        this.feasibilityService.saveSearchExpandedTerms(
          values.map((value, index) => ({
            term: keys[index],
            foundExpandedTerms: [
              value.disease_ontology_child,
              value.disease_ontology_synonym,
              value.mag_child,
              value.mesh_child,
              value.mesh_synonym,
            ].flat(),
            usedExpandedTerms,
            opportunityId,
            segmentId,
          }))
        )
      ),
      take(1)
    );
  }

  private paginatedSearch(
    payload: ISearchQuery | ICommercialSearchQuery | ISearchCombinedQuery,
    expertMapper: (
      expert: v3SearchExpert,
      trackingId: string,
      service: ExpertSource
    ) => ISearchDisplayExpert,
    count: number,
    expertsFrom: number,
    pageSize: number
  ): Observable<ISearchReturn> {
    const serviceDict = {
      deep3: `${this.baseUrl}/v3/deep/search`,
      historical: `${this.baseUrl}/v3/historical/search`,
      'combined-search': `${this.baseUrl}/v3/historical/search`,
      'pdl-commercial': `${this.baseUrl}/v3/pdl/search`,
      cognisearch: `${this.base2Url}/expert-profile-query/search`,
    };

    const base = serviceDict[payload.service];
    const serviceCount = payload.service === 'combined-search' ? 300 : count;
    const fullPages = Math.floor(serviceCount / pageSize);
    const rem = serviceCount % pageSize;

    const requests = [
      ...[...Array(fullPages).keys()].map((i) => ({
        from: expertsFrom + pageSize * i,
        pageSize: pageSize,
      })),
      ...(rem
        ? [
            {
              from: expertsFrom + pageSize * fullPages,
              pageSize: rem,
            },
          ]
        : []),
    ];

    return from(this.appService.chunkArray(2, requests)).pipe(
      concatMap((batch) =>
        from(batch).pipe(
          mergeMap((r) =>
            this.http
              .post<ISearchResponse>(
                `${base}?size=${r.pageSize}&from=${r.from}`,
                payload
              )
              .pipe(
                catchError((err) => {
                  this.toastService.sendMessage(
                    err.error?.message || err.message || JSON.stringify(err),
                    'error'
                  );
                  return EMPTY;
                })
              )
          )
        )
      ),
      reduce<ISearchResponse, ISearchDisplayExpert[]>(
        (prev, curr) =>
          prev.concat(
            curr.experts.map((e) =>
              expertMapper(
                e,
                curr.tracking_id,
                payload.service === 'combined-search'
                  ? 'historical'
                  : payload.service
              )
            )
          ),
        []
      ),
      map((experts) => ({ experts, total: experts.length }))
    );
  }

  private searchLinkedInEnrichment(
    payload: ILinkedInEnrichmentQuery
  ): Observable<ICommercialEnrichmentResultDict> {
    return this.http
      .post<ICommercialEnrichmentResponse>(`${this.baseUrl}/v1/pdl/profiles`, {
        [payload.pdlEnrichmentService]: payload.attributes,
      })
      .pipe(
        map((data) =>
          data.experts.map((expert) =>
            this.mapCommercialEnrichmentToConnectExpert(expert)
          )
        ),
        map((data) => ({
          success: data,
          fail: [],
        })),
        catchError(() =>
          of({
            success: [],
            fail: payload.attributes,
          })
        )
      );
  }

  private mapToConnectExpert(
    expert: ISearchExpertV3,
    trackingId: string,
    service: ExpertSource
  ): ISearchDisplayExpert {
    return {
      id: this.appService.createUUID(),
      selected: false,
      positions: expert.positions || [],
      source: {
        searchExpertData: {
          id: expert.id,
          trackingId: trackingId,
        },
        expertProfileId: expert.id,
        firstName: this.capitalise(expert.first_name),
        lastName: this.capitalise(expert.last_name),
        country: expert.countries?.length === 1 ? expert.countries[0] : '',
        source: service,
        expertise: this.removeDuplicates(
          (expert.specialities || []).map((s) => s.speciality)
        ),
        affiliations: this.removeDuplicates(
          (expert.positions || []).reduce(
            (acc, p) =>
              p.affiliation?.name ? [...acc, p.affiliation.name] : acc,
            []
          )
        ),
        positions: this.removeDuplicates(
          (expert.positions || []).reduce(
            (acc, p) => (p.title ? [...acc, p.title] : acc),
            []
          )
        ),
        qualifications: this.removeDuplicates(
          (expert.certifications || []).reduce(
            (acc, c) => (c.name ? [...acc, c.name] : acc),
            []
          )
        ),
        opportunityEmails: this.formatAndDedupeEmails([
          expert.poc_email,
          ...(expert.emails || []).map((e) => e.address),
        ]),
        primaryEmail: expert.poc_email || '',
        linkedInUrl: expert.linkedin_url || '',
        phoneNumbers: expert.phones || [],
      },
    };
  }

  private mapCommercialEnrichmentToConnectExpert(
    expert: ICommercialEnrichmentExpert
  ): ISearchDisplayExpert {
    const nameArray = expert.name.split(' ');
    return {
      id: this.appService.createUUID(),
      selected: false,
      source: {
        searchExpertData: {
          id: expert.id,
          trackingId: expert.tracking_id,
        },
        expertProfileId: expert.id,
        firstName: this.capitalise(nameArray[0]),
        lastName: this.capitalise(nameArray[1]),
        country: expert.countries.length === 1 ? expert.countries[0] : '',
        source: 'pdl-enrichment',
        expertise: this.removeDuplicates(expert.specialities || []),
        affiliations: this.removeDuplicates(expert.affiliations || []),
        positions: expert.positions || [],
        qualifications: [],
        opportunityEmails: this.formatAndDedupeEmails(expert.emails || []),
        primaryEmail: '',
        linkedInUrl: expert.linkedin_url || '',
        phoneNumbers: expert.phone_numbers || [],
      },
    };
  }

  public mapDeepToConnectExpert(
    expert: IDeepExpert,
    trackingId: string,
    service: ExpertSource
  ): ISearchDisplayExpert {
    let firstName = '';
    let lastName = '';
    if (expert.source.firstName) {
      firstName =
        typeof expert.source.firstName === 'object'
          ? this.capitalise(expert.source.firstName[0])
          : this.capitalise(expert.source.firstName);
      lastName =
        typeof expert.source.lastName === 'object'
          ? this.capitalise(expert.source.lastName[0])
          : this.capitalise(expert.source.lastName);
    } else if (expert.source.name) {
      const names = expert.source.name.split(' ');
      if (names.length === 1) {
        firstName = names[0];
      } else if (names.length === 2) {
        firstName = this.capitalise(names[0]);
        lastName = this.capitalise(names[names.length - 1]);
      } else {
        lastName = this.capitalise(names[names.length - 1]);
        names.pop();
        firstName = this.capitalise(names.flat()[0]);
      }
    }
    return {
      id: this.appService.createUUID(),
      selected: false,
      source: {
        searchExpertData: {
          id: expert.id,
          trackingId,
          ...(expert.source.scores
            ? {
                relevance: expert.source.scores.relevance,
                responsiveness: expert.source.scores.responsiveness,
              }
            : {}),
        },
        expertProfileId: expert.id,
        firstName,
        lastName,
        country:
          expert.source.countries.length === 1
            ? expert.source.countries[0]
            : '',
        source: service,
        expertise: this.removeDuplicates(expert.source.specialities || []),
        affiliations: this.removeDuplicates(expert.source.affiliations || []),
        positions: expert.source.positions || [],
        qualifications: expert.source.qualifications || [],
        opportunityEmails: this.formatAndDedupeEmails(
          expert.source.emails || []
        ),
        primaryEmail: '',
        linkedInUrl: '',
      },
    };
  }

  private capitalise(word?: string): string {
    return (word || '').replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  }

  private formatAndDedupeEmails(emailArray: string[]): string[] {
    return [
      ...new Set(emailArray.filter(Boolean).map((e) => e.toLowerCase().trim())),
    ];
  }

  private removeDuplicates(array: string[]): string[] {
    return Array.from(new Set(array));
  }
}
